import axios from 'axios'

export const login = (email, pw) => async (dispatch) => {

    try {
        
        dispatch({
            type: 'USER_LOGIN_REQUEST'
        })

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/users/login/', 
            {
                'username': email,
                'password': pw
            },
            config
        )

        dispatch({
            type: 'USER_LOGIN_SUCCESS',
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: 'USER_LOGIN_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })        
    }
}


export const logout = () => (dispatch) => {
    localStorage.removeItem('userInfo')

    dispatch({ type: 'USER_LOGOUT'})

    dispatch({ type: 'CART_CLEAR_ITEMS'})

    dispatch({ type: 'WISHLIST_CLEAR_ITEMS'})

    localStorage.clear()
    
}


export const register = (name, email, pw) => async (dispatch) => {
    try {

        dispatch({ type: 'USER_REGISTER_REQUEST'})

        const config = {
            headers: {
                'Content-type': 'application/json'
            }
        }

        const { data } = await axios.post('/api/users/register/', 
            {
                'name': name,
                'email': email,
                'password': pw
            },
            config            
        )

        dispatch({
            type: 'USER_REGISTER_SUCCESS',
            payload: data
        })

        dispatch({
            type: 'USER_LOGIN_SUCCESS',
            payload: data
        })

        localStorage.setItem('userInfo', JSON.stringify(data))

    } catch (error) {
        dispatch({
            type: 'USER_REGISTER_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const getUserDetails = () => async(dispatch, getState) => {
    try {
        dispatch({type: 'USER_DETAILS_REQUEST'})

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type':'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/users/${userInfo.id}/`, config)

        dispatch({
            type: 'USER_DETAILS_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'USER_DETAILS_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })        
    }
}


export const updateUserProfile = (userData) => async(dispatch, getState) => {
    try {

        dispatch({type: 'USER_UPDATE_REQUEST'})

        const { userLogin:{ userInfo } } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`/api/users/${userInfo.id}/update/`, 
            userData,
            config
        )

        dispatch({
            type: 'USER_UPDATE_SUCCESS',
            payload: data
        })


    } catch (error) {
        dispatch({
            type: 'USER_UPDATE_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}