import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar'
import Rating from '../components/Rating'


const Reviews = ({reviews}) => {
    return (
 
        <Swiper className='reviews-swiper'
        modules={[Navigation, Pagination, ]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        >
            
            {reviews.map(review => (

                <SwiperSlide key={review.id} className='review-card mb-2'>
                    <div className='d-flex flex-column'>
                        <h4 className='me-3 light-green-text'>{review.name}</h4>
                        <div className='d-flex'>
                            <Rating value={review.rating} color='#f8e825'/> ({review.rating})
                        </div>
                    </div>

                    <p className='mt-3 gray review-comment'>{review.comment}</p>
                </SwiperSlide>
            ))}

        </Swiper>
      
    )
}

export default Reviews