import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listAuthor, updateAuthor } from '../actions/authorActions'
import axios from 'axios'
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer'
import { useTranslation } from 'react-i18next'


const AuthorEditScreen = () => {

    const [firstName, setFirstName] = useState('')
    const [job, setJob] = useState('')
    const [role, setRole] = useState('')
    const [description, setDescription] = useState('')
    const [descriptionDe, setDescriptionDe] = useState('')
    const [video, setVideo] = useState('')
    const [shortVideo, setShortVideo] = useState('')
    const [image, setImage] = useState('')
    const [uploading, setUploading] = useState(false)
    const [link, setLink] = useState('');
    const { t } = useTranslation()
    
    const { id } = useParams()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const authorList = useSelector(state => state.authorList)
    const { loading, error, author } = authorList

    const authorUpdate = useSelector(state => state.authorUpdate)
    const { loading:updateLoading, error:updateError, success } = authorUpdate
    

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login');
        }
        if (success) {
            dispatch({type: 'UPDATE_AUTHOR_RESET'})
            navigate('/admin/authors')

        } else {
        
            if (!author || author.id !== id) {

                dispatch(listAuthor(id))

            } else {
                setFirstName(author.firstName)
                setImage(author.image)
                setDescription(author.authorInfo)
                setDescriptionDe(author.authorInfoDe)
                setShortVideo(author.shortVideo)
                setVideo(author.video)
                setJob(author.job)
                setRole(author.role);
                setLink(author.link);
            }
        }

    }, [id, userInfo, author, success, navigate, dispatch])


    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image', file)
        formData.append('author_id', id)

        setUploading(true)

        try {

            const config = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post(
                `/api/authors/upload/`,
                formData,
                config,
            )
            
            setImage(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
        }
    }


    const submitHandler = (e) => {
        e.preventDefault()
        
        dispatch(updateAuthor({
            id,
            firstName,
            description,
            descriptionDe,
            image,
            video,
            shortVideo,
            job,
            role,
            link
        }))
    }

    return (
        <Container className='my-5'>
            <Link to='/admin/authors' className='btn btn-primary'>{t('Go Back')}</Link>
            <FormContainer>
                <h1>{t('Edit')}</h1>

               {updateLoading && <Loader/>}
               {updateError && <Message variant='danger'>{updateError}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='name'>
                                <Form.Label>{t('Name')}</Form.Label>
                                <Form.Control type='name' placeholder='Enter Name' value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='job'>
                                <Form.Label>{t('Select job')}</Form.Label>
                                <Form.Select aria-label="function" placeholder='Select job' 
                                    value={job ? job : 'author'}
                                    onChange={(e) => setJob(e.target.value)}>
                                        <option value={'author'}>{t('Author')}</option>
                                        <option value={'artist'}>{('Artist')}</option>
                                        <option value={'team'}>{('Team')}</option>
                                    </Form.Select>
                            </Form.Group>

                            <Form.Group controlId='image'>
                                <Form.Label>{t('Image')}</Form.Label>
                                <Form.Control type='text' placeholder={t('Enter Image')} value={image ? image : ''}
                                    onChange={(e) => setImage(e.target.value)} disabled/>

                                <Form.Control type='file' label='Choose File'
                                    custom='true' onChange={uploadFileHandler} />

                                {uploading && <Loader />}
                            </Form.Group>

                            {job === 'team' ? (
                                <>
                                <Form.Group controlId='video'>
                                    <Form.Label>{t('Role')}</Form.Label>
                                    <Form.Control type='text' placeholder='e.g: Publisher' value={role ? role : ''}
                                       onChange={(e) => setRole(e.target.value)}/>
                                </Form.Group>
                                <Form.Group controlId='video'>
                                    <Form.Label>{('Link')}</Form.Label>
                                    <Form.Control type='text' placeholder='https://www.example.com ' value={link ? link : ''}
                                       onChange={(e) => setLink(e.target.value)}/>
                                </Form.Group>
                                </>
                            ) : (
                                <>  
                                    <Form.Group controlId='video'>
                                    <Form.Label>{t('Enter a')} Youtube video URL</Form.Label>
                                    <Form.Control type='text' placeholder={t('Enter a') + 'video URL'} value={video ? video : ''}
                                        onChange={(e) => setVideo(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group controlId='video'>
                                    <Form.Label>{t('Enter a')} {t('Short')} Youtube Video URL</Form.Label>
                                    <Form.Control type='text' placeholder={`${t('Enter a')} ${t('Short')} Video URL`} value={shortVideo ? shortVideo : ''}
                                        onChange={(e) => setShortVideo(e.target.value)}/>
                                    </Form.Group>
                                </>
                            )}
                            
                            <Form.Group controlId='description'>
                                <Form.Label>{('Description')}</Form.Label>
                                <Form.Control as='textarea' placeholder={t('Enter description')} value={description ? description : ''}
                                    onChange={(e) => setDescription(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='descriptionDe'>
                                <Form.Label>{('Description')} in Deutsche</Form.Label>
                                <Form.Control as='textarea' placeholder={t('Enter description')} value={descriptionDe ? descriptionDe : ''}
                                    onChange={(e) => setDescriptionDe(e.target.value)}/>
                            </Form.Group>

                            <Button type='submit' variant='primary mt-4'>{('Update')}</Button>
                        </Form>
                    )
                }
            
            </FormContainer>
        </Container>
    )
}

export default AuthorEditScreen
