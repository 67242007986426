
export const cartReducer = (state = { cartItems: [], shippingAddress: [] }, action) => {
    switch(action.type) {
        case 'CART_ADD_ITEM_REQUEST':
            return {
                ...state,
                loading: true
            }
        case 'CART_ADD_ITEM':
            const item = action.payload
            const existItem = state.cartItems.find(x => x.id === item.id)

            if (existItem) {
                return {
                    ...state,
                    loading: false,
                    cartItems: state.cartItems.map(x => x.id === existItem.id ? item : x)
                }
            } else {
                return {
                    ...state,
                    loading: false,
                    cartItems: [...state.cartItems, item]
                }
            }

        case 'CART_REMOVE_ITEM':
            const removedItem = action.payload

            return {
                ...state,
                loading: false,
                cartItems: state.cartItems.filter(item => item.id !== removedItem)
            }

        case 'CART_CLEAR_ITEMS':
            return {
                ...state,
                loading: false,
                cartItems: []
            }

        case 'SAVE_SHIPPING_ADDRESS':
            return {
                ...state,
                loading:false,
                shippingAddress: action.payload
            }


        default:
            return state
    }
}


export const wishlistReducer = (state = { wishlistItems: [] }, action) => {
    switch(action.type) {
        case 'WISHLIST_ADD_ITEM':
            const item = action.payload
            const existItem = state.wishlistItems.find(x => x.id === item.id)

            if (existItem) {
                return {
                    wishlistItems: state.wishlistItems.map(x => x.id === existItem.id ? item : x)
                }
            } else {
                return {
                    wishlistItems: [...state.wishlistItems, item]
                }
            }
        case 'WISHLIST_REMOVE_ITEM':
            const removedItem = action.payload

            return {
                wishlistItems: state.wishlistItems.filter(x => x.id !== removedItem)
            }
        
        case 'WISHLIST_CLEAR_ITEMS':
            return {
                wishlistItems: []
            }

        default:
            return state
    }
}