import { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar'
import BookCard from './BookCard'


const BooksCarousel = ({books, props}) => {

    const [filteredBooks, setFilteredBooks] = useState([])

    useEffect(() => {

        if (props) {

            if(props.bestSeller) {
                setFilteredBooks(books.filter(book => book.bestSeller === true))
    
            } else if (props.author) {
                setFilteredBooks(books.filter(book => book.author.id === props.author.author.id 
                    && book.id !== props.author.id))
    
            } else if (props.category) {
                setFilteredBooks(books.filter(book => (book.category === props.category.category 
                     || Number(book.rating) >= 4.5) && book.id !== props.category.id ))
    
            }
        }
         else {
            setFilteredBooks(books)
        }
        

    }, [props, books])


    return (

    <Swiper
        modules={[Navigation, Pagination, ]}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        breakpoints={{
            768: {
                slidesPerView: 2
            },
            990: {
                slidesPerView: 3
            },
            1400: {
                slidesPerView: 4
            }
        }}
    >
        
        {filteredBooks.map(book => (

            <SwiperSlide key={book.id}><BookCard book={book}/></SwiperSlide>
        ))}
        
    </Swiper>
    )
}

export default BooksCarousel
