import axios from 'axios'


export const listBlogs = () => async(dispatch) => {
    try {
        dispatch({
            type: 'LIST_BLOGS_REQUEST'
        })

        const config = {
            headers: {
                'Content-type': 'application/json',
            }
        }

        const { data } = await axios.get('/api/blogs/', config)

        dispatch({
            type: 'LIST_BLOGS_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_BLOGS_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message
        })
    }
}

export const listBlog = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: 'LIST_BLOG_REQUEST'
        })

        const { userLogin: {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/blogs/${id}/`,
            config,
        )

        dispatch({
            type: 'LIST_BLOG_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_BLOG_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message
        })
    }
}



export const createBlog = (blog) => async(dispatch, getState) => {
    try {
        dispatch({
            type: 'CREATE_BLOG_REQUEST'
        })

        const { userLogin: {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post('/api/blogs/create/', { }, config)

        dispatch({
            type: 'CREATE_BLOG_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'CREATE_BLOG_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message
        })
    }
}


export const updateBlog = (blog) => async(dispatch, getState) => {
    try {
        dispatch({
            type: 'UPDATE_BLOG_REQUEST'
        })

        const { userLogin: {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(`/api/blogs/${blog.id}/edit/`, blog, config)

        dispatch({
            type: 'UPDATE_BLOG_SUCCESS',
            payload: data
        })

        dispatch({
            type: 'LIST_BLOG_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'UPDATE_BLOG_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message
        })
    }
}


export const deleteBlog = (id) => async(dispatch, getState) => {
    try {
        dispatch({
            type: 'DELETE_BLOG_REQUEST'
        })

        const { userLogin: {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(`/api/blogs/${id}/delete/`, config)

        dispatch({
            type: 'DELETE_BLOG_SUCCESS',
            payload: data
        })


    } catch (error) {
        dispatch({
            type: 'DELETE_BLOG_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message
        })
    }
}


export const sendNewsletter = (mail) => async(dispatch, getState) => {
    try {
        dispatch({
            type: 'SEND_NEWSLETTER_REQUEST'
        })

        const { userLogin: {userInfo } } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post('/api/blogs/send-newsletter/', mail, config)

        dispatch({
            type: 'SEND_NEWSLETTER_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'SEND_NEWSLETTER_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message
        })
    }
}