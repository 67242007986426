import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Row, Col, ListGroup, Image, Card, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { createOrder, } from '../actions/orderActions'
import { useTranslation } from 'react-i18next'
import Message from '../components/Message'

const PlaceOrderScreen = () => {

    
    const orderCreate = useSelector(state => state.orderCreate)
    const {order, error} = orderCreate
    const { t } = useTranslation()

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cart = useSelector(state => state.cart)

    cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
    cart.shippingPrice = 0
    cart.totalPrice = (Number(cart.itemsPrice) + Number(cart.shippingPrice)).toFixed(2)

    useEffect(() => {

        if (order) {
            navigate(`/order/${order.id}`)
            dispatch({
                type: 'ORDER_CREATE_RESET'
            })
        }

    }, [navigate, dispatch, order])

    const placeOrder = () => {

        dispatch(createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            totalPrice: cart.totalPrice,
        }))

    }

    return (
        <Container id='place-order-screen' className='mt-5'>     

            <Row>
                <Col md={8}>
                    <ListGroup variant='flush'>
                        {cart.cartItems.find(item => item.type === 'hardCover') 
                         && (
                            <ListGroup.Item>
                                <h2>{t('Shipping')}</h2>

                                <p>
                                    <strong>{t('Shipping')}: </strong>
                                    {cart.shippingAddress.address},
                                    {cart.shippingAddress.city}
                                    {'  '}
                                    {cart.shippingAddress.postalCode},
                                    {'  '}
                                    {cart.shippingAddress.country}    
                                </p>
                            </ListGroup.Item>
                        )}
                       
                        <ListGroup.Item>
                            <h2>{t('Order Items')}</h2>

                            {cart.cartItems.length === 0 ? <Message variant='info'>
                                {t('Your cart is empty')}
                                </Message>
                                : (
                                    <ListGroup variant='flush'>
                                        {cart.cartItems.map((item, index) => (
                                            <ListGroup.Item className='px-0' key={index}>
                                                <Row>
                                                    <Col md={2}>
                                                        <Image src={item.image} alt={item.name} fluid rounded/>
                                                    </Col>

                                                    <Col>
                                                        <Link className='green-link fs-6' to={`/books/${item.id}`}>
                                                        {item.name} {item.type === 'eBook' && '(E-Book)'}
                                                        <p className='mt-3 text-warning'>{t('Language')}: {item.language}</p>
                                                        </Link>
                                                    </Col>

                                                    <Col md={4}>
                                                        {item.qty} X &euro;{item.price} = &euro;{(item.qty * item.price).toFixed(2)}
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ) )}
                                    </ListGroup>
                                )
                            }
                        </ListGroup.Item>
                    </ListGroup>
                </Col>
                
                <Col md={4}>
                    <Card>
                        <ListGroup variant='flush'>
                            <ListGroup.Item>
                                <h2>{t('Order Summary')}</h2>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>{t('items')}: </Col>
                                    <Col>&euro;{cart.itemsPrice}</Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>{t('Shipping')}: </Col>
                                    <Col>&euro;{cart.shippingPrice}</Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item>
                                <Row>
                                    <Col>{t('Total')}: </Col>
                                    <Col>&euro;{cart.totalPrice}</Col>
                                </Row>
                            </ListGroup.Item>

                            {error && 
                                <ListGroup.Item>
                                    <Message variant='danger'>{error}</Message>
                                </ListGroup.Item>
                            }

                            <ListGroup.Item className='p-0'>
                                <Button type='button' className='btn-block w-100'
                                 disabled={cart.cartItems.length === 0}
                                 onClick={placeOrder}>{t('Place Order')}</Button>
                            </ListGroup.Item>

                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default PlaceOrderScreen
