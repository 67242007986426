import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listAllBooks} from '../actions/bookActions'
import axios from 'axios'
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer'
import {toast} from 'react-toastify'
import { useTranslation } from 'react-i18next'


const AddPdfScreen = () => {

    const [selectedLanguage, setSelectedLanguage] = useState('')
    const [pdf, setPdf] = useState({})
    const [pdfName, setPdfName] = useState('')
    const [bookId, setBookId] = useState('')
    const [uploading, setUploading] = useState(false)
    const languages = ['deutsche', 'english', 'korean', 'spanish', 'latin', 'chinese', 'japanese']
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const booksListAll = useSelector(state => state.booksListAll)
    const { loading, error, books } = booksListAll

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


    useEffect(() => { 
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } 

        if (!books) {
            dispatch(listAllBooks())
        }

    }, [books, userInfo, navigate, dispatch])
    


    const submitHandler = async (e) => {
        e.preventDefault()
   
        const formData = new FormData()

        formData.append('pdf', pdf)
        formData.append('book_id', bookId)
        formData.append('language', selectedLanguage)

        setUploading(true)

        try {

            const config = {
                headers: {
                    'Content-type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post(
                `/api/books/pdfs/upload/`,
                formData,
                config,
            )
            
            setUploading(false)
            toast.success(data)
            navigate('/admin/ebooks')

        } catch (error) {
            setUploading(false)
            toast.error(error.response.data.detail)
            navigate('/admin/ebooks')
        }

    }

   
    return (
        <Container className='my-5'>
            <Link to='/admin/ebooks' className='btn btn-primary mb-4'>{t('Go Back')}</Link>
            <FormContainer>
                <h1>{t('Add Ebook Pdf')}</h1>

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>

                            <Form.Group controlId='language'>
                                <Form.Label>{t('Select the E-book')} </Form.Label>
                                <Form.Select aria-label="Select eBook" placeholder='Select eBook' 
                                    defaultValue={bookId}
                                    onChange={(e) => setBookId(e.target.value)}>
                                        <option>{t('Select E-book')}</option>
                                        {books && books.map((book,idx) => (
                                            <option key={idx} value={book.id} >{book.name}</option>
                                        ))}
                                </Form.Select>
                            </Form.Group>


                            <Form.Group controlId='language'>
                                <Form.Label>{t('Select the language of the E-Book')}</Form.Label>
                                <Form.Select aria-label="language" placeholder='Select language' 
                                    value={selectedLanguage}
                                    onChange={(e) => setSelectedLanguage(e.target.value)}>
                                        <option>{t('Select language')}</option>
                                        {languages.map((lang,idx) => (

                                            <option key={idx} value={lang} >{lang}</option>
                                        ))}
                                        
                                    </Form.Select>
                            </Form.Group>

                            <Form.Group controlId='pdf'>
                                <Form.Label>E-Book PDF</Form.Label>
                                <Form.Control type='text' placeholder='Enter E-Book Pdf' value={pdfName ? pdfName : ''}
                                    onChange={(e) => setPdfName(e.target.value)} disabled/>

                                <Form.Control type='file' label='Choose File'
                                    custom='true' onChange={(e) => setPdf(e.target.files[0])}/>

                                {uploading && <Loader />}
                            </Form.Group>

                            <Button type='submit' variant='primary mt-4'>{t('Add')}</Button>
                        </Form>
                    )
                }
            
            </FormContainer>
        </Container>
    )
}

export default AddPdfScreen
