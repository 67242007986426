import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listBook, updateBook, listCategories } from '../actions/bookActions'
import { listAuthors } from '../actions/authorActions'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer'
import { useTranslation } from 'react-i18next'



const BookEditScreen = () => {

    const [name, setName] = useState('')
    const [upcomingBook, setUpcomingBook ] = useState(false)
    const [hardCoverPrice, setHardCoverPrice] = useState(0)
    const [eBookPrice, setEbookPrice] = useState(0)
    const [image, setImage] = useState('')
    const [bestSeller, setBestSeller] = useState('')
    const [bookAuthors, setBookAuthors] = useState([])
    const [category, setCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [description, setDescription] = useState('')
    const [descriptionDe, setDescriptionDe] = useState('')
    const [productDetails, setProductDetails] = useState('')
    const [productDetailsDe, setProductDetailsDe] = useState('')
    const [uploading, setUploading] = useState(false)
    const [bookLanguages, setBookLanguages] = useState([])
    const languages = ['deutsche', 'english', 'korean', 'spanish', 'latin', 'chinese', 'japanese']
    const [previewName, setPreviewName] = useState('')
    const [previewUploading, setPreviewUploading] = useState(false)
    const [donation, setDonation] = useState('')
    const [donationDe, setDonationDe] = useState('')
    const { t } = useTranslation()
    
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const categoriesList = useSelector(state => state.categoriesList)
    const { categories } = categoriesList

    const bookList = useSelector(state => state.bookList)
    const { loading, error, book } = bookList
    
    const bookUpdate = useSelector(state => state.bookUpdate)
    const { loading: updateLoading, error: updateError , success: updateSuccess } = bookUpdate

    const authorsList = useSelector(state => state.authorsList)
    const { authors } = authorsList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login');
        }

        if (updateSuccess) {
            dispatch({type: 'UPDATE_BOOK_RESET'})
            
            navigate('/admin/bookList')

        } else {
        
            if (!book.name || book.id !== id) {

                dispatch(listBook(id))
                dispatch(listCategories())
                dispatch(listAuthors())

            } else {
                setName(book.name)
                setUpcomingBook(book.upcomingBook)
                setHardCoverPrice(book.hardCoverPrice)
                setEbookPrice(book.eBookPrice)
                setImage(book.image)
                setCategory(book.category.name)
                setBookAuthors(book.author)
                setCountInStock(book.countInStock)
                setDescription(book.description)
                setDescriptionDe(book.descriptionDe)
                setBestSeller(book.bestSeller)
                setProductDetails(book.productDetails)
                setProductDetailsDe(book.productDetailsDe)
                setPreviewName(book.preview)
                setDonation(book.donation)
                setDonationDe(book.donationDe)

                if (book.languages) {
                    setBookLanguages(book.languages.split(','))
                }
            }
        }

    }, [id, book, userInfo, updateSuccess,  navigate, dispatch])
    
    const handleLanguages = (lang) => {

        const langExists = bookLanguages.find(item => item === lang)


        if (langExists) {
            setBookLanguages(state => [...state.filter(item => item !== lang)])
        }
        else {
            setBookLanguages(state => [...state, lang])
        }    
        
    }

    const handleSelectAuthors = (e) => {
        const options = [...e.target.options]
        const filterOptions = options.filter(opt => opt.selected === true)

        setBookAuthors(filterOptions.map(option => { return {'firstName': option.value} }))

    }   

    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image', file)
        formData.append('book_id', id)

        setUploading(true)

        try {

            const config = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post(
                `/api/books/upload/`,
                formData,
                config,
            )
            
            setImage(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
        }
    }

    const addPreview = async (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('preview', file)
        setPreviewUploading(true)

        try {

            const config = {
                headers: {
                    'Content-type': 'multipart/form-data',
                    Authorization: `Bearer ${userInfo.token}`
                }
            }

            const { data } = await axios.post(
                `/api/books/${id}/upload-preview/`,
                formData,
                config,
            )
            
            setPreviewUploading(false)
            toast.success(data)

        } catch (error) {
            setPreviewUploading(false)
            toast.error(error.response.data.detail)
        }

    }

    const submitHandler = (e) => {
        e.preventDefault()
    
        dispatch(updateBook({
            id,
            name,
            upcomingBook: (upcomingBook === 'true' || upcomingBook === true) ? 'True' : 'False',
            hardCoverPrice,
            eBookPrice,
            donation,
            donationDe,
            image,
            category,
            bookAuthors,
            countInStock,
            description,
            descriptionDe,
            productDetails,
            productDetailsDe,
            bestSeller: (bestSeller === 'true' || bestSeller === true) ? 'True' : 'False',
            languages: bookLanguages.join(','),
        }))

    }

    
    return (
        <Container className='my-5'>
            <Link to='/admin/booklist' className='btn btn-primary mb-4'>{t('Go Back')}</Link>
            <FormContainer>
                <h1>{t('Edit Product')}</h1>

               {updateLoading && <Loader/>}
               {updateError && <Message variant='danger'>{updateError}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='name'>
                                <Form.Label>{('Name')}</Form.Label>
                                <Form.Control type='name' placeholder={t('Enter Name')} value={name}
                                    onChange={(e) => setName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='upcoming'>
                                <Form.Label>{t('Upcoming Book')}?</Form.Label>
                                <Form.Select aria-label="Is upcoming" placeholder={t('Is Upcoming Book?')} 
                                    value={upcomingBook}
                                    onChange={(e) => setUpcomingBook(e.target.value)}>
                                        <option value={true} >{('Yes')}</option>
                                        <option value={false}>{t('No')}</option>
                                    </Form.Select>
                            </Form.Group>

                            <Form.Group controlId='hardCoverPrice'>
                                <Form.Label>{('Hard Cover Price')}</Form.Label>
                                <Form.Control type='number' placeholder={t('Enter Price')} value={hardCoverPrice}
                                    onChange={(e) => setHardCoverPrice(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='eBookPrice'>
                                <Form.Label>{t('E-book Price')}</Form.Label>
                                <Form.Control type='number' placeholder={t('Enter Price')} value={eBookPrice}
                                    onChange={(e) => setEbookPrice(e.target.value)}/>
                            </Form.Group>


                            <Form.Group controlId='languages' className='d-flex flex-column'>
                                <Form.Label>{t('Select all available languages for the book')}:</Form.Label>
                                <div>
                                    {bookLanguages.length > 0 ?
                                     languages.map((lang, idx) => (   
                                        <Form.Check key={idx} inline label={lang} name="select-language" id={lang}
                                        defaultChecked={bookLanguages.includes(lang.toLowerCase())}
                                        type='checkbox' onClick={() => handleLanguages(lang)} />
                                     )) 

                                     : languages.map((lang, idx) => (
                                        
                                        <Form.Check key={idx} inline label={lang} name="select-language" id={lang}        
                                        type='checkbox' onClick={() => handleLanguages(lang)} />       
                                     ))
                                    }

                                </div>
                            </Form.Group>

                            <Form.Group controlId='bestSeller'>
                                <Form.Label>{t('Is Best Seller')}</Form.Label>
                                <Form.Select aria-label="Is Best Seller" placeholder={t('Is best Seller')}
                                    value={bestSeller}
                                    onChange={(e) => setBestSeller(e.target.value)}>
                                        <option value={true} >{t('Yes')}</option>
                                        <option value={false}>{t('No')}</option>
                                    </Form.Select>
                            </Form.Group>

                            <Form.Group controlId='image'>
                                <Form.Label>{t('Image')}</Form.Label>
                                <Form.Control type='text' placeholder={t('Enter Image')} value={image ? image : ''}
                                    onChange={(e) => setImage(e.target.value)} disabled/>

                                <Form.Control type='file' label='Choose File'
                                    custom='true' onChange={uploadFileHandler} />

                                {uploading && <Loader />}
                            </Form.Group>

                            <Form.Group controlId='preview'>
                                <Form.Label>{t('Book Preview PDF')}</Form.Label>
                                <Form.Control type='text' placeholder={t('Add book Preview')} value={previewName ? previewName : ''}
                                    onChange={(e) => setPreviewName(e.target.value)} disabled/>

                                <Form.Control type='file' label='Choose File'
                                    custom='true' onChange={addPreview}/>

                                {previewUploading && <Loader />}
                            </Form.Group>

                            <Form.Group controlId='category'>
                                <Form.Label>{t('Category')}</Form.Label>
                                <Form.Select aria-label="Category" placeholder={t('Select a category')}
                                    value={category}
                                    onChange={(e) => setCategory(e.target.value)}>
                                        {categories && categories.map((item) => (

                                            <option key={item.id} value={item.name}>{item.name}</option>
                                        ))}
                                    </Form.Select>
                            </Form.Group>

                            <Form.Group controlId='authors'>
                                <Form.Label>{t('Authors')}</Form.Label>
                                <small className='d-block'>{t('Hold down "Control", or "Command" on a Mac, to select more than one.')}</small>
                                <Form.Select aria-label="authors" placeholder='Select book authors' 
                                    multiple onChange={handleSelectAuthors}
                                >
                                {authors && bookAuthors.length > 0 ?
                                    authors.map((item) => 
                                        (
                                            <option className='my-1' key={item.id}
                                                value={item.firstName} 
                                                selected={bookAuthors.find(author => author.firstName === item.firstName)}
                                            >
                                                {item.firstName}
                                            </option>
                                        ))
                                    : authors && authors.map((item) => 
                                    (
                                        <option className='my-1' key={item.id}
                                            value={item.firstName} 
                                        >
                                            {item.firstName}
                                        </option>
                                    )
                                )}


                                </Form.Select>
                            </Form.Group>

                            <Form.Group controlId='countinstock'>
                                <Form.Label>{t('Stock')}</Form.Label>
                                <Form.Control type='number' placeholder={t('Enter stock')} value={countInStock}
                                    onChange={(e) => setCountInStock(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='description'>
                                <Form.Label>{t('Description')}</Form.Label>
                                <Form.Control as='textarea' placeholder={t('Enter description')} value={description ? description : ''}
                                    onChange={(e) => setDescription(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='descriptionDe'>
                                <Form.Label>{t('Description')} in Deutsche</Form.Label>
                                <Form.Control as='textarea' placeholder={`${t('Enter description')} in deutsche`} value={descriptionDe ? descriptionDe : ''}
                                    onChange={(e) => setDescriptionDe(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='donation'>
                                <Form.Label>{t('Enter Social Contribution')}</Form.Label>
                                <Form.Control as='textarea' placeholder={t('Enter message')} value={donation ? donation : ''}
                                    onChange={(e) => setDonation(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='donationDe'>
                                <Form.Label>{t('Enter Social Contribution')} in Deutsche</Form.Label>
                                <Form.Control as='textarea' placeholder={t('Enter message')} value={donationDe ? donationDe : ''}
                                    onChange={(e) => setDonationDe(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='productDetails'>
                                <Form.Label>{t('Product details')}</Form.Label>
                                <small className='ms-2 text-secondary'>*{t('type all product details separated with comma')} " , "</small>
                                <Form.Control as='textarea' placeholder='Enter product details' value={productDetails ? productDetails : ''}
                                    onChange={(e) => setProductDetails(e.target.value)}/>
                            </Form.Group>
                            <Form.Group controlId='productDetailsDe'>
                                <Form.Label>{t('Product details')} in Deutsche</Form.Label>
                                <small className='ms-2 text-secondary'>*{t('type all product details separated with comma')} " , "</small>
                                <Form.Control as='textarea' placeholder='Enter product details' value={productDetailsDe ? productDetailsDe : ''}
                                    onChange={(e) => setProductDetailsDe(e.target.value)}/>
                            </Form.Group>

                            <Button type='submit' variant='primary mt-4'>{t('Update')}</Button>
                        </Form>
                    )
                }
            
            </FormContainer>
        </Container>
    )
}

export default BookEditScreen
