import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { listBooks, listCategories } from "../actions/bookActions";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import Message from "../components/Message";
import BookCard from "../components/BookCard";
import Paginate from "../components/Paginate";
import Filter from "../components/Filter";

const BooksScreen = () => {
  const languages = ["Deutsche", "English", "Korean", "Chinese"];

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const booksList = useSelector((state) => state.booksList);
  const { loading, books, error } = booksList;

  const categoriesList = useSelector((state) => state.categoriesList);
  const { categories } = categoriesList;

  const location = useLocation();
  let keyword = location.search;
  let categoryId = "";
  let langId = "";
  let inStock = "";

  if (keyword.includes("category")) {
    categoryId = keyword.split("?category=")[1].split("&")[0];
  } else if (keyword.includes("lang")) {
    langId = keyword.split("?lang=")[1].split("&")[0];
  } else if (keyword.includes("in-stock")) {
    inStock = "in-stock";
  }

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(listBooks(keyword));

    if (!categories) {
      dispatch(listCategories());
    }
  }, [dispatch, categories, keyword]);

  return (
    <Container id="books-screen">
      <ToastContainer />
      <h2 className="mt-3 page-title">{t("Book Collection")} </h2>
      <div className="mb-5">
        <Link to="/" className="green-link fs-6">
          {t("Home")} &gt;{" "}
        </Link>
        <Link to="/books" className="green-link fs-6">
          {t("Book Collection")} &gt;{" "}
        </Link>
        {categories &&
          categories.map(
            (cat) =>
              cat.id === categoryId && (
                <Link
                  key={cat.id}
                  to={`/books/?category=${cat.id}&page=1`}
                  className="green-link fs-6"
                >
                  {i18n.language === "de" && cat.nameDe
                    ? cat.nameDe.charAt(0).toUpperCase() + cat.nameDe.slice(1)
                    : cat.name.charAt(0).toUpperCase() + cat.name.slice(1)}
                </Link>
              )
          )}

        {languages &&
          languages.map(
            (lang, idx) =>
              lang.toLowerCase() === langId && (
                <Link
                  key={idx}
                  to={`/books/?lang=${lang.toLocaleLowerCase()}&page=1`}
                  className="green-link fs-6"
                >
                  {lang}
                </Link>
              )
          )}

        {inStock && (
          <Link to={`/books/?in-stock=all&page=1`} className="green-link fs-6">
            In Stock
          </Link>
        )}
      </div>
      <div className="book-collection d-flex flex-column flex-md-row">
        <Filter
          filters={{
            categories: categories,
            languages: languages,
            activeCat: categoryId,
          }}
        />
        <Row className="book-collection">
          {books && books.books && books.books.length === 0 && (
            <div className="mt-4">
              <Message variant="warning">
                {t('Currently, there are no books with the given filter')}.
              </Message>
            </div>
          )}

          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant="danger">{error}</Message>
          ) : (
            books &&
            books.books &&
            books.books.map((book) => (
              <Col
                key={book.id}
                sm={12}
                md={6}
                xl={4}
                className="bookcard-box d-flex justify-content-center"
              >
                <BookCard book={book} />
              </Col>
            ))
          )}
        </Row>
      </div>

      {books && books.page && books.pages && (
        <Paginate page={books.page} pages={books.pages} keyword={keyword} />
      )}
    </Container>
  );
};

export default BooksScreen;
