import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../actions/userActions'
import { useTranslation } from 'react-i18next'
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer'

const RegisterScreen = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')
    const [emailMsg, setEmailMsg] = useState('')
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const dispatch = useDispatch()
    const {t} = useTranslation()

    const navigate = useNavigate()

    const redirect = new URLSearchParams(useLocation().search).get('redirect')

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo } = userRegister

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo:login } = userLogin

    useEffect(() => {
        
        if (login || userInfo) {    
            navigate(redirect ? redirect : '/')
        }

    }, [navigate, redirect, login, userInfo])


    const submitHandler = (e) => {
        e.preventDefault()
        
        if (password !== confirmPassword) {
            setMessage(t('Passwords do not match'))   

        } else if (password.length < 8) {
            setMessage(t('password to short'))

        } else if (!email.toLowerCase().match(emailRegex)) {
            setEmailMsg('Invalid Email')
        }
        else {
            dispatch(register(name, email, password))
        }
    }

    return (
        <FormContainer>
            <h1>{t('register')}</h1>               
            {loading && <Loader/> }
            <div>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='name' className='mb-2'>
                        <Form.Label className='m-0'>{t('name')}</Form.Label>
                        <Form.Control required type='name' placeholder={t('enter name')} value={name}
                            onChange={(e) => setName(e.target.value)}/>
                    </Form.Group>

                    <Form.Group controlId='email' className='mb-2'>
                        <Form.Label className='m-0'>{t('enter email')}</Form.Label>
                        <Form.Control required type='email' placeholder={t('enter email')} value={email}
                            className={error || emailMsg ? 'input-err' : ''} onChange={(e) => setEmail(e.target.value)}/>
                            {error ? <small style={{'color':'red'}}>{error}</small> : emailMsg 
                             && <small style={{'color': 'red'}}>{emailMsg}</small>}
                    </Form.Group>

                    <Form.Group controlId='password' className='mt-3 mb-2'>
                        <Form.Label className='m-0'>{t('password')}</Form.Label>
                        <small className='d-block text-secondary'>{t('must contain at least 8 characters')}</small>
                        <Form.Control required type='password' placeholder={t('enter password')} value={password}
                            className={message ? 'input-err' : ''} onChange={(e) => setPassword(e.target.value)}/>
                            {message && <small style={{'color':'red'}}>{message}</small>}
                    </Form.Group>

                    <Form.Group controlId='password-confirm' className='mt-3 mb-2'>
                        <Form.Label className='m-0'>{t('confirm password')}</Form.Label>
                        <Form.Control required type='password' placeholder={t('confirm password')} value={confirmPassword}
                            className={message ? 'input-err' : ''} onChange={(e) => setConfirmPassword(e.target.value)}/>
                            {message && <small style={{'color':'red'}}>{message}</small>}
                    </Form.Group>

                    <Button type='submit' className='red' variant='primary mt-4'>{t('register')}</Button>
                </Form>

                <Row className='py-3'>
                    <Col>{t('have an account')}? <Link 
                        to={redirect ? `/login?redirect=${redirect}` : '/login'} className='fs-5 ms-1'> {t('sign in')}</Link>
                    </Col>
                </Row>
            </div>
            
        </FormContainer>
    )
}

export default RegisterScreen
