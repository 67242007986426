export const listEventsReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_EVENTS_REQUEST':
            return {
                loading: true,
            }
        
        case 'LIST_EVENTS_SUCCESS':
            return {
                loading: false,
                events: action.payload
            }
        
        case 'LIST_EVENTS_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}


export const listEventReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_EVENT_REQUEST':
            return {
                loading: true,
            }
        
        case 'LIST_EVENT_SUCCESS':
            return {
                loading: false,
                event: action.payload
            }
        
        case 'LIST_EVENT_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}



export const createEventReducer = (state = { }, action) => {
    switch(action.type) {
        case 'CREATE_EVENT_REQUEST':
            return {
                loading: true,
            }
        
        case 'CREATE_EVENT_SUCCESS':
            return {
                loading: false,
                success: true,
                event: action.payload,
            }
        
        case 'CREATE_EVENT_FAIL':
            return {
                loading: false,
                error: action.payload
            }
        
        case 'CREATE_EVENT_RESET':
            return { }

        default:
            return state
    }
}


export const updateEventReducer = (state = { }, action) => {
    switch(action.type) {
        case 'UPDATE_EVENT_REQUEST':
            return {
                loading: true,
            }
        
        case 'UPDATE_EVENT_SUCCESS':
            return {
                loading: false,
                success:true
            }
        
        case 'UPDATE_EVENT_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'UPDATE_EVENT_RESET':
            return { }

        default:
            return state
    }
}


export const deleteEventReducer = (state = { }, action) => {
    switch(action.type) {
        case 'DELETE_EVENT_REQUEST':
            return {
                loading: true,
            }
        
        case 'DELETE_EVENT_SUCCESS':
            return {
                loading: false,
                success:true
            }
        
        case 'DELETE_EVENT_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'DELETE_EVENT_RESET':
            return { }

        default:
            return state
    }
}




