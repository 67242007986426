import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Table, Button, Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listEvents, createEvent, deleteEvent } from '../actions/eventActions'
import Loader from '../components/Loader';
import Message from '../components/Message';


const EventsListScreen = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const eventsList = useSelector(state => state.eventsList)
    const { events, loading, error } = eventsList

    const eventDelete = useSelector(state => state.eventDelete)
    const { success, loading:deleteLoading, error:deleteError } = eventDelete

    const eventCreate = useSelector(state => state.eventCreate)
    const { success:successCreate, event, loading:createLoading, error:createError } = eventCreate


    useEffect(() => {   
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } else {
            dispatch(listEvents())
        }

        dispatch({type: 'CREATE_EVENT_RESET'})
       
        if (successCreate) {
            navigate(`/admin/events/${event.id}/edit/`)
        }

    }, [dispatch, navigate, userInfo, event, success, successCreate])

    const deleteHandler = (id) => {
        
        if (window.confirm('Are you sure you want to delete this event?')) {
            dispatch(deleteEvent(id))
        }

    }

    const createEventHandler = () => {
        dispatch(createEvent())
    }


    if (loading || createLoading || deleteLoading) {
        return <Loader/>
    }
    if (error) {
        return <Message variant='danger'>{error}</Message>
    }
    if (deleteError) {
        return <Message variant='danger'>{deleteError}</Message>
    }
    if (createError) {
        return <Message variant='danger'>{createError}</Message>
    }

    return (
        <Container className='my-5'>
            <Row className='align-items-center'>
                <Col>
                    <h1>Events</h1>
                </Col>

                <Col className='d-flex justify-content-end'>
                    <Button className='my-3' onClick={createEventHandler}>
                        <i className='fas fa-plus'></i> Create
                    </Button>
                </Col>
            </Row>

            <Table striped bordered hover responsive className='table-sm'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>NAME</th>
                        <th>ACTIONS</th>
                    </tr>
                </thead>

                <tbody>
                    {events && events.map(event => (
                        <tr key={event.id}>
                            <td>{event.id}</td>
                            <td>{event.name}</td>
                            <td>
                                <Link to={`/admin/events/${event.id}/edit`}>
                                    <Button variant='light' className='btn-sm'>
                                        <i className='fas fa-edit'></i>
                                    </Button>
                                </Link>

                                <Button variant='danger' className='btn-sm mt-1 mt-lg-0 ms-lg-2'
                                    onClick={() => deleteHandler(event.id)}>
                                    <i className='fas fa-trash'></i>
                                </Button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}

export default EventsListScreen
