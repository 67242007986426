export const blogsListReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_BLOGS_REQUEST':
            return {
                loading: true,
            }

        case 'LIST_BLOGS_SUCCESS':
            return {
                loading: false,
                blogs: action.payload
            }

        case 'LIST_BLOGS_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}


export const blogListReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_BLOG_REQUEST':
            return {
                loading: true,
            }

        case 'LIST_BLOG_SUCCESS':
            return {
                loading: false,
                blog: action.payload
            }

        case 'LIST_BLOG_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}

export const blogCreateReducer = (state = { }, action) => {
    switch(action.type) {
        case 'CREATE_BLOG_REQUEST':
            return {
                loading: true,
            }

        case 'CREATE_BLOG_SUCCESS':
            return {
                loading: false,
                createdBlog: action.payload
            }

        case 'CREATE_BLOG_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'CREATE_BLOG_RESET':
            return { }

        default:
            return state
    }
}


export const blogUpdateReducer = (state = { }, action) => {
    switch(action.type) {
        case 'UPDATE_BLOG_REQUEST':
            return {
                loading: true,
            }

        case 'UPDATE_BLOG_SUCCESS':
            return {
                loading: false,
                success: true
            }

        case 'UPDATE_BLOG_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'UPDATE_BLOG_RESET':
            return { }

        default:
            return state
    }
}


export const blogDeleteReducer = (state = { }, action) => {
    switch(action.type) {
        case 'DELETE_BLOG_REQUEST':
            return {
                loading: true,
            }

        case 'DELETE_BLOG_SUCCESS':
            return {
                loading: false,
                success: true
            }

        case 'DELETE_BLOG_FAIL':
            return {
                loading: false,
                error: action.payload
            }


        default:
            return state
    }
}


export const sendNewsletterReducer = (state = { }, action) => {
    switch(action.type) {
        case 'SEND_NEWSLETTER_REQUEST':
            return {
                loading: true
            }

        case 'SEND_NEWSLETTER_SUCCESS':
            return {
                loading: false,
                detail: action.payload
            }

        case 'SEND_NEWSLETTER_FAIL':
            return {
                loading: false,
                error: action.payload
            }
        
        case 'SEND_NEWSLETTER_RESET':
            return { }
            
        default:
            return state
    }
}