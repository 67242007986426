import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listBlogs, createBlog, deleteBlog } from '../actions/blogActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useTranslation } from 'react-i18next'


const BlogsListScreen = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const blogsList = useSelector(state => state.blogsList)
    const { loading, error, blogs } = blogsList

    const blogCreate = useSelector(state => state.blogCreate)
    const { loading:loadingCreate, error:errorCreate, createdBlog } = blogCreate

    const blogDelete = useSelector(state => state.blogDelete)
    const { loading:loadingDelete, error:errorDelete, success:successDelete } = blogDelete

    useEffect(() => {

        dispatch({type: 'CREATE_BLOG_RESET'})
        
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } 
        if (createdBlog) {
            navigate(`/admin/blogs/${createdBlog.id}/edit`)
        }
         else {
            dispatch(listBlogs())
        }

    }, [dispatch, navigate, userInfo, createdBlog, successDelete])

    const deleteHandler = (id) => {
        
        if (window.confirm(t('Are you sure you want to delete this product?'))) {
            dispatch(deleteBlog(id))
        }

    }

    const createBookHandler = () => {
        dispatch(createBlog())
    }

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <Message variant='danger'>{error}</Message>
    }

    return (
        <Container className='my-5'>
            <Row className='align-items-center'>
                <Col><h1>{t('Blogs')}</h1></Col>

                <Col className='d-flex justify-content-end'>
                    <Button className='my-3' onClick={createBookHandler}>
                        <i className='fas fa-plus'></i> {('Create Blog ')}
                    </Button>
                </Col>
            </Row>

            {loadingDelete && <Loader />}
            {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

            {loadingCreate && <Loader />}
            {errorCreate && <Message variant='danger'>{errorCreate}</Message>} 

            <div>
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{t('name').toUpperCase()}</th>
                            <th>{t('Created').toUpperCase()}</th>
                            <th>{t('Actions').toUpperCase()}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {blogs && blogs.map(blog => (
                            <tr key={blog.id}>
                                <td>{blog.id}</td>
                                <td>{blog.name.substring(0,55)}</td>
                                <td>{blog.createdAt.substring(0,10)}</td>

                                <td>
                                    <Link to={`/admin/blogs/${blog.id}/edit`}>
                                        <Button variant='light' className='btn-sm'>
                                            <i className='fas fa-edit'></i>
                                        </Button>
                                    </Link>

                                    <Button variant='danger' className='btn-sm mt-1 mt-lg-0 ms-lg-2'
                                        onClick={() => deleteHandler(blog.id)}>
                                        <i className='fas fa-trash'></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

            </div>
        </Container>
    )
}

export default BlogsListScreen
