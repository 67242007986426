import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { listAllBooks } from '../actions/bookActions'
import { Container, Row, Col } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import Hero from '../components/Hero'
import BooksCarousel from '../components/BooksCarousel'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Cookies from '../components/Cookies'


const HomeScreen = () => {

    const dispatch = useDispatch()
    const { t } = useTranslation()
    const location = useLocation()

    const booksListAll = useSelector(state => state.booksListAll)
    const { loading, books, error} = booksListAll

    useEffect(() => {

        if(!books) {

            dispatch(listAllBooks())
        }
        
        if (location.search) {
            const targetSection = document.getElementById(location.search.slice(1));
            targetSection.scrollIntoView({ behavior: "smooth" });
        }
        
        
    }, [dispatch, location, books])

    return (
        <div>
            <ToastContainer/>
            <Hero />
            <Container>
                <section id='bestSeller' >
                    <h2 className='text-center mt-5 fw-semibold green-text'>{t('best seller')}</h2>
                    {loading ? <Loader/> : error ? <Message variant='danger'>{error}</Message>
                     : books && (<BooksCarousel books={books} props={{'bestSeller': true}}/>)}
                    
                </section>

                <section id='tours'>
                    <h2 className='text-center mb-4 fw-semibold green-text'>{t('book club')}</h2>
                    
                    <motion.video muted autoPlay loop controls initial={{opacity: 0, scale:0.8, y: 200}} whileInView={{opacity:1, scale: 1,  y:0}} transition={{duration: 0.8}}>
                        <motion.source src={require('../assets/homepagevid.mp4')} type='video/mp4'></motion.source>
                    </motion.video>

                    <motion.div className="tour-description"
                     initial={{opacity: 0, scale:0.8, y: 200}} whileInView={{opacity:1, scale: 1,  y:0}} transition={{duration: 0.8}}>
                        <h2 className='green-text fs-4'>{t("Counting Stars Out Of Heaven in Us")},</h2>
                        <p>Yun Dongju</p>
                        <br />
                        <p className='light-green-text fst-italic'>
                        <span>{t('In the heart of Stuttgart in 2023')}</span>
                        <span>{t('Hanl Publication is born')}.</span>
                        <span>{t('We provide a specialised platform')}</span>
                        <span>{t('that')} <span className='fw-semibold'>{t('informs, inspires and transforms you')}.</span></span>
                        <span>{t('Take a look around and see what catches your heart')}.</span>
                        </p>
                        <div className='light-green-text align-self-end mt-2 fst-italic'>
                            <p className='m-0 fs-6'>{t('Wishing you a delightful experience')}</p>
                            <span> - Hanl Publication</span>
                        </div>
                    </motion.div>
                </section>

                <motion.section id='initiatives' className='my-5'
                    initial={{opacity: 0, scale:0.8, y: 200}} whileInView={{opacity:1, scale: 1,  y:0}} transition={{duration: 0.8}}>
                    <h2 className='mb-5 text-center fw-semibold green-text'>{t('Social initiatives our literature focuses towards')}</h2>
                    <img className='social-initiatives' src={require('../assets/social-initiatives.png')} alt="social-initiatives" />

                </motion.section>

                <section id="places" className='my-5'>
                    <h2 className='my-5 text-center fw-semibold green-text'>{t('Explore Places')}</h2>

                    <Row className='justify-content-around'>
                        <Col sm={12} md={2} className='places-card'>
                            <div></div>
                            <Link to='/explore-places/koreea'>
                                <img src={require('../assets/korean-culture.jpg')} alt="" />
                                <p className='fw-semibold green-text'>{t('Korean Culture')}</p>
                            </Link>
                        </Col>
                        <Col sm={12} md={2} className='places-card stuttgart'>
                            <div className='places-box'></div>
                            <Link to='/explore-places/stuttgart'>
                                <img src={require('../assets/stuttgart.jpg')} alt="" />
                                <p className='fw-semibold green-text'>{t('Stuttgart: A place called Home')}</p>
                            </Link>
                        </Col>
                    </Row>

                </section>
            </Container>
            <Cookies />
        </div>
    )
}

export default HomeScreen
