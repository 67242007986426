import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const HeroBooks = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.7 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.8 }}
    >
      <header id="hero">
        <div className="hero-2">
          <h1>{t("books that warm your heart")}</h1>
        </div>

        <img
          className="hero-2-img "
          src={require("../assets/new-banner.jpg")}
          alt="hero"
        />
      </header>
    </motion.div>
  );
};

export default HeroBooks;
