export const booksListReducer = (state = { }, action) => {
    switch(action.type) {
        case 'BOOKS_LIST_REQUEST':
            return {
                loading: true,
            }

        case 'BOOKS_LIST_SUCCESS':
            return {
                loading: false,
                books: action.payload
            }
        
        case 'BOOKS_LIST_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
        
    }
}

export const booksListAllReducer = (state = { }, action) => {
    switch(action.type) {
        case 'BOOKS_LIST_ALL_REQUEST':
            return {
                loading: true,
               
            }

        case 'BOOKS_LIST_ALL_SUCCESS':
            return {
                loading: false,
                books: action.payload
            }
        
        case 'BOOKS_LIST_ALL_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
        
    }
}


export const bookListReducer = (state = {book: {reviews: []} }, action) => {
    switch(action.type) {
        case 'BOOK_LIST_REQUEST':
            return {
                ...state,
                loading: true,
            }

        case 'BOOK_LIST_SUCCESS':
            return {
                loading: false,
                book: action.payload
            }
        
        case 'BOOK_LIST_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
        
    }
}


export const addReviewReducer = (state = { }, action) => {
    switch(action.type) {
        case 'REVIEWS_CREATE_REQUEST':
            return {
                loading: true,
            }

        case 'REVIEWS_CREATE_SUCCESS':
            return {
                loading: false,
                success: true
            }
            
        case 'REVIEWS_CREATE_FAIL':
            return {
                loading: false,
                error: action.payload
            }
        
        case 'REVIEWS_CREATE_RESET':
            return { }

        default:
            return state
    }
}


export const listCategoriesReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_CATEGORIES_REQUEST':
            return {
                loading: true,

            }
        
        case 'LIST_CATEGORIES_SUCCESS':
            return {
                loading: false,
                categories: action.payload
            }
        
        case 'LIST_CATEGORIES_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}


export const createBookReducer = (state = { }, action) => {
    switch(action.type) {
        case 'CREATE_BOOK_REQUEST':
            return {
                loading: true
            }
        
        case 'CREATE_BOOK_SUCCESS':
            return {
                loading: false,
                success: true,
                book: action.payload,
            }
        
        case 'CREATE_BOOK_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'CREATE_BOOK_RESET':
            return {

            }
        
        default:
            return state
    }
}


export const updateBookReducer = (state = { }, action) => {
    switch(action.type){
        case 'UPDATE_BOOK_REQUEST':
            return {
                loading:true,
            }

        case 'UPDATE_BOOK_SUCCESS':
            return {
                loading: false,
                success: true
            }

        case 'UPDATE_BOOK_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'UPDATE_BOOK_RESET':
            return { }

        default: 
            return state
        
    }
}


export const deleteBookReducer = (state = { }, action) => {
    switch(action.type) {
        case 'DELETE_BOOK_REQUEST':
            return {
                loading: true
            }

        case 'DELETE_BOOK_SUCCESS' :
            return {
                loading: false,
                success: true
            }

        case 'DELETE_BOOK_FAIL':
            return {
                loading: false,
                error: action.payload
            }
        
        default:
            return state
    }
}


export const listEbooksReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_EBOOKS_REQUEST':
            return {
                loading: true
            }

        case 'LIST_EBOOKS_SUCCESS':
            return {
                loading: false,
                ebooks: action.payload
            }

        case 'LIST_EBOOKS_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default: 
            return state
    }
}