import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listAuthors } from '../actions/authorActions'
import { useLocation } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import BlogCard from '../components/BlogCard'
import Message from '../components/Message'
import Loader from '../components/Loader'
import Filter from '../components/Filter'
import HanlTeam from '../components/HanlTeam'


const PeopleScreen = () => {

    const [peoples, setPeoples] = useState()
    const [filter, setFilter] = useState('')
    const authorsList = useSelector(state => state.authorsList)
    const {t} = useTranslation()
    const { loading, error, authors } = authorsList
    const dispatch = useDispatch()  
    const location = useLocation()
 

    useEffect(() => {
        setFilter(location.search.slice(1))

        if (authors) {
           
            setPeoples(authors)
            
            if (filter) {
                setPeoples(authors.filter(pers => pers.job === filter))
            }
            
        } else {

            dispatch(listAuthors())
        }
        
    }, [dispatch, location, filter, authors])  

    return (
        <Container>
            <h1 className='page-title mt-3 mb-2 fs-2 '>{t('Hanl Book\'s People')}</h1>
            <iframe className='mt-lg-3 mb-lg-5 peoples-gif' title='peoplesGif' width={'100%'} height={'380'} src="https://embed.lottiefiles.com/animation/67614"></iframe>
            {loading ? <Loader /> : error && <Message variant={'danger'}> {error}</Message>}

            <div className="d-flex flex-column flex-xl-row pe-0 pe-lg-4 ">
                <div className='mb-5'>
                    <Filter filters={{'authors': authors}}/>
                </div>

                <div>
                    <div className='w-100 d-flex justify-content-center'>
                        <span id='artist' className={`filter-active green-text text-center fs-2 fw-bold mb-5 mt-2 ${filter !== 'artist' ? 'd-none' : ''}`}>{t('Artists')}</span>
                        <span id='author' className={`filter-active green-text text-center fs-2 fw-bold mb-5 mt-2 ${filter !== 'author' ? 'd-none' : ''}`}>{t('Authors')}</span>
                        <span id='team' className={`filter-active green-text text-center fs-2 fw-bold mb-5 mt-2 ${filter !== 'team' ? 'd-none' : ''}`}>Hanl's Team</span>
                    </div>
                    
                    <div className='d-flex flex-wrap justify-content-center justify-content-lg-between'>

                    {(filter === 'team' && peoples) ? peoples.map((person,idx) => (
                            <HanlTeam key={person.id} person={person} idx={idx}/>
                     )) 
                     : peoples ? peoples.filter(author => author.job !== 'team').map((author, idx) => (
                        <div id={author.id} key={author.id}>
                            <BlogCard blog={author} flex={idx}/>
                        </div>
                     )) 
                     : (<p className='mt-5'>{t('no-artists')}</p>)
                    }

                    </div>
                    
                    
                </div>
            </div>
        </Container>
    )
}

export default PeopleScreen
