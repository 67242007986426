import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Container} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listEvent, updateEvent } from '../actions/eventActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer'
import axios from 'axios'


const EventEditScreen = () => {

    const [eventName, setEventName] = useState('')
    const [eventNameDe, setEventNameDe] = useState('')
    const [uploading, setUploading] = useState(false)
    const [image, setImage] = useState('')
    
    const { id } = useParams()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const eventList = useSelector(state => state.eventList)
    const { loading, error, event } = eventList

    const eventUpdate = useSelector(state => state.eventUpdate)
    const { loading:updateLoading, error:updateError, success } = eventUpdate

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } 

        if (success) {
            dispatch({type: 'UPDATE_EVENT_RESET'})
            navigate('/admin/events')

        } else {
        
            if (!event || event.id !== id) {
                dispatch(listEvent(id))
            }
            else {
                setEventName(event.name)
                setEventNameDe(event.nameDe)
            }
        }

    }, [id, userInfo, success, event, navigate, dispatch])

    const uploadFileHandler = async (e) => {
        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('eventImage', file)
        formData.append('eventId', id)

        setUploading(true)

        try {

            const config = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post(
                `/api/events/upload/`,
                formData,
                config,
            )
            
            setImage(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
            console.log(error)
        }
    }
   
    const submitHandler = (e) => {
        e.preventDefault()
        
        dispatch(updateEvent(id, {
            eventName,
            eventNameDe
        }))
        
    }

    return (
        <Container className='my-5'>
            <Link to='/admin/events' className='btn btn-primary'>Go Back</Link>
            <FormContainer>
                <h1>Edit Event</h1>

               {updateLoading && <Loader/>}
               {updateError && <Message variant='danger'>{updateError}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='name'>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type='name' placeholder='Enter Name' value={eventName}
                                    onChange={(e) => setEventName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='nameDe' className='mb-4'>
                                <Form.Label>Name in Deutsch</Form.Label>
                                <Form.Control type='name' placeholder='Enter Name' value={eventNameDe ? eventNameDe : ''}
                                    onChange={(e) => setEventNameDe(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='image'>
                                <Form.Label>Image</Form.Label>
                                <Form.Control type='text' placeholder='Enter Image' value={image ? image : ''}
                                    onChange={(e) => setImage(e.target.value)} disabled/>

                                <Form.Control type='file' label='Choose File'
                                    custom='true' onChange={uploadFileHandler} />

                                {uploading && <Loader />}
                            </Form.Group>


                            <Button type='submit' variant='primary mt-4'>Update</Button>
                        </Form>
                    )
                }
            
            </FormContainer>
        </Container>
    )
}

export default EventEditScreen
