import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useTranslation } from 'react-i18next'

const Unsubscribe = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const location = useLocation()

    const id = location.search.split('&')[0].slice(1)
    const token = location.search.split('&')[1]
    
    const handleUnsubscribe = () => {
        if (window.confirm('Are you sure?')) {
            
            const { data } = axios.delete(`/api/blogs/unsubscribe/${id}/${token}`)

            toast.success(data)
            navigate('/')
        }
    }
    return (
        <div className="text-center my-5">
            <h3>{t('unsubscribe-here')}</h3>
            <button onClick={handleUnsubscribe} className='bg-danger btn text-white'>{t('Unsubscribe')}</button>
        </div>
    )
}

export default Unsubscribe
