import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Button} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingAddress } from '../actions/cartActions'
import { useTranslation } from 'react-i18next'
import FormContainer from '../components/FormContainer'


const ShippingScreen = () => {
    
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart

    const navigate = useNavigate()

    const [address, setAddress] = useState(shippingAddress.address)
    const [city, setCity] = useState(shippingAddress.city)
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
    const [country, setCountry] = useState(shippingAddress.country)


    const submitHandler = () => {
        dispatch(saveShippingAddress({ address, city, postalCode, country }))
        navigate('/placeorder')
    }

    return (
        <FormContainer>
            
            <h1>{t('Shipping')}</h1>
            <Form onSubmit={submitHandler}>
                <Form.Group controlId='address'>
                    <Form.Label>{t('Address')}</Form.Label>
                    <Form.Control required type='text' placeholder={t('Enter Address')} value={address ? address : ''}
                        onChange={(e) => setAddress(e.target.value)}/>                        
                </Form.Group>

                <Form.Group controlId='addcityress'>
                    <Form.Label>{t('City')}</Form.Label>
                    <Form.Control required type='text' placeholder={t('Enter City')} value={city ? city : ''}
                        onChange={(e) => setCity(e.target.value)}/>                        
                </Form.Group>

                <Form.Group controlId='postalCode'>
                    <Form.Label>{t('Postal Code')}</Form.Label>
                    <Form.Control required type='text' placeholder={t('Enter Postal Code')} value={postalCode ? postalCode : ''}
                        onChange={(e) => setPostalCode(e.target.value)}/>                        
                </Form.Group>

                <Form.Group controlId='country'>
                    <Form.Label>{t('Country')}</Form.Label>
                    <Form.Control required type='text' placeholder={t('Enter Country')} value={country ? country : ''}
                        onChange={(e) => setCountry(e.target.value)}/>                        
                </Form.Group>

                <Button type='submit' variant='primary' className='mt-4'>
                    {t('Continue')} 
                </Button>
            </Form>
        </FormContainer>
    )
}

export default ShippingScreen
