import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const HeroUpcomingBook = ({ isUpcomingBook }) => {
  const { t } = useTranslation();

  return (
    <header id="hero" className="justify-content-around future-book-header">
      {isUpcomingBook && (
        <>
          <motion.img
            initial={{ opacity: 0, scale: 0.4 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8 }}
            className="future-launch"
            src={isUpcomingBook.image}
            alt="upcomingBook"
          />

          <motion.div
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="context upcoming-book"
          >
            <h1>{t("upcoming book")}</h1>
            <p>{t("we are excited")}</p>
            <p>{t("taking preorders")}</p>
            <div className="cta-actions">
              <Link
                className="green-btn-sec"
                to={`/books/${isUpcomingBook.id}`}
              >
                {/* {t("preorder now")} */}
                {t('Buy Now')}
              </Link>
              <small className="mt-2">
                <Link className="fw-normal new-sign-up" to={"/register"}>
                  {t("new sign up")}
                </Link>
              </small>
            </div>
          </motion.div>
        </>
      )}
    </header>
  );
};

export default HeroUpcomingBook;
