import { Form } from 'react-bootstrap'
import { useNavigate }  from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const SearchBox = () => {
    
    const { t } = useTranslation()
    const navigate = useNavigate()

    let keyword = ''
    
    const handleChange = (e) => {

        keyword = e.target.value

        navigate(`/books/?keyword=${keyword}&page=1`)
    }
    
    return (
        <>
        <Form id='desktop-form' className=' d-flex justify-content-end my-lg-0 position-relative' onSubmit={e => e.preventDefault()}>
            <Form.Control id='search-box' placeholder={t('search')} type='text' name='q' onChange={e => handleChange(e)} 
             />    
             <i className="fa-solid fa-magnifying-glass position-absolute mx-2"></i>   
        </Form>
         <Form id='tablet-form' className='d-flex justify-content-end my-lg-0 position-relative' onSubmit={e => e.preventDefault()}>
            <Form.Control id='search-box' type='text' name='q' onChange={e => handleChange(e)} 
            />    
            <i className="fa-solid fa-magnifying-glass position-absolute mx-2"></i>   
        </Form>
     </>
    )
}

export default SearchBox
