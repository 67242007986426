import axios from 'axios'

export const listBooks = (keyword = '', filter) => async(dispatch) => {

    try {
        dispatch({type: 'BOOKS_LIST_REQUEST'})

        const { data } = await axios.get(`/api/books/${keyword}`,
            {'filter': filter}
        )

        dispatch({
            type: 'BOOKS_LIST_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'BOOKS_LIST_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const listAllBooks = () => async(dispatch) => {

    try {
        dispatch({type: 'BOOKS_LIST_ALL_REQUEST'})

        const { data } = await axios.get(`/api/books/all`)

        dispatch({
            type: 'BOOKS_LIST_ALL_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'BOOKS_LIST_ALL_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const listEbooks = () => async(dispatch, getState) => {

    try {
        dispatch({type: 'LIST_EBOOKS_REQUEST'})

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/books/pdfs/`, config)

        dispatch({
            type: 'LIST_EBOOKS_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_EBOOKS_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const listBook = (id) => async(dispatch) => {

    try {
        dispatch({type: 'BOOK_LIST_REQUEST'})

        const { data } = await axios.get(`/api/books/${id}/`)

        dispatch({
            type: 'BOOK_LIST_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'BOOK_LIST_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const createReview = (id, review) => async(dispatch, getState) => {
    try {
        dispatch({type: 'REVIEWS_CREATE_REQUEST'})

        const { userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`/api/books/${id}/reviews/create/`, 
            review,
            config
        )

        dispatch({
            type: 'REVIEWS_CREATE_SUCCESS',
            payload: data
        })


    } catch (error) {
          dispatch({
            type: 'REVIEWS_CREATE_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const listCategories = () => async(dispatch) => {
    try {

        dispatch({type: 'LIST_CATEGORIES_REQUEST'})

        const { data } = await axios.get(`/api/books/categories/`)

        dispatch({
            type: 'LIST_CATEGORIES_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_CATEGORIES_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const createBook = () => async(dispatch, getState) => {
    try {
        dispatch({ type: 'CREATE_BOOK_REQUEST '})

        const { userLogin : {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`/api/books/create/`, {} , config)

        dispatch({type: 'CREATE_BOOK_SUCCESS', payload: data})

    } catch (error) {
        dispatch({
            type: 'CREATE_BOOK_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const updateBook = (book) => async(dispatch, getState) => {
    try {
        
        dispatch({type: 'UPDATE_BOOK_REQUEST'})

        const { userLogin: {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(`/api/books/${book.id}/edit/`,
            book,
            config
        )

        dispatch({
            type: 'UPDATE_BOOK_SUCCESS',
            payload: data
        })

        dispatch({
            type: 'BOOK_LIST_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'UPDATE_BOOK_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const deleteBook = (id) => async(dispatch, getState) => {
    try {
        
        dispatch({ type: 'DELETE_BOOK_REQUEST' })

        const { userLogin: { userInfo }} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(`/api/books/${id}/delete/`, config)

        dispatch({type: 'DELETE_BOOK_SUCCESS', payload: data})

        
    } catch (error) {
        dispatch({
            type: 'DELETE_BOOK_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}