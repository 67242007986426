import axios from 'axios'


export const listEvents = () => async(dispatch) => {

    try {
        dispatch({type: 'LIST_EVENTS_REQUEST'})

        const { data } = await axios.get(`/api/events/`)

        dispatch({
            type: 'LIST_EVENTS_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_EVENTS_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const listEvent = (id) => async(dispatch) => {

    try {
        dispatch({type: 'LIST_EVENT_REQUEST'})

        const { data } = await axios.get(`/api/events/${id}/`)

        dispatch({
            type: 'LIST_EVENT_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_EVENT_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}

export const createEvent = () => async(dispatch, getState) => {

    try {
        dispatch({type: 'CREATE_EVENT_REQUEST'})

        const {userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`/api/events/create/`, { }, config)

        dispatch({
            type: 'CREATE_EVENT_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'CREATE_EVENT_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const updateEvent = (id, event) => async(dispatch, getState) => {

    try {
        dispatch({type: 'UPDATE_EVENT_REQUEST'})

        const {userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(`/api/events/${id}/update/`, event, config)

        dispatch({
            type: 'UPDATE_EVENT_SUCCESS',
            payload: data
        })

        dispatch({
            type: 'LIST_EVENT_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'UPDATE_EVENT_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const deleteEvent = (id) => async(dispatch, getState) => {

    try {
        dispatch({type: 'DELETE_EVENT_REQUEST'})

        const {userLogin: { userInfo } } = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(`/api/events/${id}/delete/`, config)

        dispatch({
            type: 'DELETE_EVENT_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'DELETE_EVENT_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}
