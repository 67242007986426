import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Button, Container} from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { sendNewsletter } from '../actions/blogActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer'
import { toast } from 'react-toastify'
import { useTranslation } from "react-i18next";


const NewsletterScreen = () => {

    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { t } = useTranslation();
    const newsletterSend = useSelector(state => state.newsletterSend)
    const { loading, error, detail } = newsletterSend

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } 

        dispatch({
            type: 'SEND_NEWSLETTER_RESET'
        })
        
        if (detail) {
            if (detail.sent) {

                toast.success(detail.sent)
            } 
            if (detail.notSent) {
                toast.warning(detail.notSent)
            }
        }

    }, [detail, userInfo, navigate, dispatch])


    const submitHandler = (e) => {
        e.preventDefault()
        
        dispatch(sendNewsletter({
            subject,
            body
        }))

        setSubject('')
        setBody('')
    }

    return (
        <Container className='my-5'>
            <Link to='/' className='btn btn-primary'>{t('Go Back')}</Link>
            <FormContainer>
                <h1>{t('Send a Newsletter')}</h1>

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='subject' className='mb-4'>
                                <Form.Label>Subject</Form.Label>
                                <Form.Control type='name' placeholder='Enter Subject' value={subject}
                                    onChange={(e) => setSubject(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='body' className='mb-4'>
                                <Form.Label>Body</Form.Label>
                                <Form.Control as='textarea' type='text' placeholder='Enter body' value={body ? body : ''}
                                    onChange={(e) => setBody(e.target.value)}/>
                            </Form.Group>

                            <Button type='submit' variant='primary mt-4'>Send</Button>
                        </Form>
                    )
                }
            
            </FormContainer>
        </Container>
    )
}

export default NewsletterScreen
