export const listAuthorsReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_AUTHORS_REQUEST':
            return {
                loading: true,

            }
        
        case 'LIST_AUTHORS_SUCCESS':
            return {
                loading: false,
                authors: action.payload
            }
        
        case 'LIST_AUTHORS_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}


export const listAuthorReducer = (state = { }, action) => {
    switch(action.type) {
        case 'LIST_AUTHOR_REQUEST':
            return {
                loading: true,
            }
        
        case 'LIST_AUTHOR_SUCCESS':
            return {
                loading: false,
                author: action.payload
            }
        
        case 'LIST_AUTHOR_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state
    }
}


export const updateAuthorReducer = (state = { }, action) => {
    switch(action.type) {
        case 'UPDATE_AUTHOR_REQUEST':
            return {
                loading: true,
            }
        
        case 'UPDATE_AUTHOR_SUCCESS':
            return {
                loading: false,
                success: true,
            }
        
        case 'UPDATE_AUTHOR_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'UPDATE_AUTHOR_RESET':
            return {
                
            }

        default:
            return state
    }
}


export const deleteAuthorReducer = (state = { }, action) => {
    switch(action.type) {
        case 'DELETE_AUTHOR_REQUEST':
            return {
                loading: true,
            }
        
        case 'DELETE_AUTHOR_SUCCESS':
            return {
                loading: false,
                success: true,
            }
        
        case 'DELETE_AUTHOR_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'DELETE_AUTHOR_RESET':
            return {
                
            }

        default:
            return state
    }
}


export const createAuthorReducer = (state = { }, action) => {
    switch(action.type) {
        case 'CREATE_AUTHOR_REQUEST':
            return {
                loading: true,
            }
        
        case 'CREATE_AUTHOR_SUCCESS':
            return {
                loading: false,
                author: action.payload,
                success: true,
            }
        
        case 'CREATE_AUTHOR_FAIL':
            return {
                loading: false,
                error: action.payload
            }

        case 'CREATE_AUTHOR_RESET':
            return {
                
            }

        default:
            return state
    }
}