import { useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import axios from 'axios'
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'

const ResetScreen = () => {

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const { t } = useTranslation()

    const {id, token} = useParams()
    const navigate = useNavigate()

    const submitHandler = async (e) => {
        e.preventDefault()

        if (password !== '' && password === confirmPassword) {

            const { data } = await axios.post(`/api/users/reset/${id}/${token}/`, {
                'password': password
            })

            if (data.success) {
                toast.success('Password successfully changed!')
                navigate('/login')

            } else if (data.error) {
                setMessage('Bad request!')
            }
            

        } else {
            setMessage(t('Passwords do not match'))
        }
        
    }

    return (
      
        <FormContainer>
            <h1>{t('Enter a new Password')}!</h1>   
            <p>{t('Enter a new Password p')}</p>
            <div>
                {message && <Message variant='warning'>{message}</Message>}
                <Form onSubmit={submitHandler}>

                    <Form.Group controlId='password' className='mt-3 mb-2'>
                        <Form.Label className='m-0'>{t('password')}</Form.Label>
                        <Form.Control required type='password' placeholder={t('enter password')} value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                    </Form.Group>

                    <Form.Group controlId='password-confirm' className='mt-3 mb-2'>
                        <Form.Label className='m-0'>{t('confirm password')}</Form.Label>
                        <Form.Control required type='password' placeholder={t('confirm password')} value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}/>
                    </Form.Group>


                    <div className='d-flex justify-content-between'>
                        <Button type='submit' variant='primary mt-4'>{t('submit')}</Button>
                    </div>
                </Form>

            </div>
           
        </FormContainer>
    )
}

export default ResetScreen
