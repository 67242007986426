import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Form, Button, Row, Col, Table, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateUserProfile, getUserDetails } from "../actions/userActions";
import { getAllOrders } from "../actions/orderActions";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import Loader from "../components/Loader";
import Message from "../components/Message";
import "react-toastify/dist/ReactToastify.css";

const ProfileScreen = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const userDetails = useSelector((state) => state.userDetails);
  const { error, loading, user } = userDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const myOrders = useSelector((state) => state.myOrders);
  const { loading: loadingOrders, error: errorOrders, myOrdersInfo } = myOrders;

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    } else {
      if (!user || !user.name || success || userInfo.id !== user.id) {
        dispatch({
          type: "USER_UPDATE_RESET",
        });
        dispatch(getUserDetails());
      } else {
        setName(user.name);
        setEmail(user.email);
      }

      dispatch(getAllOrders());
    }
  }, [navigate, dispatch, userInfo, success, user]);

  const submitHandler = (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
      setTimeout(() => {
        setMessage("");
      }, 5000);
    } else {
      toast.success("Profile Updated!");
      dispatch(
        updateUserProfile({
          id: userInfo.id,
          name: name,
          email: email,
          password: password,
        })
      );

      setMessage("");
      setPassword("");
      setConfirmPassword("");
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container className="user-profile">
      <ToastContainer />
      <Row className="my-5">
        <Col md={3}>
          <h2>{t("user profile")}</h2>

          {message && <Message variant="danger">{message}</Message>}
          {error && <Message variant="danger">{error}</Message>}
          <Form onSubmit={submitHandler}>
            <Form.Group controlId="name">
              <Form.Label>
                {t("name")}: {name.split("@")[0]}
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="email">
              <Form.Label>
                {t("email address")}: {email}
              </Form.Label>
            </Form.Group>

            <Form.Group controlId="password" className="mt-5">
              <h4>{t("change password")}</h4>
              <Form.Label>{t("password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("enter password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="password-confirm">
              <Form.Label>{t("confirm password")}</Form.Label>
              <Form.Control
                type="password"
                placeholder={t("confirm password")}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Form.Group>

            <Button type="submit" variant="primary my-4">
              {t("update")}
            </Button>
          </Form>
        </Col>

        <Col md={9}>
          <h2>{t("my orders")}</h2>
          {loadingOrders ? (
            <Loader />
          ) : errorOrders ? (
            <Message variant="danger">{errorOrders}</Message>
          ) : (
            <Table striped responsive className="table-md">
              <thead>
                <tr>
                  <th>{t("id")}</th>
                  <th>{t("date")}</th>
                  <th>{t("total")}</th>
                  <th>{t("paid")}</th>
                  <th>{t("delivered")}</th>
                  <th>{t("details")}</th>
                </tr>
              </thead>

              <tbody>
                {myOrdersInfo &&
                  myOrdersInfo.map((order) => (
                    <tr key={order.id}>
                      <td>{order.orderNumber || order.id}</td>
                      <td>{order.createdAt.substring(0, 10)}</td>
                      <td>{order.totalPrice} &euro;</td>
                      <td>
                        {order.isPaid ? (
                          order.paidAt.substring(0, 10)
                        ) : (
                          <i
                            className="fas fa-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td>
                      <td>
                        {order.isDelivered ? (
                          order.deliveredAt.substring(0, 10)
                        ) : (
                          <i
                            className="fas fa-times"
                            style={{ color: "red" }}
                          ></i>
                        )}
                      </td>
                      <td>
                        <Link className="" to={`/order/${order.id}`}>
                          <button className="p-1 btn rounded btn-primary">
                            {t("view-order")}
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ProfileScreen;
