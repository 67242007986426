import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { motion } from 'framer-motion'
import { addToWishlist, addToCart, removeFromWishlist } from '../actions/cartActions'
import { useTranslation } from 'react-i18next'
import Rating from '../components/Rating'
import 'react-toastify/dist/ReactToastify.css';

const BookCardInfo = ({book}) => {

    const [bookType, setBookType] = useState('hardCover')
    const [language, setLanguage] = useState('')
    const [availableLanguages, setAvailableLanguages] = useState([])
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const { i18n, t } = useTranslation()

    const wishlist  = useSelector((state) => state.wishlist)
    const { wishlistItems } = wishlist

    useEffect(() => {

        if (book && book.languages) {
            setAvailableLanguages(book.languages.split(','))
            setLanguage(book.languages.split(',')[0])
        }

    }, [book])

    
    const wishlistHandler = () => {

        dispatch(addToWishlist(id))
        document.getElementById('cart-notification').classList.remove('d-none')
        toast.success(t('Added to wishlist'))
    }

    const buyNow = () => {
        navigate(`/cart/${id}?qty=1&type=${bookType}&lang=${language}`)

    }

    const addInCart = () => {
        dispatch(addToCart(id, 1, bookType, language))
        document.getElementById('cart-notification').classList.remove('d-none')
        toast.success(t('Added to cart'))
    }

    const readMore = (e) => {
        const extendedText = document.getElementById('read-more')
        extendedText.classList.toggle('d-none')

        if(extendedText.classList.contains('d-none')) {
            e.target.innerText = t('Read More')
       } else {
            e.target.innerText = t('Read Less')
       }

    }

    const handleRemoveWishlist = () => {
        dispatch(removeFromWishlist(id))
        document.getElementById('cart-notification').classList.add('d-none')
    }

    const closePreview = (e) => {
        document.querySelector('.book-preview').classList.add('d-none')
        document.querySelector('.book-preview-div').classList.add('d-none')
        document.querySelector('.window-blur').classList.add('d-none')
        e.target.classList.add('d-none')
    }

    const openPreview = () => {
        document.querySelector('.book-preview').classList.remove('d-none')
        document.querySelector('.book-preview-div').classList.remove('d-none')
        document.querySelector('.fa-circle-xmark').classList.remove('d-none')
        document.querySelector('.window-blur').classList.remove('d-none')
    }

    return (
        <>
            <ToastContainer/>
            <div className="book-page-card mt-5 d-flex justify-content-between position-relative">
                
                {book && <> 
                    <div className='book-image-div'>
                        <motion.img initial={{opacity: 0, x:-100}} animate={{opacity: 1, x:0}}
                        transition={{duration: 0.5}} src={book.image} alt="" />
                    </div>

                    <motion.div initial={{opacity: 0, x:200}} animate={{opacity: 1, x:0}}
                    transition={{duration: 0.5}}>
                        <div className="d-flex justify-content-between">
                            <div className='d-flex'>
                                <div>
                                    <h1 className='book-name'>
                                        {book.name}
                                    </h1>
                                    {book.author && book.author.map((author, idx) => (
                                        <small className='d-block' key={idx}>by {author.firstName}</small>
                                    ))}
                                </div>
                                <div className='wishlist-btn'>
                                    {wishlistItems && wishlistItems.find(item => item.id === id) ? (

                                        <i onClick={handleRemoveWishlist} style={{color: '#CC7578'}} className="fa-solid fa-heart fa-2x"></i>
                                    ) : (
                                        <i onClick={wishlistHandler} style={{color: '#CC7578'}} className="fa-regular fa-heart fa-2x"></i>
                                    )}
                                </div>
                            </div>
                            {book.rating && (
                                <div>
                                    <span>{t('Reviews')} ({book.numReviews})</span>

                                    <div className='d-flex'>
                                        <Rating value={book.rating} color='#f8e825'/> 
                                        <span className='ms-1'>({book.rating})</span>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="book-page-description mt-2">
                            <p className="fw-bold">{t('Description')}</p>
                            {book.description && book.description.length > 200 ? (
                                <div className='book-description'>
                                    <p>{(i18n.language === 'de' && book.descriptionDe) ? book.descriptionDe.substring(0, 620)
                                     : book.description.substring(0, 620)} 
                                        <span id='read-more' className='d-none'>{(i18n.language === 'de' && book.descriptionDe) ? book.descriptionDe.substring(620, book.descriptionDe.length)
                                         : book.description.substring(620)}</span>
                                         {book.description.length > 620 && (
                                            <span onClick={readMore} className='ms-1 green-link text-secondary fw-bold read-more-btn'>...{t('Read More')}</span>
                                         )}
                                    </p>
                                </div>
                            ): (<p>{(i18n.language === 'de' && book.descriptionDe) ? book.descriptionDe 
                                    : book.description}</p>)}
                            
                        
                            <div className='mt-auto mb-2'>
                                <div className='d-flex flex-row align-items-md-end justify-content-around justify-content-md-start'>
                                    <div className='mb-2 mb-md-0 ms-md-2 me-md-4 p-1 rounded'>
                                        <div >
                                            <input type="radio" id="hardCover" onClick={(e) => setBookType(e.target.value)}
                                                name='bookType' value="hardCover" defaultChecked={true}
                                                className={bookType === 'hardCover' ? 'brown' : ''}/>
                                            <label htmlFor="hardCover" className={`${bookType === 'hardCover' ? 'brown' : ''} ms-1 book-type`}>{t('Paper Back')}</label>
                                        </div>
                                        <p className={`${bookType === 'hardCover' ? 'brown' : ''} fw-bold my-0 text-center fs-6 `} >{book.hardCoverPrice} &euro;</p>
                                    </div>
                                    <div className='vertical-line'></div>
                                    <div className='py-1 px-3 rounded'>
                                        <input type="radio" id="eBook"  onClick={(e) => setBookType(e.target.value)}
                                            name='bookType' value="eBook" className={`${bookType === 'eBook' ? 'brown' : ''}`}
                                            disabled={+book.eBookPrice <= 0 ? true : false}/>
                                        <label htmlFor="eBook" className={`${bookType === 'eBook' ? 'brown' : ''} ms-1 book-type`}> eBook</label>
                                        <p className={`${bookType === 'eBook' ? 'brown' : ''} fw-bold fs-6 my-0`} >
                                            {+book.eBookPrice <= 0 ? t('Coming Soon') : book.eBookPrice} &euro;
                                            </p>
                                    </div>
                                    
                                </div>
                                <div className='d-flex align-items-center'>
                                    <div className='mt-3 me-2 position-relative book-language'>
                                            <Form.Label className='select-lang-label ms-2'>{t('Select language')}</Form.Label>
                                            <select aria-label="Select language" placeholder='Select language' 
                                                value={language} className='text-start select-language'
                                                onChange={(e) => setLanguage(e.target.value)}>
                                                    {availableLanguages && availableLanguages.map((lang,idx) => (
                                                        <option key={idx} value={lang.toLowerCase().trim()} >
                                                            {lang.trim().slice(0, 2).toUpperCase()}
                                                        </option>
                                                    ))}
                                            </select>
                                    </div>

                                    <div className='d-flex align-items-center'>
                                        <span className='d-none d-md-block  pt-2'>{t('Preview')}:</span>
                                        <svg onClick={openPreview}
                                        className='mt-2 ms-2 prev-pdf' width="50" height="50" viewBox="0 0 55 55" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="27.5" cy="27.5" r="27.5" fill="#F2D291" fillOpacity="0.15"/>
                                            <path d="M27.5006 45.998C27.3851 45.998 27.2696 45.9755 27.1627 45.9298L10.6627 38.8918C10.3893 38.7749 10.2148 38.5279 10.2148 38.2562V16.4385C10.2148 16.1971 10.3531 15.9726 10.581 15.8431C10.8081 15.7143 11.0948 15.6995 11.3384 15.803L27.5006 22.6967L43.6627 15.8023C43.9063 15.6995 44.1915 15.7136 44.4193 15.8431C44.648 15.9726 44.7863 16.1971 44.7863 16.4385V38.2562C44.7863 38.5279 44.6118 38.7749 44.3384 38.8918L27.8384 45.9298C27.7316 45.9755 27.6161 45.998 27.5006 45.998ZM11.7863 37.8114L27.5006 44.5144L43.2148 37.8114V17.5533L27.8384 24.112C27.6255 24.2028 27.3764 24.2028 27.1635 24.112L11.7863 17.5533V37.8114Z" fill="#8E6E2D"/>
                                            <path d="M27.5009 20.7712L13.8035 14.929C13.4114 14.7615 13.2456 14.3413 13.4319 13.9908C13.6189 13.6396 14.0871 13.4925 14.4792 13.6572L27.5009 19.2123L40.5225 13.6572C40.9154 13.4925 41.3829 13.6396 41.5699 13.9908C41.7561 14.3413 41.5903 14.7615 41.1982 14.929L27.5009 20.7712Z" fill="#8E6E2D"/>
                                            <path d="M28.2863 23.4766H26.7148V45.2943H28.2863V23.4766Z" fill="#8E6E2D"/>
                                        </svg>
                                    </div>
                                </div>
                                
                            </div>
                                <div>
                                    <div className='book-preview-div d-none'></div>
                                    <i onClick={closePreview} className="fa-solid fa-circle-xmark d-none"></i>
                                    <iframe title='book-preview' className='book-preview d-none' src={book.preview}></iframe>
                                </div>
                                
                            <div>
                                
                                <div className='d-flex justify-content-end my-2 my-md-0'>

                                    {(book.countInStock > 0 || book.upcomingBook) && (

                                        <div className='position-relative d-flex flex-column ms-0 ms-md-4'>
                                            <button onClick={buyNow} className={book.upcomingBook ? 'brown-btn' : 'green-btn'} >
                                                {book.upcomingBook ? t('Pre Order') : t('Buy Now')}
                                            </button>
                                            {book.upcomingBook && book.name !== 'Träume Mich' ? (
                                                <span className='available-text'>{t('Available after')} 5th Jun, 2023</span>
                                                ) : book.upcomingBook && (
                                                <span className='available-text'>{t('Coming Soon')} in 2024</span>
                                            )}
                                            <button onClick={addInCart} className={`${book.upcomingBook && 'd-none' } ms-auto mt-2 brown-btn`}>{t('Add to cart')}</button>
                                        </div>
                                    )}

                                </div>
                            </div>
                        </div>
                    </motion.div>
                </>}
            </div>
        </>
    )
}

export default BookCardInfo
