import { Link } from 'react-router-dom'

const NotFound = () => {
    return (
        <div className='d-flex align-items-center justify-content-center flex-column mt-5 text-danger'>
            <h1>404: Error - Page Was Not Found!</h1>
            <Link to={'/'}>Go Back Home</Link>
        </div>
    )
}

export default NotFound
