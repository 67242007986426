export const deutch =  {
    'about':"Über uns",
    'books':"Bücher",
    'blogs':"Blogs",
    'people':"Menschen",
    'events':"Veranstaltungen",
    'search': "Suchen",
    'log in': "Anmeldung",
    "profile": 'Profil',
    "logout": 'Ausloggen ',
    "user profile": 'Benutzerprofil',
    'change password': 'Kennwort ändern',
    'update': 'Aktualisieren',
    'my orders': 'Meine Bestellungen',
    'id': 'Ausweis',
    'date': 'Datum',
    'total': 'Gesamt',
    'paid': 'Bezahlt',
    'delivered': 'Geliefert',
    'details': 'Einzelheiten',
    'view-order': 'Bestellung ansehen',
    // Anmeldeseite
    'sign in': "Anmelden",
    'email address': "E-Mail Addresse",
    'enter email': "Email eingeben",
    'password': "Passwort",
    'enter password': "Passwort eingeben" ,
    'new customer': "Neukunde",
    'sign up': "Anmeldung",
    'forgot password': "Passwort vergessen",
    // Fusszeile
    'contact us': "Kontaktiere uns",
    'for business please email': "Für geschäftliche Zwecke senden Sie bitte eine E-Mail",
    'office address': "Büro adresse",
    'follow us on social media channels': "Folgen Sie uns auf Social-Media-Kanälen",
    'our payment channels': "Unsere Zahlungskanäle",
    // Passwort zurücksetzen
    "reset password": 'Passwort zurücksetzen',
    "forgotten-pw": 'Passwort vergessen? Geben Sie unten Ihre E-Mail-Adresse ein und wir senden Ihnen per E-Mail Anweisungen zum Einrichten einer neuen Adresse.' ,
    'submit': "Einreichen",
    // Registrieren
    'name': "Name",
    'enter name': "Name eingeben",
    'must contain at least 8 characters': "Muss mindestens 8 Zeichen enthalten",
    'confirm password':"Bestätige das Passwort",
    'register': "Registrieren",
    'have an account': "Ein Konto haben",
    'passwords do not match': 'Passwörter stimmen nicht überein',
    'password to short': 'Das Passwort ist zu kurz. Es muss mindestens 8 Zeichen enthalten',
    // heros
    "books that warm your heart": "Bücher, die einem das Herz erwärmen!",
    "upcoming book": "Kommendes Buch!",
    'we are excited': "Wir freuen uns, ein neues Buch in unsere Sammlung aufzunehmen. Machen Sie sich bereit für ein brandneues Buch von Ihrem Lieblingsautor. Lassen Sie sich dieses aufregende neue Buch nicht entgehen!",
    "taking preorders": "Wir nehmen jetzt Vorbestellungen entgegen, also sichern Sie sich jetzt Ihr Exemplar, bevor es veröffentlicht wird!",
    'preorder now': "Jetzt Vorbestellen",
    'new sing up' :"Neu? Jetzt anmelden",
    'subscribe now': "Abonniere jetzt",
    "life is to short": "Das Leben ist zu kurz für langweilige E-Mails",
    'sbscribe to newsletter': "Abonnieren Sie unseren Newsletter, um Updates über die neueste Markteinführung und exklusive Angebote zu erhalten.",
    'no spam': "Kein Spam, versprochen :)",
    "protecting privacy": "Wir verpflichten uns, Ihre Privatsphäre zu schützen und die Datenschutz-Grundverordnung (DSGVO) einzuhalten, wenn Sie Ihre E-Mail-ID für unseren Newsletter erfassen. Indem Sie sich für unseren Newsletter anmelden, erteilen Sie uns die Erlaubnis, Ihre E-Mail-ID ausschließlich für den Versand zu verwenden Sie erhalten unseren Newsletter. Wir speichern Ihre E-Mail-ID sicher und geben sie ohne Ihre ausdrückliche Zustimmung nicht an Dritte weiter. Sie können sich jederzeit von unserem Newsletter abmelden, indem Sie in jeder E-Mail, die Sie von uns erhalten, auf den Abmeldelink klicken.",
    'questions use of data': "Wenn Sie Fragen oder Bedenken dazu haben, wie wir Ihre Daten verwenden, zögern Sie bitte nicht, uns unter info@hanlbook.com zu kontaktieren.",
    "subscribe": "Abonnieren",
    // Home page
    'best seller': 'Bestseller',
    'book club': 'Buchclub',
    "Counting Stars Out Of Heaven in Us": "Wir zählen die Sterne vom Himmel in uns",
    'In the heart of Stuttgart in 2023': 'Im Herzen von Stuttgart im Jahr 2023',
    'Hanl Publication is born': 'Hanl Publication ist geboren',
    'We provide a specialised platform': 'Wir bieten eine spezialisierte Plattform',
    'that': 'Das',
    'informs, inspires and transforms you': 'informiert, inspiriert und verwandelt Sie',
    'Take a look around and see what catches your heart': 'Schauen Sie sich um und finden Sie heraus, was Ihnen am Herzen liegt',
    'Wishing you a delightful experience': 'Ich wünsche Ihnen ein wunderbares Erlebnis',
    'Social initiatives our literature focuses towards': 'Soziale Initiativen, auf die sich unsere Literatur konzentriert',
    'Explore Places': 'Orte erkunden',
    'Korean Culture': 'Koreanische Kultur',
    'Stuttgart: A place called Home': 'Stuttgart: Ein Ort namens Heimat',
    // Korean
    'Home': 'Zu Hause',
    'Cherry Blossom Festival': 'Kirschblütenfest',
    'in korea': 'In Korea gibt es eine kulturelle Tradition, die als „Blumenschauen“ bekannt ist, ein Begriff, den es in Deutschland nicht gibt. Diese Tradition hat einen besonderen Platz im Herzen der Koreaner, die oft Wochenendausflüge mit Freunden, der Familie und geliebten Menschen planen, die mit der Blütezeit zusammenfallen. Das Blühen der Blumen beginnt in den wärmeren südlichen Regionen und bewegt sich allmählich nach Norden, beginnend im zeitigen Frühjahr mit den Blüten der Kornelkirsche, gefolgt von den Azaleenblüten, und gipfelt mit den Kirschblüten im April.',
    'if you': 'Wenn Sie Korea vom Mond aus beobachten würden, würden Sie im Februar eine gelbe Landschaft, im März eine rosa Landschaft und im April eine weiß-rosa Landschaft sehen. Jede Stadt in Korea ist so gestaltet, dass sie zu der jeweiligen Jahreszeit passt, in der die Blumen blühen, und so im ganzen Land einen wunderschönen, großen Blumengarten entstehen lässt. Daher genießen romantische Veranstaltungen zur Kirschblütenbesichtigung jedermann, unabhängig davon, wo er lebt. Dieser schöne und unvergessliche Moment wird von Menschen jeden Alters in Korea geschätzt.',
    'unfortunately cherry blossomms': 'Leider haben Kirschblüten eine kurze Lebensdauer, was ihr Verschwinden bittersüß macht.“ Doch selbst der Moment ihres schnellen Verblassens wird von Koreanern als romantisches Erlebnis angesehen. Während der Sommermonate genießen sie Trost in der Schönheit von Rosen und Sonnenblumen und warten sehnsüchtig auf den Herbst. Die Tradition der Herbstbeobachtungen in Korea bleibt ein streng gehütetes Geheimnis. Es gibt jedoch einige atemberaubende Seherlebnisse, die mit der Schönheit der Kirschblüten mithalten können, und wir werden im Herbst mehr darüber erzählen.',
    'must visit': 'Muss einen Buchladen in Südkorea besuchen',
    'small bookshop': 'Tchekbom, eine kleine Buchhandlung, die sich um die Umwelt kümmert und Tiere liebt. Es präsentiert hauptsächlich unabhängige Publikationen. Es verkauft nicht nur Bücher, sondern vermittelt durch verschiedene Aktivitäten auch die Freude am Lesen. Choi Hyun-joo, die Besitzerin der Buchhandlung, hat auch ein Buch über ihre Erfahrungen mit der Buchhandlung veröffentlicht. In diesem Buch erzählte sie von der Adoption dreier verlassener Katzen. Wie sie dadurch ihr Leben zu einem einfachen und herzlichen Umgang mit ihren Nachbarn, Tieren und allen Lebewesen entwickelte. Dieses Buch wurde nach und nach von Mensch zu Mensch bekannt. Es gibt jene Leser, die extra nach Gumi in einem kleinen Dorf in Südkorea reisen, um Bücher in dieser Buchhandlung „Tchekbom“ zu kaufen. Große Überraschung!! Dieses Buch in deutscher Sprache erscheint im Hanl-Verlag. Die deutsche Ausgabe heißt „Das Glück auf leisen Pfoten.',
    // Stuttgart
    'Charm, Culture and History of Stuttgart': 'Charme, Kultur und Geschichte Stuttgarts',
    'stuttgart-p1': 'Stuttgart ist eine Stadt, die das Wesen Deutschlands wirklich einfängt. Die Stadt liegt im Südwesten des Landes und ist ein Zentrum für Wirtschaft, Kultur und Unterhaltung. „Die Stadt ist berühmt für ihre Grünflächen, innovative Architektur und weltberühmten Museen sowie für ihre faszinierende Geschichte.',
    'stuttgart-p2': 'Eines der Wahrzeichen Stuttgarts ist das Mercedes-Benz Museum, das die Entwicklung der Automobilindustrie von ihren Anfängen bis in die Neuzeit zeigt.“ Es ist ein Muss für jeden Autoliebhaber oder Technikliebhaber. Eine weitere faszinierende Kulturinstitution in Stuttgart ist die Landesbibliothek Württemberg, eine der ältesten und bedeutendsten Bibliotheken Deutschlands. Seine Sammlung umfasst über drei Millionen Bücher, Manuskripte, Karten und mehr.',
    'stuttgart-p3': 'Auch die kulinarische Szene Stuttgarts ist mit ihrer vielfältigen Mischung aus traditioneller deutscher Küche und internationalen Aromen eine Erkundung wert.“ Von den weltberühmten Maultaschen bis hin zu lokalen Spezialitäten wie Käsespätzle ist für jeden Gaumen etwas dabei.',
    'stuttgart-p4': 'Neben seinen kulturellen Attraktionen ist Stuttgart ein beliebtes Reiseziel für Outdoor-Enthusiasten. Der Schlossgarten, ein weitläufiger Park im Herzen der Stadt, bietet malerische Spazier- und Joggingstrecken, wunderschöne botanische Gärten und charmante Cafés für einen entspannten Nachmittag. Auch die Stuttgarter Buchhandlungen sind einen Besuch wert, denn in der Stadt gibt es viele unabhängige Geschäfte, die eine einzigartige und vielfältige Auswahl an Büchern anbieten. Eine der beliebtesten ist die Tübinger Bücherstube, die es schon seit über 100 Jahren gibt und die für ihren freundlichen Service und ihre beeindruckende Sammlung deutscher Literatur bekannt ist.',
    'stuttgart-p5': 'Stuttgart hat eine reiche Geschichte, die bis ins Römische Reich zurückreicht. Die Stadt war jahrhundertelang ein wichtiges Zentrum für Handel und Gewerbe. Während des Zweiten Weltkriegs erlitt die Stadt erhebliche Schäden, doch seitdem wurde die Stadt wieder aufgebaut und in ein modernes und lebendiges Reiseziel verwandelt, das sein Erbe bewahrt und sich gleichzeitig für Innovationen einsetzt.',
    'stuttgart-p6': 'Insgesamt ist Stuttgart eine Stadt, die für jeden etwas zu bieten hat. Egal, ob Sie sich für Geschichte oder Kunst interessieren oder einfach nur die Natur genießen möchten, in diesem charmanten und kulturell reichen Reiseziel werden Sie mit Sicherheit etwas finden, das Ihnen gefällt.',
    // About
    'Book to the Future': 'Buch in die Zukunft',
    'michelle': 'Ich freue mich wirklich, eine neue Literaturplattform zu eröffnen! So viele Leute sind überrascht und fragen uns ständig: Wird das Buch überhaupt noch existieren?“',
    'alex': 'Die Welt hat sich in kurzer Zeit dramatisch verändert und damit auch unser Lebensstil. Die Menschheit ist viel schlauer, intelligenter, kenntnisreicher geworden und verfügt über alle möglichen Wundermittel zum Informieren, Erinnern und Rechnen. Für diesen Alltag mit so hoher Geschwindigkeit und vielfältigen Informationsquellen könnte das Buch durchaus entbehrlich sein.',
    'swati': 'Wird Ihr Leben dadurch glücklicher und erfüllter?',
    'burkhard': "Ein glückliches und erfülltes Leben hängt nicht von Informationen und Wissen ab. Die wesentlichen Wohlfühlfaktoren bestehen aus den verschiedenen Beziehungen. z. B. wie man sich zum Beruf verhält. Wie man sich mit seiner Familie, Freunden und Kollegen verhält, und.“ wie du dich fühlst.",
    'michelle-1': ' Gefühl? Meinst du verschiedene Emotionen?',
    'tina': "Eigentlich die positiven Gefühle. Ich meine, jedes positive Gefühl macht das Leben glücklich, weil es uns unter allen Umständen belastbar macht. Diese positiven Gefühle können nur durch Erfahrung und Konfrontation mit der Realität des Lebens gelehrt werden. Nun, das können Sie.“ „Es gibt nicht tausend Biografien auf einmal. Aber wenn man die Chance hat, zu erleben, wie vielfältig das Leben sein kann, ist man schon auf dem richtigen Weg.",
    'swati-1': 'Das Leben anderer zu erleben und sich in sie hineinzuversetzen gibt einem die Fähigkeit, positive Gefühle zu entwickeln, das finde ich interessant. Wenn es so wäre, könnte gute Literatur immer noch von wesentlicher Bedeutung für die Lebensqualität sein. Was denkst du darüber?',
    'rose': "Na klar. Ich bin einfach hungrig nach 'New Story!'. Niemand lebt mehr in den traditionellen Vorstellungen von Zeit und Raum. Alle alten Konzepte von Geschlecht, ethnischer Zugehörigkeit, Nation, sozialer Klasse, Region oder Religion lösen sich auf. Jeder von uns ist auf der Suche nach etwas Neuem, das seinen Platz einnehmen kann. Dieser neue Wert entsteht aus der Auseinandersetzung mit den Menschen, die hier und jetzt mit mir leben. Schauen Sie sich um und hören Sie einen Moment zu: Wie viele Sprachen werden gesprochen? gleichzeitig? Wie viele verschiedene Menschen vermischen sich? Was für eine wunderbare Gelegenheit, mit einer so vielfältigen Gruppe von Menschen Nachbarn oder Freunde zu sein? Wir wollen neue soziale Werte aufbauen, indem wir die Werte des anderen respektieren und teilen. Wir sind auf der Suche Ich brauche Literatur, die uns dabei hilft. Manchmal kommt es mir so vor, als wäre mein Kopf im 23. Jahrhundert angekommen, mein Herz aber im 19. Jahrhundert stehengeblieben. Das kann nicht wahr sein. Ich wäre wirklich glücklich, wenn ich wusste, wie ich mein Herz noch weiter entwickeln kann. Am liebsten noch liebevoller, wärmer, mutiger und großzügiger, vor allem freier!",
    'Heartwarming': 'Herzerwärmend',
    'Your': 'Dein',
    'Our Team': 'Unser Team',
    // Books
    'Book Collection': 'Buchsammlung',
    'Know More': 'Mehr Wissen',
    // Filter
    'Categories': 'Kategorien',
    "Languages": 'Sprachen',
    'In-stock': 'Auf Lager',
    'Authors': 'Autoren',
    'Artists': 'Künstler',
    // Book page
    'Description': 'Beschreibung',
    'Read More': 'Weiterlesen',
    'Read Less': 'Weniger lesen',
    'Paper Back': 'Taschenbuch',
    'Coming Soon': 'Demnächst',
    'Select language': 'Sprache auswählen',
    'Pre Order': 'Vorbestellung',
    'Available after': 'Verfügbar nach',
    'Buy Now': 'Jetzt kaufen',
    'Add to cart': 'In der Kart',
    'Social Contribution': 'Sozialbeitrag',
    'Product Description': 'Produktbeschreibung',
    'About author': 'Über den Autor',
    'Write a review': 'Bewertung schreiben',
    'Rating': 'Bewertung',
    'Review': 'Rezension',
    'Reviews': 'Bewertungen',
    'Submit': 'Absenden ',
    'Please': 'Bitte',
    'to write a review': 'eine Rezension schreiben',
    'poor': 'Arm',
    'fair': 'Gerecht',
    'good': 'Gut',
    'very good': 'Sehr gut',
    'excellent': 'Exzellent',
    // Blogs
    'Hanl Book\'s Blog' : 'Blog von Hanl Book',
    // People
    'Hanl Book\'s People' : 'Die Leute von Hanl Book',
    'Hanl\'s Team': 'Hanl\'s Team' ,
    // Team
    'Publishing director': 'Verlagsleiter',
    'born in': 'Geboren in Südkorea und lebe seit 2006 in Stuttgart, Deutschland.',
    'Lector': 'Lektor',
    'user exp': 'User Experience Designer und Social Media Manager',
    'Graphic Designer': 'Graphic Designer',
    'finken-desc': 'Finken & Bumiller Buchdesigner und Grafikdesigner Finken & Bumiller ist ein Designbüro mit langjähriger und umfassender Erfahrung in der Gestaltung von Büchern und Verlagsauftritten. Das Leistungsspektrum umfasst alles – vom einzelnen Buchcover, über Reihen, Vorpremieren bis hin zur Gestaltung eines Auftritts für den gesamten Verlag. Neben dem eigentlichen Arbeitsschwerpunkt für Buchverlage betreut Finken & Bumiller vor allem kulturelle Institutionen.',
    // Events
    'Book Tour': 'Tour Buchen',
    "Hanl Book's Events": "Veranstaltungen von Hanl Book",
    // Shopping Cart
    'Shopping Cart': 'Warenkorb',
    'Language': 'Sprache',
    'Subtotal': 'Zwischensumme',
    'items': 'Artikel',
    'Proceed to Checkout': 'Zur Kasse gehen',
    'Your Wishlist': 'Ihre Wunschliste',
    'Your wishlist is empty': 'Ihre Wunschliste ist leer',
    'Your cart is empty': 'Ihr Warenkorb ist leer',
    'Buy Books': 'Bücher kaufen',
    // Shipping
    'Shipping': 'Versand',
    'Address': 'Adresse',
    'Enter Address': 'Adresse eingeben',
    'City': 'Stadt',
    'Enter City': 'Stadt eingeben',
    'Postal Code': 'Postleitzahl',
    'Enter Postal Code': 'Postleitzahl eingeben',
    'Country': 'Land',
    'Enter Country': 'Land eingeben',
    'Continue': 'Weiter',
    // Place order
    'Order Items':'Artikel bestellen',
    'Order Summary': 'Bestellübersicht',
    'Total': 'Gesamt',
    'Place Order': 'Bestellung aufgeben',
     // Order Screen
     'Order': 'Bestellen',
     'Not Paid': 'Nicht bezahlt',
     'Not Delivered': 'Nicht geliefer',
     // Cookies
    'We care': 'Wir kümmern uns darum, dass Ihre persönlichen Daten privat bleiben',
    'cookie msg': 'verwendet nur unbedingt erforderliche Cookies, die als Reaktion auf Ihre Aktionen auf der Website gesetzt werden, z. B. beim Singen oder Ausfüllen von Formularen. Diese sind für die Funktionalität unserer Website erforderlich und können nicht deaktiviert werden.',
    'View Cookies': 'Cookies anzeigen',
    'Accept': 'Akzeptieren',
    'Privacy Policy': 'Datenschutz-Bestimmungen',
    'Cookie List': 'Cookie-Liste',
    'PayPal cookie': 'Diese Cookies werden verwendet, um Online-Zahlungen über unsere Website zu ermöglichen. Sie ermöglichen uns eine sichere Zahlungsabwicklung und den Überblick über Ihre Bestelldaten.',
    'User Info cookie': 'Dabei handelt es sich um ein lokales Speicherelement, das Ihre Benutzerinformationen wie Ihr Token, Ihre ID, Ihren Benutzernamen, Ihren Namen und Ihre E-Mail-Adresse speichert. Diese Informationen werden verwendet, um Ihr Erlebnis auf unserer Website zu personalisieren und bestimmte Funktionen zu ermöglichen, wie z. B. Online-Zahlungen, Bestellverlauf und Verfolgung Ihrer Bestellungen.',
    'Shipping Address cookie': 'Dabei handelt es sich um ein lokales Speicherelement, das Ihre Benutzerinformationen wie Ihr Token, Ihre ID, Ihren Benutzernamen, Ihren Namen und Ihre E-Mail-Adresse speichert. Diese Informationen werden verwendet, um Ihr Erlebnis auf unserer Website zu personalisieren und bestimmte Funktionen zu ermöglichen, wie z. B. Online-Zahlungen, Bestellverlauf und Verfolgung Ihrer Bestellungen.',
    'paypal storage cookie': 'Hierbei handelt es sich um ein lokales Speicherelement, das von PayPal verwendet wird, um Ihre Zahlungspräferenzen zu speichern und eine schnellere Kaufabwicklung zu ermöglichen.',
    'all essential': 'Dabei handelt es sich um alle wesentlichen Cookies, die für das Funktionieren der Website notwendig sind und nicht deaktiviert werden können.',
    'by using': 'Durch die Nutzung unserer Website stimmen Sie der Verwendung dieser Cookies zu',
    // Privacy Policy
    'Privacy Policy for website': 'Datenschutzrichtlinie für die Hanl Book-Website',
    'Collection of Information': 'Sammlung von Informationen',
    'we collect': 'Wir sammeln Informationen über unsere Benutzer auf folgende Weise:',
    'collect-1': 'Informationen, die Sie uns zur Verfügung stellen: Wenn Sie sich für ein Konto registrieren oder eine Bestellung aufgeben, erfassen wir möglicherweise personenbezogene Daten wie Ihren Namen, Ihre E-Mail-Adresse und Ihre Lieferadresse.',
    'Use of Information': 'Verwendung von Informationen',
    'we use information': 'Wir verwenden die von uns erfassten Informationen für folgende Zwecke:',
    'we use information-1':'Wir verwenden die Informationen zur Bestellabwicklung und zur Personalisierung Ihres Erlebnisses auf unserer Website.',
    'we use information-2': 'Für Marketingzwecke: Wir können die Informationen verwenden, um Ihnen Werbe-E-Mails zu senden.',
    'Disclosure of Information': 'Offenlegung von Informationen',
    'we disclose': 'Unter den folgenden Umständen können wir Informationen über unsere Benutzer offenlegen:',
    'we disclose for': 'Aus rechtlichen Gründen: Wir können Ihre Daten als Reaktion auf eine Vorladung oder eine andere rechtliche Anfrage offenlegen.',
    'Security of Information': 'Informationssicherheit',
    'security msg': 'Wir ergreifen geeignete Maßnahmen, um die Sicherheit Ihrer Daten zu schützen, einschließlich der Implementierung technischer und organisatorischer Maßnahmen, um unbefugten Zugriff, Offenlegung oder Zerstörung zu verhindern.',
    'Your Rights': 'Deine Rechte',
    'your rights msg': 'Sie haben das Recht, auf Ihre persönlichen Daten zuzugreifen, diese zu korrigieren und zu löschen. Sie haben außerdem das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen und eine Kopie Ihrer personenbezogenen Daten in einem strukturierten, maschinenlesbaren Format zu erhalten.',
    'contact us about pp': 'Wenn Sie Fragen oder Bedenken zu dieser Datenschutzrichtlinie haben, kontaktieren Sie uns bitte unter info@hanlbook.com.',
    // Reset pw
    'Enter a new Password': 'Geben Sie ein neues Kennwort ein',
    'Enter a new Password p': 'Bitte geben Sie Ihr neues Passwort zweimal ein, damit wir überprüfen können, ob Sie es richtig eingegeben haben',
    'Passwords do not match': 'Passwörter stimmen nicht überein!',

    
    'Out of Stock': 'Ausverkauft',
    'Added to wishlist': 'Zur Wunschliste hinzugefügt',
    'Added to cart': 'Zum Warenkorb hinzugefügt',
    'Preview': 'Vorschau',
    'location link': 'Standort-Link',
   'onwards at':'weiter um',
   'Blogs': 'Blogs',
   "E-Books": "E-Books",
   'Reject All': 'Alles ablehnen',
   'Orders': 'Aufträge',
   'Newsletter': 'Newsletter',
   'Loading': 'Wird geladen',
   'unsubscribe-here': 'Wenn Sie den Newsletter abbestellen möchten, klicken Sie hier',
   'Unsubscribe': 'Abbestellen',
   'Go Back': 'Geh zurück',
   'Add Ebook Pdf': 'E-Book-PDF hinzufügen',
   'Select the E-book': 'Wählen Sie das E-Book aus',
   'Select the language of the E-Book': 'Wählen Sie die Sprache des E-Books aus',
   'Add': 'Hinzufügen',
   'Edit': 'Bearbeiten',
   'Select job': 'Job auswählen',
   'Team': 'Team',
   'Image': 'Bild',
   'Enter Image': 'Bild eingeben',
   'Role': 'Rolle',
   'Link': 'Verknüpfung',
   'Enter a': 'Geben Sie ein',
   'Short': 'Kurz',
   'Author': 'Autor',
   'Artist': 'Künstler',
   'About': 'About',
   'Actions': 'Aktionen',
   'Enter descritpion': 'Beschreibung eingeben',
   'Edit Blog': 'Blog bearbeiten',
   'Blog Name': 'Blogname',
   'Author Name': 'Autorenname',
   'Text': 'Text',
   'Enter Section One Text': 'Geben Sie den Text für Abschnitt 1 ein',
   'Choose File': 'Datei wählen',
   'Are you sure you want to delete this product?': 'Sind Sie sicher, dass Sie dieses Produkt löschen möchten?',
   'Create Blog': 'Blog erstellen',
   'Create Book': 'Buch erstellen',
   'Created': 'Erstellt',
   'Edit Product': 'Produkt bearbeiten',
   'Is Upcoming Book?': 'Ist das kommende Buch?',
   'Yes': 'Ja',
   'No': 'Nein',
   'Enter Price': 'Geben Sie den Preis ein',
   'Hard Cover Price': 'Hardcover-Preis',
   'E-book Price': 'E-Book-Preis',
   'Price': 'Preis',
   'Select all available languages for the book': 'Wählen Sie alle verfügbaren Sprachen für das Buch aus',
   'Is Best Seller': 'Ist Bestseller',
   'Book Preview PDF': 'Buchvorschau PDF',
   'Add book Preview': 'Buchvorschau hinzufügen',
   'Category': 'Kategorie',
   'Select a category': 'Wählen Sie eine Kategorie',
   'Hold down "Control", or "Command" on a Mac, to select more than one.': 'Halten Sie „Strg“ oder „Befehl“ auf einem Mac gedrückt, um mehr als eine auszuwählen.',
  'Stock': 'Aktie',
  'Enter stock': 'Lagerbestand eingeben',
  'Enter Social Contribution': 'Geben Sie den Sozialbeitrag ein',
  'Enter message': 'Nachricht eingeben',
  'Product details': 'Produktdetails',
  'type all product details separated with comma': 'Geben Sie alle Produktdetails durch Komma getrennt ein',
  'No reviews yet': 'Noch keine Bewertungen',
 'Select': 'Wählen',
 'Currently, there are no books with the given filter': 'Derzeit sind keine Bücher mit dem angegebenen Filter vorhanden',
 'Send a Newsletter': 'Senden Sie einen Newsletter',
 'Number': 'Nummer',
 'Paypal-payment': "Sie müssen kein Konto erstellen. Wählen Sie einfach beim Bezahlvorgang PayPal aus und folgen Sie den Anweisungen.",
 'Credit-card-payment': "Bezahlen Sie mit Ihrer bevorzugten Debit- oder Kreditkarte. Geben Sie beim Bezahlvorgang sicher Ihre Kartendaten ein und Ihr Buch wird versendet, sobald die Zahlung verarbeitet wurde.",
 'Payment Guidelines': 'Zahlungsrichtlinien',
 'sepa-payment': 'Wenn die SEPA-Zahlung fehlschlägt, verwenden Sie entweder eine Kreditkarte, eine Debitkarte oder PayPal. Für die Kartenzahlung geben Sie beim Bezahlvorgang Ihre Kartendaten ein. Mit PayPal können Sie eine Zahlung durchführen, ohne ein Konto zu erstellen. Wählen Sie einfach PayPal als Zahlungsoption aus.',
 'sepa-failure': 'SEPA-Zahlungsfehler',
 'prepayment': 'Überweisen Sie den Gesamtbetrag per E-Mail mit der Bestellreferenznummer an die angegebene IBAN. Sobald die Zahlung eingegangen ist, wird Ihr Buch versendet.',
  'order-confirm': 'Vielen Dank für Ihre Bestellung! Eine Bestätigungs-E-Mail mit dem E-Book im Anhang wurde an Ihre E-Mail-Adresse gesendet. Stellen Sie sicher, dass Sie auch den Spam überprüfen. Bestes Hanl book!',
  'order-confirm-note': 'Bei Vorbestellungen wird das E-Book verschickt, sobald es fertig ist!',
  'order-confirm-2': 'Vielen Dank für Ihre Bestellung! Eine Bestätigungs-E-Mail wurde an Ihre E-Mail-Adresse gesendet. Stellen Sie sicher, dass Sie auch den Spam überprüfen. Bestes Hanl Book!',
  'no-artists': 'Derzeit gibt es keine Autoren oder Künstler',
  "new sign up": "Neu? Jetzt registrieren",
}