import { useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Button,
  Card,
  Container,
  NavDropdown,
} from "react-bootstrap";
import { addToCart, removeFromCart } from "../actions/cartActions";
import { useTranslation } from "react-i18next";
import BooksCarousel from "../components/BooksCarousel";
import Loader from "../components/Loader";
import Message from "../components/Message";

const CartScreen = () => {
  const navigate = useNavigate();

  const { id } = useParams();
  const { t } = useTranslation();
  const qty = new URLSearchParams(useLocation().search).get("qty");
  let type = new URLSearchParams(useLocation().search).get("type");
  const language = new URLSearchParams(useLocation().search).get("lang");

  if (!type) {
    type = "hardCover";
  }

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { loading, cartItems } = cart;

  const wishlist = useSelector((state) => state.wishlist);
  const { wishlistItems } = wishlist;

  const user = useSelector((state) => state.userLogin);
  const { userInfo } = user;

  useEffect(() => {
    if (id) {
      dispatch(addToCart(id, Number(qty), type, language));

      window.scrollTo(0, 0);
    }

    document.getElementById("cart-notification").classList.add("d-none");
  }, [id, qty, language, type, dispatch]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
    navigate("/cart");
  };

  const checkoutHandler = () => {
    if (userInfo) {
      const hardCover = cartItems.find((item) => item.type === "hardCover");

      if (hardCover) {
        navigate("/shipping");
      } else {
        navigate("/placeorder");
      }
    } else {
      navigate("/login?redirect=/shipping");
    }
  };

  return (
    <Container className="shopping-cart">
      <Row className="mt-5">
        <Col lg={8}>
          <h1 style={{ color: "#6B778C" }}>{t("Shopping Cart")}</h1>
          {cartItems.length === 0 && (
            <Message variant="info">
              {t("Your cart is empty")}{" "}
              <Link to={"/books"}>{t("Buy Books")}</Link>
            </Message>
          )}
          {loading ? (
            <Loader />
          ) : (
            <ListGroup variant="flush">
              {cartItems.map((item) => (
                <ListGroup.Item key={item.id}>
                  <Row className="justify-content-between">
                    <Col md={2} lg={3}>
                      <Image src={item.image} alt={item.name} fluid rounded />
                    </Col>
                    <Col md={item.type === "hardCover" ? 3 : 4}>
                      <Link
                        className="green-link fs-5"
                        to={`/books/${item.id}`}
                      >
                        {item.name} {item.type === "eBook" && "(E-Book)"}
                        {item.type === "hardCover" && "(Paper Back)"}
                      </Link>

                      <p className="mt-3 text-warning">
                        {t("Language")}: {item.language}
                      </p>
                    </Col>

                    <Col
                      md={1}
                      className="fw-bold fs-5 pt-1 d-flex flex-row flex-md-column"
                    >
                      <div>&euro;{item.price}</div>

                      {item.type === "hardCover" && (
                        <div className="pt-2 mb-1 ms-4 md-mb-0">
                          <NavDropdown title={item.qty} id="cart-select-qty">
                            {[...Array(item.countInStock).keys()].map((x) => (
                              <NavDropdown.Item
                                onClick={(e) =>
                                  dispatch(
                                    addToCart(
                                      item.id,
                                      Number(e.target.text),
                                      "hardCover",
                                      item.language
                                    )
                                  )
                                }
                                className="select-qty-dropdown"
                                key={x + 1}
                              >
                                {x + 1}
                              </NavDropdown.Item>
                            ))}
                          </NavDropdown>
                        </div>
                      )}
                    </Col>

                    <Col md={1}>
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFromCartHandler(item.id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>

        <Col lg={4} className="mt-3">
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2 style={{ color: "#6B778C" }}>
                  {t("Subtotal")} (
                  {cartItems.reduce((acc, cur) => acc + cur.qty, 0)}){" "}
                  {t("items")}
                </h2>
                {cartItems
                  .reduce((acc, cur) => acc + cur.price * cur.qty, 0)
                  .toFixed(2)}
                &euro;
              </ListGroup.Item>
            </ListGroup>

            <ListGroup.Item>
              <Button
                type="button"
                className="btn-block w-100"
                disabled={cartItems.length === 0 && true}
                onClick={checkoutHandler}
              >
                {t("Proceed to Checkout")}
              </Button>
            </ListGroup.Item>
          </Card>
        </Col>
      </Row>

      <Row className="mt-5">
        <h2 className="text-center">{t("Your Wishlist")}</h2>

        {wishlistItems.length > 0 ? (
          <BooksCarousel books={wishlistItems} />
        ) : (
          <Message variant="warning">
            {t("Your wishlist is empty")}{" "}
            <Link to={"/books"}>{t("Buy Books")}</Link>
          </Message>
        )}
      </Row>
    </Container>
  );
};

export default CartScreen;
