import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useDispatch } from 'react-redux'
import { removeFromWishlist } from '../actions/cartActions'
import { useTranslation } from 'react-i18next'
import Rating from '../components/Rating'
import 'react-toastify/dist/ReactToastify.css';

const BookCard = ({book}) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { i18n, t } = useTranslation()

    const addToCart = () => {
        navigate(`/cart/${book.id}?qty=${1}&lang=english`)
    }

    const handleRemove = () => {
        dispatch(removeFromWishlist(book.id))
    }

    return (

    <motion.div initial={{opacity: 0, scale:0.8}} animate={{opacity: 1, scale:1}}
     transition={{duration: 0.5}}
     className='my-3 rounded book-card position-relative'>
        
        <img onClick={() => navigate(`/books/${book.id}`)} src={book.image} alt='Book' className='rounded-top'/>
        {book.inWishlist && (<span onClick={handleRemove} className='remove-btn'>&#10005;</span>)}
       
        <div onClick={() => navigate(`/books/${book.id}`)} className='book-info'>
            <div className='d-flex justify-content-between'>
                <div className='author-title w-100 d-flex justify-content-between'>
                    <p className='book-title'>
                    {(i18n.language === 'de' && book.nameDe) ? book.nameDe.substring(0, 45) 
                     : book.name.substring(0, 45)} 
                    </p>
                        
                    {book.rating ? (
                        <div className='d-flex'>
                            <Rating value={book.rating} color='#f8e825'/> 
                        </div>
                    ) : (
                        <div>
                            <i className='fas fa-star' style={{color: '#D9D9D9'}}></i>
                            <i className='fas fa-star' style={{color: '#D9D9D9'}}></i>
                            <i className='fas fa-star' style={{color: '#D9D9D9'}}></i>
                            <i className='fas fa-star' style={{color: '#D9D9D9'}}></i>
                            <i className='fas fa-star' style={{color: '#D9D9D9'}}></i>
                        </div>
                    )}
      
                </div>
            </div>
            
            <small className='mb-2'>{t('Description')}</small>
            <p className='description'>{(i18n.language === 'de' && book.descriptionDe) ? book.descriptionDe.substring(0, 105)
                : book.description.substring(0, 105)}... <span className='fw-bold' style={{'textDecoration':'underline'}}>{t('Know More')}</span>
            </p>

            <div className='d-flex justify-content-between mt-2'>
                <div className="book-price">{book.hardCoverPrice ? book.hardCoverPrice : book.eBookPrice ? book.eBookPrice : book.price} &euro;</div>
                <div className='d-flex'>
                    {book.upcomingBook && !book.inWishlist ? (
                        <button onClick={addToCart} className='buy-btn'>{t('Pre Order')}</button>
                    ) : book.countInStock > 0 && !book.inWishlist  ? (
                        <button onClick={addToCart} className='buy-btn'>{t('Buy Now')}</button>
                    ) : book.inWishlist ? (
                        <button onClick={addToCart} className='buy-btn'>{t('Add to cart')}</button>
                    ) : (    
                            <small>{t('Out of Stock')}</small>
                    ) }
                    
                </div>
            </div>
        </div>
            
    </motion.div>
    
    )
}

export default BookCard
