import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Modal from "react-bootstrap/Modal";

const Footer = () => {
  const { t } = useTranslation();
  const [adminPage, setAdminPage] = useState(false);
  const location = useLocation();
  const [showCookies, setShowCookies] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("admin")) {
      setAdminPage(true);
    } else {
      setAdminPage(false);
    }
  }, [adminPage, location]);

  return (
    <>
      {!adminPage && (
        <div className="footer">
          <Container className="d-flex flex-column flex-md-row align-items-center align-items-md-start">
            <div className="text-center text-md-start">
              <div className="contact">
                <h3>{t("contact us")}</h3>
                <p className="fw-bold">
                  {t("for business please email")}:{" "}
                  <a href="mailto:info@hanlbook.com">info@hanlbook.com</a>
                </p>
                <p className="fw-bold">Telefon: +49 157 3697 1112</p>
              </div>
              <div className="address">
                <h5>{t("office address")} </h5>
                <p className="fw-bold m-0">Hanl Verlag GmbH</p>
                <p className="fw-bold m-0">Geschäftsführung: Michelle Han</p>
                <p className="fw-bold m-0">
                  Königstraße 26, D-70173 Stuttgart{" "}
                </p>
                <p className="fw-bold m-0">
                  Amtsgericht Stuttgart, HRB 781353 Steuer-Nr. 99054/12219
                </p>
              </div>

              <div className="social">
                <h3>{t("follow us on social media channels")} </h3>

                <div className="media-icons d-flex justify-content-center justify-content-md-start">
                  <Link
                    to="https://www.instagram.com/hanlbook/?igshid=YmMyMTA2M2Y%3D"
                    target="_blank"
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </Link>
                  <Link
                    to="https://www.facebook.com/profile.php?id=100089690203891"
                    target="_blank"
                  >
                    <i className="fa-brands fa-facebook"></i>
                  </Link>
                </div>

                <div className="mt-4">
                  <h3>Copyright</h3>
                  <p>
                    Die Vervielfältigung von Informationen oder Daten,
                    insbesondere die Verwendung von Texten, Textteilen oder
                    Bildmaterial bedarf der vorherigen Zustimmung des Hanl
                    Verlags.
                  </p>

                  <h3>Externe Links</h3>
                  <p>
                    Hanl Verlag GmbH ist für die Inhalte unternehmensfremder
                    Internetpräsenzen, die über Links erreicht werden, nicht
                    verantwortlich und distanziert sich von diesen.
                  </p>

                  <h3>OS-Platform</h3>
                  <p>
                    Die EU-Kommission hat eine Internetplattform zur
                    Online-Beilegung von Streitigkeiten (sog. »OS-Plattform«)
                    bereit gestellt. Die OS-Plattform soll als Anlaufstelle zur
                    außergerichtlichen Beilegung von Streitigkeiten betreffend
                    vertragliche Verpflichtungen, die aus Online-Kaufverträgen
                    erwachsen, dienen. Die OS-Plattform ist unter folgendem Link
                    erreichbar: https://ec.europa.eu/consumers/odr. Wir sind
                    aktuell nicht bereit und nicht verpflichtet an einem
                    Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen. Unsere
                    E-Mail-Adresse: info@hanlbook.com
                  </p>
                </div>

                <div className="d-flex">
                  <p
                    onClick={() => setShowPolicy(true)}
                    className="green-link fw-bold"
                  >
                    {t("Privacy Policy")}
                  </p>
                  <p className="mx-2">|</p>
                  <p
                    onClick={() => setShowCookies(true)}
                    className="green-link brown fw-semibold"
                  >
                    {t("Cookies")}
                  </p>
                </div>
              </div>
            </div>
            <div className="social mt-3 text-center">
              <p className="fw-bold">{t("our payment channels")} </p>

              <div className="media-icons d-flex justify-content-center">
                <p className="fs-3 mt-0 mx-2 pt-0">
                  <i className="fa-brands fa-cc-visa"></i>
                </p>
                <p className="fs-3 mt-0 mx-2 pt-0">
                  <i className="fa-brands fa-cc-mastercard"></i>
                </p>
                <p className="fs-3 mt-0 mx-2 pt-0">
                  <i className="fa-brands fa-cc-amex"></i>
                </p>
                <p className="fs-3 mt-0 mx-2 pt-0">
                  <i className="fa-brands fa-cc-diners-club"></i>
                </p>
                <p className="fs-3 mt-0 mx-2 pt-0">
                  <i className="fa-brands fa-cc-discover"></i>
                </p>
                <p className="fs-3 mt-0 mx-2 pt-0">
                  <i className="fa-brands fa-cc-apple-pay"></i>
                </p>
                <p className="fs-3 mt-0 mx-2 pt-0">
                  <i className="fa-brands fa-cc-paypal"></i>
                </p>
              </div>
            </div>

            <Modal
              size="lg"
              show={showCookies}
              onHide={() => setShowCookies(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  {t("Cookies List")}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul>
                  <li>
                    Verwendung von Cookies Die Internetseiten des Hanl Verlags
                    GmbH verwenden Cookies. Bei Cookies handelt es sich um
                    Daten, die vom Internetbrowser auf dem Computersystem des
                    Nutzers gespeichert werden. Die Cookies können beim Aufruf
                    einer Seite an diese übermittelt werden und ermöglichen
                    somit eine Zuordnung des Nutzers. Cookies helfen dabei, die
                    Nutzung von Internetseiten für die Nutzer zu vereinfachen.
                    Es ist jederzeit möglich der Setzung von Cookies durch
                    entsprechende Änderung der Einstellung im Internetbrowser zu
                    wiedersprechen. Gesetze Cookies können gelöscht werden. Es
                    wird darauf hingewiesen, dass bei Deaktivierung von Cookies
                    möglicherweise nicht alle Funktionen unserer Internetseiten
                    vollumfänglich genutzt werden können. Weitergehende
                    Informationen zu den von uns eingesetzten Cookies finden Sie
                    hier
                  </li>
                  <li>PayPal: {t("PayPal cookie")}</li>
                  <li>User Info: {t("User Info cookie")}</li>
                  <li>Shipping Address: {t("Shipping Address cookie")}</li>
                  <li>paypal_storage: {t("paypal storage cookie")}</li>
                </ul>
                <p>{t("all essential")}</p>
                <p>{t("by using")}</p>
              </Modal.Body>
            </Modal>

            <Modal
              size="lg"
              show={showPolicy}
              onHide={() => setShowPolicy(false)}
              aria-labelledby="example-modal-sizes-title-lg"
            >
              <Modal.Header closeButton>
                <Modal.Title id="example-modal-sizes-title-lg">
                  {"Privacy Policy for website"}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <h4>{t("Collection of Information")}</h4>

                  <p>{t("we collect")}</p>

                  <ul>
                    <li>{t("collect-1")}</li>
                  </ul>
                </div>

                <div>
                  <h4>{t("Use of Information")}</h4>

                  <p>{t("we use information")}</p>

                  <ul>
                    <li>{t("we use information-1")}</li>
                    <li>{t("we use information-2")}</li>
                  </ul>
                </div>

                <div>
                  <h4>{t("Disclosure of Information")}</h4>

                  <p>{t("we disclose")}</p>

                  <ul>
                    <li>{t("we disclose for")}</li>
                  </ul>
                </div>

                <div>
                  <h4>{t("Security of Information")}</h4>

                  <p>{t("security msg")}</p>
                </div>

                <div>
                  <h4>{"Your rights"}</h4>

                  <p>{t("your rights msg")}</p>
                </div>

                <div>
                  <h4>{t("contact us")}</h4>

                  <p>{t("contact us about pp")}</p>
                </div>

                <div>
                  <h4>"Datenschutz" </h4>
                  <p className="m-0">Name und Anschrift Verantwortliche</p>
                  <p className="m-0">Hanl Verlag GmbH </p>
                  <p className="m-0">
                    Geschäftsführung: Michelle Han Königstraße 26, D-70173
                  </p>

                  <p className="m-0">
                    Stuttgart Amtsgericht Stuttgart, HRB 781353 Steuer-Nr.
                    99054/12219
                  </p>
                  <p className="m-0">info@hanlbook.com</p>
                  <p> Telefon: +49 157 3697 1112</p>

                  <h5 className="m-0 fw-bold">
                    Verwendung von Nutzerbezogenen Daten
                  </h5>
                  <p className="m-0 fw-bold">
                    Wir sammeln Informationen über unsere Benutzer auf folgende
                    Weise:
                  </p>
                  <p>
                    Informationen, die Sie uns zur Verfügung stellen: Wenn Sie
                    sich für ein Konto registrieren oder eine Bestellung
                    aufgeben, erfassen wir personenbezogene Daten wie Ihren
                    Namen, Ihre E-Mail-Adresse und Ihre Lieferadresse.
                  </p>

                  <p className="m-0 fw-bold">
                    Wir verwenden die von uns erfassten Informationen für
                    folgende Zwecke:
                  </p>
                  <p className="m-0">
                    Wir verwenden die Informationen zur Bestellabwicklung und
                    zur Personalisierung Ihres Erlebnisses auf unserer Website.
                  </p>
                  <p>
                    Für Marketingzwecke: Wir können die Informationen verwenden,
                    um Ihnen Werbe-E-Mails zu senden.
                  </p>

                  <p className="m-0 fw-bold">Offenlegung von Informationen</p>
                  <p className="m-0">
                    Unter den folgenden Umständen können wir Informationen über
                    unsere Benutzer offenlegen:
                  </p>
                  <p>
                    Aus rechtlichen Gründen: Wir können Ihre Daten als Reaktion
                    auf eine Vorladung oder eine andere rechtliche Anfrage
                    offenlegen.
                  </p>

                  <p className="m-0 fw-bold">Informationssicherheit</p>
                  <p>
                    Wir ergreifen geeignete Maßnahmen, um die Sicherheit Ihrer
                    Daten zu schützen, einschließlich der Implementierung
                    technischer und organisatorischer Maßnahmen, um unbefugten
                    Zugriff, Offenlegung oder Zerstörung zu verhindern.
                  </p>

                  <p className="m-0 fw-bold">Rechte betroffener Personen</p>
                  <p>
                    Sie haben das Recht, auf Ihre persönlichen Daten
                    zuzugreifen, diese zu korrigieren und zu löschen. Sie haben
                    außerdem das Recht, der Verarbeitung Ihrer personenbezogenen
                    Daten zu widersprechen und eine Kopie Ihrer
                    personenbezogenen Daten in einem strukturierten,
                    maschinenlesbaren Format zu erhalten.
                  </p>

                  <p className="m-0 fw-bold">Newsletter-Anmeldung</p>
                  <p>
                    Sollen Sie sich für den Newsletter registriert haben,
                    verwenden wir diese Daten ausschließlich für den Versand des
                    Newsletters, der vom Hanl Verlag verschickt wird.
                  </p>

                  <p className="m-0 fw-bold">Kontaktieren Sie uns</p>
                  <p>
                    Wenn Sie Fragen oder Bedenken zu dieser
                    Datenschutzrichtlinie haben, kontaktieren Sie uns bitte
                    unter info@hanlbook.com.
                  </p>

                  <p>
                    Allgemeine Geschäftsbedingungen und Verbraucherinformationen
                    zu Verkaufsverträgen zwischen dem Verlag: Hanl Verlag und
                    den Kunden.
                  </p>

                  <h5>§ 1 Geltungsbereich</h5>
                  <p>
                    Diese Allgemeinen Geschäftsbedingungen gelten für alle
                    Bestellungen, die über unseren Online-Shop getätigt werden.
                    Durch die Nutzung unseres Online-Shops erklären Sie sich mit
                    diesen Geschäftsbedingungen einverstanden. Unser Online-Shop
                    richtet sich ausschließlich an Verbraucher als Kunden.
                    Verbraucher ist jede natürliche Person, die ein
                    Rechtsgeschäft zu Zwecken abschließt, die überwiegend weder
                    ihrer gewerblichen noch ihrer selbständigen beruflichen
                    Tätigkeit zugerechnet werden können. Ein Unternehmer ist
                    eine natürliche oder juristische Person oder eine
                    rechtsfähige Personengesellschaft, die bei Abschluss eines
                    Rechtsgeschäfts in Ausübung ihrer gewerblichen oder
                    selbständigen beruflichen Tätigkeit handelt. Der oben
                    genannte Verlag erkennt abweichende Bedingungen des Kunden
                    nicht an, es sei denn, sie werden ausdrücklich schriftlich
                    vereinbart.
                  </p>

                  <h5>§ 2 Vertragsabschluss</h5>

                  <p>
                    Der Kaufvertrag kommt mit dem jeweiligen Verlag zustande.
                    Die Darstellung der Produkte im Online-Shop stellt kein
                    rechtlich bindendes Angebot dar, sondern einen
                    unverbindlichen Online-Katalog. Sie können Produkte
                    unverbindlich in den Warenkorb legen und Ihre Eingaben vor
                    dem Absenden Ihrer verbindlichen Bestellung jederzeit
                    korrigieren. Die Bestellung, die ein verbindliches Angebot
                    zum Kauf der Waren im Warenkorb darstellt, kann nur
                    abgegeben werden, wenn Sie zuvor diese Allgemeinen
                    Geschäftsbedingungen durch Anklicken der Schaltfläche
                    "Allgemeine Geschäftsbedingungen akzeptieren" akzeptiert
                    haben. Dadurch binden Sie diese Geschäftsbedingungen in Ihr
                    Angebot ein. Die Bestellung wird ausgelöst, indem Sie auf
                    die Schaltfläche "Mit zahlungspflichtig bestellen" klicken.
                    Mit der Bestellung geben Sie eine verbindliche Erklärung ab,
                    dass Sie die Waren erwerben möchten. Sie erhalten eine
                    Bestätigung per E-Mail, dass Ihre Bestellung eingegangen
                    ist. Diese Bestätigung stellt keine Annahmeerklärung dar.
                    Der Kaufvertrag kommt zustande, wenn der jeweilige Verlag
                    das in Ihrer Bestellung enthaltene Angebot durch eine
                    Bestätigungsemail, eine Rechnungsemail oder durch Lieferung
                    der Ware annimmt. Der Verlag verpflichtet sich, Ihre
                    Bestellung unter den auf der Website festgelegten
                    Bedingungen anzunehmen. Bei Schreib-, Druck- oder
                    Rechenfehlern auf der Website ist der jeweilige Verlag
                    berechtigt, sich vom Vertrag zu lösen."
                  </p>
                </div>
              </Modal.Body>
            </Modal>
          </Container>
        </div>
      )}
    </>
  );
};

export default Footer;
