import { Pagination } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useEffect } from 'react'


const Paginate = ({pages, page, keyword = '', isAdmin = false}) => {

    let category = ''
    let author = ''

    if (keyword.includes('keyword')) {
        keyword = keyword.split('?keyword=')[1].split('&')[0]

    } else if (keyword.includes('category')) {
         category = keyword.split('?category=')[1].split('&')[0]

    } else if (keyword.includes('author')) {
        author = keyword.split('?author=')[1].split('&')[0]
    }

    useEffect(() => {

        const paginateBtn = [...document.querySelectorAll('.paginate-btn')]
        
        if (paginateBtn) {
            paginateBtn.forEach(btn => {
                
                if (btn.dataset.active === 'true') {

                    btn.style.background = '#D3C7CA'
                }               
            })
        }
        
    }, [page])

    return (
        pages > 1 && (
            <Pagination>
                {
                    [...Array(pages).keys()].map(x => (

                        <Link key={x + 1} to={!isAdmin ? category ? `/books/?category=${category}&page=${x + 1}` 
                         : author ? `/books/?author=${author}&page=${x +  1}` 
                         : `/books/?keyword=${keyword}&page=${x + 1}` 
                         : `/admin/booklist/?keyword=${keyword}&page=${x + 1}` }
                        >
                            <button className={`paginate-btn`} data-active={(x + 1 === page).toString()}>
                                { x + 1 }
                            </button>
                        </Link>
                    ))
                }
            </Pagination>
        )
    )
}

export default Paginate
