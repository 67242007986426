import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { english } from './locales/en'
import { deutch } from './locales/de'

// Translation resources for each language
const resources = {
  en: {
    translation: english
  },
  de: {
    translation: deutch
  }
};

// i18next configuration options
const options = {
  resources,
  lng: 'en',
  interpolation: {
    escapeValue: false
  }
};

// Initialize i18next and configure it for React
i18n.use(initReactI18next).init(options);

export default i18n;
