import { useState, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Form, Button, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listBlog, updateBlog } from '../actions/blogActions'
import axios from 'axios'
import Loader from '../components/Loader';
import Message from '../components/Message';
import FormContainer from '../components/FormContainer'
import { useTranslation } from 'react-i18next'


const BlogEditScreen = () => {

    const [name, setName] = useState('')
    const [nameDe, setNameDe] = useState('')
    const [author, setAuthor] = useState('')
    const [sectionOneText, setSectionOneText] = useState('')
    const [sectionOneTextDe, setSectionOneTextDe] = useState('')
    const [image, setImage] = useState('')
    const [uploading, setUploading] = useState(false)
    
    const { t } = useTranslation()

    const { id } = useParams()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const blogList = useSelector(state => state.blogList)
    const { loading, error, blog } = blogList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const blogUpdate = useSelector(state => state.blogUpdate)
    const { success, loading:updateLoading, error:updateError } = blogUpdate
    

    useEffect(() => {
        
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } 

        if (success) {
            dispatch({type: 'UPDATE_BLOG_RESET'})
            navigate('/admin/blogs')

        } else {
        
            if (!blog || blog.id !== id) {

                dispatch(listBlog(id))

            } else {
                setName(blog.name)
                setNameDe(blog.nameDe)
                setAuthor(blog.author)
                setSectionOneText(blog.sectionOneText)
                setSectionOneTextDe(blog.sectionOneTextDe)
            }
        }

    }, [id, blog, success, userInfo, navigate, dispatch])


    const uploadFileHandler = async (e) => {

        const file = e.target.files[0]
        const formData = new FormData()

        formData.append('image', file)
        formData.append('blog_id', id)

        setUploading(true)

        try {

            const config = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }

            const { data } = await axios.post(
                `/api/blogs/upload/`,
                formData,
                config,
            )
            
            setImage(data)
            setUploading(false)

        } catch (error) {
            setUploading(false)
        }
    }


    const submitHandler = (e) => {
        e.preventDefault()
        
        dispatch(updateBlog({
            name,
            nameDe,
            author,
            id,
            sectionOneText,
            sectionOneTextDe
        }))
        
    }

    return (
        <Container className='my-5'>
            <Link to='/admin/blogs' className='btn btn-primary'>{t('Go Back')}</Link>
            <FormContainer>
                <h1>{t('Edit Blog')}</h1>

               {updateLoading && <Loader/>}
               {updateError && <Message variant='danger'>{updateError}</Message>}

                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                    : (
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId='name'>
                                <Form.Label>{('Blog Name')}</Form.Label>
                                <Form.Control type='name' placeholder={t('Enter Name')} value={name}
                                    onChange={(e) => setName(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='nameDe'>
                                <Form.Label>{('Blog Name')} in Deutsche</Form.Label>
                                <Form.Control type='name' placeholder={t('Enter Name')} value={nameDe ? nameDe : ''}
                                    onChange={(e) => setNameDe(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='author-name'>
                                <Form.Label>{t('Author Name')} </Form.Label>
                                <Form.Control type='name' placeholder={t('Enter Name')} value={author ? author : ''}
                                    onChange={(e) => setAuthor(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='sectionOneText'>
                                <Form.Label>{t('Text')}</Form.Label>
                                <Form.Control as='textarea' placeholder={t('Enter Section One Text')} value={sectionOneText ? sectionOneText : ''}
                                    onChange={(e) => setSectionOneText(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='sectionOneTextDe'>
                                <Form.Label>{t('Text')} in Deutsche</Form.Label>
                                <Form.Control as='textarea' placeholder={t('Enter Section One Text')} value={sectionOneTextDe ? sectionOneTextDe : ''}
                                    onChange={(e) => setSectionOneTextDe(e.target.value)}/>
                            </Form.Group>

                            <Form.Group controlId='image'>
                                <Form.Label>{t('Image')}</Form.Label>
                                <Form.Control type='text' placeholder={t('Enter Image')} value={image}
                                    onChange={(e) => setImage(e.target.value)} disabled/>

                                <Form.Control type='file' label={t('Choose File')}
                                    custom='true' onChange={uploadFileHandler} />

                                {uploading && <Loader />}
                            </Form.Group>

                            <Button type='submit' variant='primary mt-4'>{t('update')}</Button>
                        </Form>
                    )
                }
            
            </FormContainer>
        </Container>
    )
}

export default BlogEditScreen
