import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'

const BlogCard = ({blog, flex}) => {

    const { i18n, t } = useTranslation()

    const readMore = (e, id) => {
       const extendedText = document.getElementById(`extend-text-${id}`)
       const blogName = document.getElementById(`blogName-${id}`)
       const blogNameSec = document.getElementById(`blogNameSec-${id}`)
       const blogImg = document.getElementById(`image-${id}`)
       blogName.classList.toggle('d-none')
       blogNameSec.classList.toggle('d-none')
       extendedText.classList.toggle('d-none')
       blogImg.classList.toggle('extended')

       if(extendedText.classList.contains('d-none')) {
            e.target.innerText = `... ${t('Read More')}`
       } else {
        e.target.innerText = t('Read Less')
       }

    }

    return (
        <motion.div initial={{opacity: 0, x: flex % 2 ? 300 : - 300}} whileInView={{opacity: 1, x: 0}} transition={{duration: 0.6}}
         className={`blog-card d-flex ${flex % 2 ? '' : 'flex-lg-row-reverse odd'} flex-column align-items-center justify-content-between flex-lg-row position-relative `}>
            <img src={blog.image} id={`image-${blog.id}`} className={`me-0 mb-3 mb-md-0 `} alt="" />

            <div className="mx-3 mx-xxl-5 hr-line"></div>
            <div>
                <span style={{top: '-60px'}} id={`blogName-${blog.id}`} className="blog-name d-none position-absolute start-50 translate-middle-x fs-3 mt-3 fw-semibold mt-lg-0 filter-active">{i18n.language === 'en' ?  blog.name : blog.nameDe}</span>
                <div className="d-flex justify-content-between">
                    <h2 id={`blogNameSec-${blog.id}`} className="mt-3 mt-lg-0">{i18n.language === 'en' ?  blog.name : blog.nameDe}</h2>
                    
                </div>
                {blog.author && (

                    <span>by {blog.author}</span>
                )}

                <div className="mt-0 mt-lg-4 blog-text">
                    
                    {blog.sectionOneText ? (
                        <p>
                            {(i18n.language === 'de' && blog.sectionOneTextDe) ? blog.sectionOneTextDe.substring(0,400)
                             : blog.sectionOneText.substring(0,400)}
                            <span id={`extend-text-${blog.id}`} className="d-none">
                            {(i18n.language === 'de' && blog.sectionOneTextDe) ? blog.sectionOneTextDe.substring(400)
                             : blog.sectionOneText.substring(400)}
                                 </span>
                            <button className='ms-2' onClick={(e) => readMore(e, blog.id)}>... {t('Read More')}</button>

                        </p>
                    ) : blog.authorInfo && (
                        <p>
                            <span className='green-text fw-bold'>{blog.firstName} </span>
                            {i18n.language === 'en' ? blog.authorInfo && blog.authorInfo.replace(blog.firstName, '') 
                             : blog.authorInfoDe && blog.authorInfoDe.replace(blog.firstName, '')}
                            
                        </p>
                    )}
                </div>

            </div>
        </motion.div>
    )
}

export default BlogCard
