export const orderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case "ORDER_CREATE_REQUEST":
      return {
        loading: true,
      };

    case "ORDER_CREATE_SUCCESS":
      return {
        loading: false,
        order: action.payload,
      };

    case "ORDER_CREATE_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    case "ORDER_CREATE_RESET":
      return {};

    default:
      return state;
  }
};

export const listOrderReducer = (
  state = { loading: true, orderItems: [] },
  action
) => {
  switch (action.type) {
    case "LIST_ORDER_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "LIST_ORDER_SUCCESS":
      return {
        loading: false,
        order: action.payload,
      };

    case "LIST_ORDER_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const listAllOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case "LIST_ALL_ORDERS_REQUEST":
      return {
        loading: true,
      };

    case "LIST_ALL_ORDERS_SUCCESS":
      return {
        loading: false,
        myOrdersInfo: action.payload,
      };

    case "LIST_ALL_ORDERS_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const listUsersOrdersReducer = (state = {}, action) => {
  switch (action.type) {
    case "LIST_USERS_ORDERS_REQUEST":
      return {
        loading: true,
      };

    case "LIST_USERS_ORDERS_SUCCESS":
      return {
        loading: false,
        usersOrders: action.payload,
      };

    case "LIST_USERS_ORDERS_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const orderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case "ORDER_PAY_REQUEST":
      return {
        loading: true,
      };

    case "ORDER_PAY_SUCCESS":
      return {
        loading: false,
        success: true,
      };

    case "ORDER_PAY_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    case "ORDER_PAY_RESET":
      return {};

    default:
      return state;
  }
};

export const orderPrepayReducer = (state = {}, action) => {
  switch (action.type) {
    case "ORDER_PREPAY_REQUEST":
      return {
        loading: true,
      };

    case "ORDER_PREPAY_SUCCESS":
      return {
        loading: false,
        success: true,
        message: action.payload,
      };

    case "ORDER_PREPAY_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    case "ORDER_PREPAY_RESET":
      return {};

    default:
      return state;
  }
};

export const deliverOrderReducer = (state = {}, action) => {
  switch (action.type) {
    case "DELIVER_ORDER_REQUEST":
      return {
        loading: true,
      };

    case "DELIVER_ORDER_SUCCESS":
      return {
        loading: false,
        success: true,
      };

    case "DELIVER_ORDER_FAIL":
      return {
        loading: false,
        error: action.payload,
      };

    case "DELIVER_ORDER_RESET":
      return {};

    default:
      return state;
  }
};
