import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { NavDropdown } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'



const Filter = ({filters}) => {
    
    const { t, i18n } = useTranslation()
    const [authors, setAuthors] = useState()

    useEffect(() => {

        if (filters.authors) {
            setAuthors(filters.authors)
        }

    }, [filters])

    const goToPerson = (person) => {
        const personCard = [...document.querySelectorAll('.blog-card')]
        const personElement = document.getElementById(`${person}`)
        if (personElement) {

            personElement.scrollIntoView({ behavior: "smooth"})
            personCard.forEach((card => card.id === person ? card.classList.add('active') : card.classList.remove('active')))
        }
    }  

    const activateLink = (e) => {
        document.querySelectorAll('.filter-span').forEach(el => el.classList.remove('filter-active'))

        if (e.target.tagName === 'A' ) {
            e.target.children[0].classList.add('filter-active')

            
        } else {
            e.target.classList.add('filter-active')
        }
    }

    return (
        <div className="filter">
    
            <div className='d-flex flex-md-column justify-content-between'>
                {filters.categories && (
                    <div className='category-desktop'>
                        <h3 className='fs-6 fw-bold gray'>{t('Categories')}</h3>
                        <div>
                            {filters.categories.map((category) => (
                                <Link onClick={activateLink} className='d-block green-link  fs-6 text-start'
                                    key={category.id} to={`/books/?category=${category.id}&page=1`}>
                                        <span className={`filter-span ${category.id === filters.activeCat ? 'filter-active' : ''}`}> 
                                            {i18n.language === 'de' ? category.nameDe.charAt(0).toUpperCase() + category.nameDe.slice(1) : category.name.charAt(0).toUpperCase() + category.name.slice(1)}
                                        </span>
                                </Link>
                            ))}
                        </div>
                    </div>
                )} 

                {filters.categories && (
                    <div className='category-mobile'>
                        <NavDropdown title={t('Categories')} id='filterCategory'> 

                        {filters.categories.map((category) => (
                            <Link className='d-block green-link  my-1 fs-6 text-center text-md-start'
                                key={category.id} to={`/books/?category=${category.id}&page=1`}>
                                {i18n.language === 'de' ? category.nameDe : category.name.charAt(0).toUpperCase() + category.name.slice(1)}
                            </Link>
                        ))}

                        </NavDropdown>
                    </div>
                )} 

                {authors && (  
                    <div>
                        <div className='language-desktop'>
                            <Link className='gray' to="/people?author"><h3 className='fs-6 fw-bold mt-4'>{t('Authors')}</h3></Link>

                            <div >
                                {authors.map(author => author.job === 'author' && (  
                                    <Link to={`/people/#${author.id}`} className='d-block green-link fs-6' key={author.id}
                                         onClick={(e) => {
                                            goToPerson(author.id)
                                            activateLink(e)
                                            }} >
                                        <span className='filter-span'>{author.firstName.charAt(0).toUpperCase() + author.firstName.slice(1)}</span>
                                    </Link>
                                ))}
                            </div>
                        </div>
                
                        <div className='language-mobile'>
                                <NavDropdown title={t('Authors')} id='filterAuthors'>                              
                                    {authors.map(author => author.job === 'author' && (  
                                        <Link to={`/people/#${author.id}`} className='d-block green-link my-1 fs-6 text-center text-md-start'
                                            key={author.id} onClick={() => goToPerson(author.id)} >
                                            <li>{author.firstName.charAt(0).toUpperCase() + author.firstName.slice(1)}</li>
                                        </Link>
                                    ))}
                            </NavDropdown>
                        </div>
                        
                    </div>
                )}

                {authors && (   
                    <div>
                        <div className='language-desktop'>
                            <Link className='gray' to="/people?artist"><h3 className='fs-6 fw-bold mt-4'>{t('Artists')}</h3></Link>
                            <div>              
                                {authors.map(author => author.job === 'artist' && (               
                                    <Link to={`/people/#${author.id}`}  className='d-block green-link fs-6'
                                        key={author.id} onClick={(e) => {
                                            goToPerson(author.id)
                                            activateLink(e)
                                            }} >
                                        <span className='filter-span'>{author.firstName.charAt(0).toUpperCase() + author.firstName.slice(1)}</span>
                                    </Link>    
                                ))}                         
                            </div>
                        </div>
                
                        <div className='language-mobile'>         
                            <NavDropdown title={t('Artists')} id='filterAuthors'> 
                                {authors.map(author => author.job === 'artist' && ( 
                                    <Link to={`/people/#${author.id}`}  className='d-block green-link my-1 fs-6 text-center text-md-start'
                                        key={author.id} onClick={() => goToPerson(author.id)} >
                                        <li>{author.firstName.charAt(0).toUpperCase() + author.firstName.slice(1)}</li>
                                    </Link>
                                ))}
                            </NavDropdown>
                        </div>
                    </div>
                )}

                {authors && (
                    <div className='pt-2 mt-1'>
                        <Link className='gray m-0' to="/people?team"><h3 className='fw-bold fs-6 m-0'>Hanl's Team</h3></Link>
                    </div>
                )}

                {filters.languages && (

                    <div className='language-desktop mt-4'>
                    <h3 className='fs-6 fw-bold gray'>{t('Languages')}</h3>
                        <div>
                            {filters.languages.map((lang, idx) => (
                                <Link onClick={activateLink}  to={`/books/?lang=${lang.toLowerCase()}&page=1`} className='d-block green-link fs-6 text-start' key={idx}>
                                    <span className='filter-span'>
                                        {lang}
                                    </span>
                                </Link>
                            ))}
                        </div>
                    </div>
                )}

                {filters.languages && (

                    <div className='language-mobile'>
                    <NavDropdown title={t('Languages')} id="filterLanguage">
                        {filters.languages.map((lang, idx) => (
                            <Link to={`/books/?lang=${lang.toLowerCase()}&page=1`} className='d-block green-link my-1 fs-6 text-center' key={idx}>
                                {lang}
                            </Link>
                        ))}
                    </NavDropdown>
                    </div>
                )}
                
                {filters.categories && (
                    <div className='d-flex align-items-center mt-2 mt-lg-3'>
                        <Link to={`/books/?in-stock=all&page=1`} className='fs-6 mt-0 mt-lg-4 fw-bold gray my-md-1 '>{t('In-stock')}</Link>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Filter
