import { useState } from 'react'
import { Form, Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'
import Loader from '../components/Loader'

const PasswordReset = () => {

    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()

    const submitHandler = async (e) => {
        e.preventDefault()
        setLoading(true)
        
        try {
            const { data } = await axios.post('api/users/reset-password/', {
                'email': email
            })    

            if (data) {
                setMessage(data)
                setLoading(false)
            }
        } catch (error) {
            setMessage(error.response.data.detail)
            setLoading(false)
        }
    }

    return (
      
        <FormContainer>
            <h1>{t('reset password')}</h1>   
            <p>{t('forgotten-pw')}</p>
            {message && <Message variant={'warning'}>{message}</Message>}
            {loading && <Loader />}
            <div>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='email'>
                        <Form.Label>{t('email address')}</Form.Label>
                        <Form.Control type='email' placeholder={t('enter email')} value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                    </Form.Group>

                    <div className='d-flex justify-content-between'>
                        <Button type='submit' variant='primary mt-4'>{t('submit')}</Button>
                    </div>
                </Form>

            </div>
           
        </FormContainer>
    )
}

export default PasswordReset
