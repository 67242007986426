import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Table, Button, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listUsersOrders } from "../actions/orderActions";
import Loader from "../components/Loader";
import Message from "../components/Message";

const OrderListScreen = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const listOrders = useSelector((state) => state.listOrders);
  const { loading, error, usersOrders } = listOrders;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsersOrders());
    } else {
      navigate("/login");
    }
  }, [dispatch, navigate, userInfo]);

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Message variant="danger">{error}</Message>;
  }

  return (
    <Container className="my-5">
      <h1>Orders</h1>

      <Table striped bordered hover responsive className="table-sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>USER</th>
            <th>DATE</th>
            <th>TOTAL</th>
            <th>PAID</th>
            <th>DELIVERED</th>
            <th>INFO</th>
            <th>PAYMENT</th>
          </tr>
        </thead>

        <tbody>
          {usersOrders &&
            usersOrders.map((order) => {
              if (!order.isPaid && order.paymentMethod !== "prepayment") {
                return null;
              }

              return (
                <tr key={order.id}>
                  <td>{order.orderNumber || order.id}</td>
                  <td>{order.user && order.user.name}</td>
                  <td>{order.createdAt.substring(0, 10)}</td>
                  <td>{order.totalPrice}&euro;</td>
                  <td>
                    {order.isPaid ? (
                      order.paidAt.substring(0, 10)
                    ) : (
                      <i className="fa-solid fa-x" style={{ color: "red" }}></i>
                    )}
                  </td>

                  <td>
                    {order.isDelivered ? (
                      order.deliveredAt.substring(0, 10)
                    ) : (
                      <i className="fa-solid fa-x" style={{ color: "red" }}></i>
                    )}
                  </td>

                  <td>
                    <Link to={`/order/${order.id}`}>
                      <Button className="btn btn-sm">Details</Button>
                    </Link>
                  </td>

                  <td>{order.paymentMethod || "Payment method"}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Container>
  );
};

export default OrderListScreen;
