import { useEffect, useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listEbooks } from '../actions/bookActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import axios from 'axios'
import { toast } from 'react-toastify'


const PdfsScreen = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [deleted, setDeleted] = useState(false)

    const ebooksList = useSelector(state => state.ebooksList)
    const { loading, error, ebooks } = ebooksList
        
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    useEffect(() => {
        setDeleted(false)

        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } 

        dispatch(listEbooks())
       

    }, [dispatch, navigate, userInfo, deleted])

    const deleteHandler = async(id) => {
        
        if (window.confirm('Are you sure you want to delete this pdf?')) {
            try {

                const config = {
                    headers: {
                        'Content-type': 'application/json',
                        Authorization: `Bearer ${userInfo.token}`
                    }
                }
    
                const { data } = await axios.delete(
                    `/api/books/pdfs/${id}/delete/`,
                    config,
                )

                toast.success(data)
                setDeleted(true)

            } catch (error) {
                toast.error(error.response)
            }
            
        }

    }

    const createBookHandler = () => {
        navigate('/admin/add-pdf')
    }

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <Message variant='danger'>{error}</Message>
    }

    return (
        <Container className='my-5'>
            <Row className='align-items-center'>
                <Col><h1>E-Books PDFs</h1></Col>

                <Col className='d-flex justify-content-end'>
                    <Button className='my-3' onClick={createBookHandler}>
                        <i className='fas fa-plus'></i> Add E-book Pdf
                    </Button>
                </Col>
            </Row>


            <div>
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                            <th>NAME</th>
                            <th>PDF</th>
                            <th>LANGUAGE</th>
                            <th>DELETE</th>
                        </tr>
                    </thead>

                    <tbody>
                        {ebooks && ebooks.map(book => (
                            <tr key={book.id}>
                                <td>{book.bookName}</td>
                                {book.eBookPDF ? (
                                    <td><Link to={book.eBookPDF} target='_blank'>{book.eBookPDF.split('pdfs/')[1]}</Link></td>
                                ) : (
                                    <td>No pdf</td>
                                )}
                                <td>{book.language}</td>

                                <td>
                                    <Button variant='danger' className='btn-sm mt-1 mt-lg-0 ms-lg-2'
                                        onClick={() => deleteHandler(book.id)}>
                                        <i className='fas fa-trash'></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>

            </div>
        </Container>
    )
}

export default PdfsScreen
