import { useParams, Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Form, ListGroup, Button } from 'react-bootstrap'
import { listBook } from '../actions/bookActions'
import { createReview } from '../actions/bookActions'
import { useTranslation } from 'react-i18next'
import BookCardInfo from '../components/BookCardInfo'
import Reviews from '../components/Reviews'
import Message from '../components/Message'
import Loader from '../components/Loader'


const BookScreen = () => {

    const { id } = useParams()
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')

    const bookList = useSelector(state => state.bookList)
    const { loading, book, error } = bookList

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const createdReview = useSelector(state => state.createdReview)
    const { loading:loadingProductReview, success:successCreatedReview, error:errorCreateReview} = createdReview

    useEffect(() => {
        
        dispatch(listBook(id))
        
        window.scrollTo(0,0)

        if (successCreatedReview) {
            setRating(0)
            setComment('')
            dispatch({type: 'REVIEWS_CREATE_RESET'})
        }

    }, [dispatch, id, successCreatedReview])


    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(createReview(id, {
            rating,
            comment
        }))
    }

    return (
        <div>
            <div className='window-blur d-none'></div>
            <Container>
                <div className='mt-3'>
                    <h2 className="gray">{t('Book Collection')}</h2>
                    <Link to='/' className='green-link fs-6 fs-md-5'>{t('Home')} &gt; </Link>
                    <Link to='/books' className='green-link fs-6 fs-md-5'>{t('Book Collection')} &gt; </Link>
                    {book.category && (
                        <>  
                        <Link to={`/books/?category=${book.category.id}&page=1`} className='green-link fs-6 fs-md-5'>  
                                {(i18n.language === 'de' && book.category.nameDe) ? book.category.nameDe.charAt(0).toUpperCase() + book.category.nameDe.slice(1)
                                : book.category.name.charAt(0).toUpperCase() + book.category.name.slice(1)} 
                                &gt; 
                        </Link>

                        <Link to={`/books/${book.id}`} className='green-link fs-6 fs-md-5 fw-normal'> {book.name}</Link>
                        </>
                    )}

                </div>
                {loading ? <Loader /> : error ? <Message variant='danger'>{error}</Message>
                : book && <BookCardInfo book={book}/>}
                
                <div>
                    <div className='d-flex flex-column flex-lg-row justify-content-between'>
                        <div>
                            {book && book.donation && (

                                <div className='social-contrib mt-4'>
                                    <h4 className='m-0'>{t('Social Contribution')}</h4>
                                    <p className='m-0'>{i18n.language === 'en' ? book.donation : book.donationDe}</p>
                                </div>
                            )}

                            {book && book.productDetails && (
                                <div className="my-0 mt-3 my-lg-5 product-info">
                                    <h3>{t('Product Description')}</h3>
                                
                                    <div>
                                        {i18n.language === 'en' ? book.productDetails.split(',').map((detail,idx) => (
                                            <p key={idx}>{detail}</p>
                                            
                                        )) : book.productDetailsDe.split(',').map((detail,idx) => (
                                            <p key={idx}>{detail}</p>))}
                                    </div>
                                </div>
                            )}
                        </div>
                        
                            <div className='reviews-box'>
                                <h2 className='mb-2 light-green-text'>{t('Reviews')}</h2>
                                { book.rating ? (
                                    <Reviews reviews={book.reviews}/>
                                ) : (<p className='ms-4'>{t('No reviews yet')}.</p>)}
                            </div>
                        

                    </div>

                </div>

                <div className='author-description'>
                    <h2 className='text-center mb-5 lg-mb-5' style={{color: '#374242'}}>{t('About author')}</h2>
                    {book.author && book.author.map((author,idx) => (
                        <div key={idx}>
                            <div className={`${idx % 2 === 0 ? 'odd' : ''} author-desc-box blog-card`}>
                            
                                    <div className={`d-flex flex-column-reverse ${idx % 2 === 0 ? 'flex-lg-row' : 'flex-lg-row-reverse'} align-items-center justify-content-between`}>
                                        <p>
                                            {(i18n.language === 'de' && author.authorInfoDe) ? author.authorInfoDe
                                            : author.authorInfo }
                                        </p>

                                        <div className='h-line-break'></div>

                                        <img src={author.image} alt={author.firstName} className='mb-4 lg-mb-0'/>
                                    </div>
                            </div>
                            
                            {author.shortVideo && (
                                <section id='short-video' className='mt-4'>
                                    <iframe title='video' 
                                    src={`https://www.youtube.com/embed/${author.shortVideo.split('v=')[1]}`}
                                    >
                                    </iframe>
                                </section>
                            )}

                            {author.video && (
                                <section id='tours'>
                                    <iframe title='video'
                                        src={`https://www.youtube.com/embed/${author.video.split('v=')[1]}`}
                                        >
                                    </iframe>
                                </section>
                            )}
                        </div>

                    ))}
                </div>

                <ListGroup.Item className='mb-5 mt-5 write-review'>
                    <h4 style={{color: '#374242'}}>{t('Write a review')}</h4>

                    {loadingProductReview && <Loader/>}
                    {successCreatedReview && <Message variant='success'>Review Submitted</Message>}
                    {errorCreateReview && <Message variant='danger'>{errorCreateReview}</Message>}

                    {userInfo ? (
                        <Form onSubmit={submitHandler} >
                            <Form.Group controlId='rating'>
                                <Form.Label>{t('Rating')}</Form.Label>
                                <Form.Control as='select' value={rating}
                                onChange={(e) => setRating(e.target.value)}
                                > 
                                    <option value=''>{t('Select')} . . .</option>
                                    <option value='1'>1 - {t('poor')}</option>
                                    <option value='2'>2 - {t('fair')}</option>
                                    <option value='3'>3 - {t('good')}</option>
                                    <option value='4'>4 - {t('very good')}</option>
                                    <option value='5'>5 - {t('excellent')}</option>

                                </Form.Control>
                            </Form.Group>

                            <Form.Group className='mt-4' controlId='comment'>
                                <Form.Label>{t('Review')}</Form.Label>
                                <Form.Control as='textarea' rows='5'
                                value={comment} onChange={(e) => setComment(e.target.value)} />
                            </Form.Group>

                            <Button disabled={loadingProductReview} type='submit' variant='primary'
                            className='mt-3'>
                                {t('submit')}
                            </Button>
                        </Form>
                    ) : <Message variant='info'>{t('Please')} <Link to='/login'>
                            {t('log in')}</Link> {t('to write a review')}
                        </Message>
                    }
                </ListGroup.Item>
        
            </Container>
        </div>
    )
}

export default BookScreen
