import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container} from 'react-bootstrap'
import { listEvents } from '../actions/eventActions'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import Loader from '../components/Loader'
import Message from '../components/Message'


const EventsScreen = () => {

    const dispatch = useDispatch()
    const { t, i18n } = useTranslation()

    const eventsList = useSelector(state => state.eventsList)
    const { loading, error, events} = eventsList

    useEffect(() => {

        if (!events) {

            dispatch(listEvents())
        }

    }, [dispatch, events])

    const toggleEv = (event, e) => {
        const evImg = document.getElementById(`event-${event}`)
        const svg = document.querySelector(`.svg-${event}`)
        
        if (e.target.tagName === 'P' || e.target.tagName === 'svg' || e.target.tagName === 'path') {
            evImg.classList.toggle('d-none')
            svg.classList.toggle('rotate-90')
        }
    }   

    return (
        <Container className='mt-3 position-relative events-container'>
            <motion.img initial={{x: 300}} animate={{ x: 0}} transition={{duration: 0.6}}
             src={require('../assets/calendar.png')} alt="calendar" className='events-img' />
            <h2 className='mb-4 page-title'>{t("Hanl Book's Events")}</h2>
            <h3 className='text-center'>
                <span className='book-tour'>{t('Book Tour')}</span>
            </h3>
            {loading && <Loader/>}
            {error && <Message variant='danger'>{error}</Message>}

            {events && events.map(event => (
                <div key={event.id} className='mb-5 mt-3'>
                    <div onClick={(e) => toggleEv(event.id, e)} className='d-flex align-items-center book-type'>
                        <svg className={`svg-${event.id} mb-4 me-3`} width="31" height="22" viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M28.7274 1.89623L15.7517 19.5123L2.42483 2.16048L28.7274 1.89623Z" fill="#F2D291" stroke="#F2D291" strokeWidth="2"/>
                        </svg>
                        <p className='fs-3 event-name mb-4'>{i18n.language === 'en' ? event.name.slice(0, 15) : event.nameDe && event.nameDe.slice(0, 15)}
                        </p>
                        <p className='fs-3 event-name mb-4 ms-2' style={{fontWeight: '500'}}>{ i18n.language === 'en' ? event.name.slice(15, event.name.length)
                            : event.nameDe && event.nameDe.slice(15, event.nameDe.length)}</p>
                    </div>
                    <div className='event-img' id={`event-${event.id}`}>
                        <motion.img initial={{opacity:0, scale: 0.5}} whileInView={{opacity: 1, scale: 1}}
                         transition={{duration: 0.6}}
                         src={event.image} alt={event.name} />
                    </div>
                </div>
            ))}

        </Container>
    )
}

export default EventsScreen
