import axios from 'axios'

export const listAuthors = () => async(dispatch) => {
    try {

        dispatch({type: 'LIST_AUTHORS_REQUEST'})

        const { data } = await axios.get(`/api/authors/`)

        dispatch({
            type: 'LIST_AUTHORS_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_AUTHORS_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const listAuthor = (id) => async(dispatch, getState) => {
    try {

        dispatch({type: 'LIST_AUTHOR_REQUEST'})

        const { userLogin : {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.get(`/api/authors/${id}/`, config)

        dispatch({
            type: 'LIST_AUTHOR_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'LIST_AUTHOR_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const updateAuthor = (author) => async(dispatch, getState) => {
    try {

        dispatch({type: 'UPDATE_AUTHOR_REQUEST'})

        const { userLogin : {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.put(`/api/authors/${author.id}/edit/`,
            author,
            config
         )

        dispatch({
            type: 'UPDATE_AUTHOR_SUCCESS',
            payload: data
        })

        dispatch({
            type: 'LIST_AUTHOR_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'UPDATE_AUTHOR_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}



export const deleteAuthor = (id) => async(dispatch, getState) => {
    try {

        dispatch({type: 'DELETE_AUTHOR_REQUEST'})

        const { userLogin : {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.delete(`/api/authors/${id}/delete/`,
            config
         )

        dispatch({
            type: 'DELETE_AUTHOR_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'DELETE_AUTHOR_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}


export const createAuthor = () => async(dispatch, getState) => {
    try {

        dispatch({type: 'CREATE_AUTHOR_REQUEST'})

        const { userLogin : {userInfo}} = getState()

        const config = {
            headers: {
                'Content-type': 'application/json',
                Authorization: `Bearer ${userInfo.token}`
            }
        }

        const { data } = await axios.post(`/api/authors/create/`,
            { },
            config
         )

        dispatch({
            type: 'CREATE_AUTHOR_SUCCESS',
            payload: data
        })

    } catch (error) {
        dispatch({
            type: 'CREATE_AUTHOR_FAIL',
            payload: error.response && error.response.data.detail 
            ? error.response.data.detail 
            : error.message,
        })
    }
}