import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  booksListReducer,
  bookListReducer,
  addReviewReducer,
  booksListAllReducer,
  listCategoriesReducer,
  createBookReducer,
  listEbooksReducer,
  updateBookReducer,
  deleteBookReducer,
} from "./reducers/bookReducers";
import {
  userLoginReducer,
  userRegisterReducer,
  userProfileUpdateReducer,
  userDetailsReducer,
} from "./reducers/userReducers";
import { cartReducer, wishlistReducer } from "./reducers/cartReducers";
import {
  orderCreateReducer,
  listOrderReducer,
  listUsersOrdersReducer,
  orderPayReducer,
  listAllOrdersReducer,
  deliverOrderReducer,
  orderPrepayReducer,
} from "./reducers/orderReducers";
import {
  listAuthorsReducer,
  listAuthorReducer,
  updateAuthorReducer,
  deleteAuthorReducer,
  createAuthorReducer,
} from "./reducers/authorReducers";
import {
  blogsListReducer,
  blogCreateReducer,
  blogListReducer,
  blogUpdateReducer,
  blogDeleteReducer,
  sendNewsletterReducer,
} from "./reducers/blogReducers";
import {
  listEventsReducer,
  createEventReducer,
  listEventReducer,
  updateEventReducer,
  deleteEventReducer,
} from "./reducers/eventReducers";

const reducer = combineReducers({
  booksListAll: booksListAllReducer,
  booksList: booksListReducer,
  bookList: bookListReducer,
  ebooksList: listEbooksReducer,

  bookCreate: createBookReducer,
  bookUpdate: updateBookReducer,
  bookDelete: deleteBookReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userProfileUpdateReducer,

  cart: cartReducer,
  wishlist: wishlistReducer,

  createdReview: addReviewReducer,

  categoriesList: listCategoriesReducer,
  authorsList: listAuthorsReducer,
  authorList: listAuthorReducer,
  authorUpdate: updateAuthorReducer,
  authorDelete: deleteAuthorReducer,
  authorCreate: createAuthorReducer,

  orderCreate: orderCreateReducer,
  orderDetails: listOrderReducer,
  orderDeliver: deliverOrderReducer,
  orderPay: orderPayReducer,
  orderPrepay: orderPrepayReducer,
  myOrders: listAllOrdersReducer,

  listOrders: listUsersOrdersReducer,

  blogsList: blogsListReducer,
  blogList: blogListReducer,
  blogCreate: blogCreateReducer,
  blogUpdate: blogUpdateReducer,
  blogDelete: blogDeleteReducer,
  newsletterSend: sendNewsletterReducer,

  eventsList: listEventsReducer,
  eventList: listEventReducer,
  eventCreate: createEventReducer,
  eventUpdate: updateEventReducer,
  eventDelete: deleteEventReducer,
});

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const wishlistFromStorage = localStorage.getItem("wishlist")
  ? JSON.parse(localStorage.getItem("wishlist"))
  : [];

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : [];

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  wishlist: { wishlistItems: wishlistFromStorage },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
