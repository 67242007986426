import Col from 'react-bootstrap/Col'
import { Link }from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const HanlTeam = ({person, idx}) => {

    const {i18n} = useTranslation()
    console.log(idx % 2)
    return (
       <>
       {person.image ? (

            <Col xs={12} md={6} xxl={4} className={`mb-5 d-flex flex-column align-items-center align-items-xl-start`}>
                <div className={`blog-card ${idx % 2 === 0 ? 'odd' : ''} team`} >
                    <img className='team-img' src={person.image} alt="Michelle" />
                </div>
                <div className='mt-4 text-center text-md-start'>
                    <h2 className='fw-bold fs-5'>{person.firstName}</h2>
                    <span>{person.role}</span>

                    <p className='about-team mt-3'>{i18n.language === 'en' ? person.authorInfo : person.authorInfo.de}</p>

                    {person.link && (
                    <Link className='green-link' to="https://www.jelinek.in/" target='_blank'>
                    www.jelinek.in
                    </Link>
                    )}

                </div>
            </Col>

        ) : (
            <div className={`blog-card ${idx % 2 === 0 ? '' : 'odd'} team-sec mb-5 d-flex flex-column align-items-center align-items-xl-start`}>
                <div className='mt-4 px-0 px-md-5'>
                    <h2 className='fw-bold fs-5 ps-0 ps-md-5 m-0'>{person.firstName}</h2>
                    <p className='ps-0 ps-md-5'>{person.role}</p>
                    <p className='ps-0 ps-md-5'>
                        {i18n.language === 'en' ? person.authorInfo : person.authorInfoDe}
                    </p>
                    {person.link && (
                        <Link className='ps-0 ps-md-5 green-link' to={person.link} target='_blank'>
                            {person.link.split('://')[1]}
                        </Link>
                    )}
                </div>
            </div>           
        )}

        </>
    )
}


export default HanlTeam
