import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { Table, Button, Container, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listAuthors, deleteAuthor, createAuthor } from '../actions/authorActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import { useTranslation } from 'react-i18next'


const AuthorListScreen = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const authorsList = useSelector(state => state.authorsList)
    const { authors, loading, error } = authorsList

    const authorDelete = useSelector(state => state.authorDelete)
    const { success, loading:deleteLoading, error:deleteError } = authorDelete

    const authorCreate = useSelector(state => state.authorCreate)
    const { success:successCreate, author, loading:createLoading, error:createError } = authorCreate


    useEffect(() => {
        dispatch({type: 'CREATE_AUTHOR_RESET'})

        if (successCreate) {
            navigate(`/admin/authors/${author.id}/edit/`)
        }

        if (userInfo && userInfo.isAdmin) {
            dispatch(listAuthors())

        } else {
            navigate('/login')
        }

    }, [dispatch, navigate, success, userInfo, successCreate, author])

    const deleteHandler = (id) => {
        
        if (window.confirm('Are you sure you want to delete this product?')) {
            dispatch(deleteAuthor(id))
        }

    }

    const createAuthorHandler = () => {
        dispatch(createAuthor())
    }


    if (loading || deleteLoading || createLoading) {
        return <Loader/>
    }
    if (error) {
        return <Message variant='danger'>{error}</Message>
    }
    if (deleteError) {
        return <Message variant='danger'>{deleteError}</Message>
    }
    if (createError) {
        return <Message variant='danger'>{createError}</Message>
    }

    return (
        <Container className='my-5'>
            <Row className='align-items-center'>
                <Col>
                    <h1>Authors & Artists & Team</h1>
                </Col>

                <Col className='d-flex justify-content-end'>
                    <Button className='my-3' onClick={createAuthorHandler}>
                        <i className='fas fa-plus'></i> Create
                    </Button>
                </Col>
            </Row>

            <Table striped bordered hover responsive className='table-sm'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>{t('Author').toUpperCase()}</th>
                        <th>{t('About').toUpperCase()}</th>
                        <th>{t('Actions').toUpperCase()}</th>
                    </tr>
                </thead>

                <tbody>
                    {authors && authors.map(author => (
                        <tr key={author.id}>
                            <td>{author.id}</td>
                            <td>{author.firstName}</td>
                            {author.authorInfo && (
                                <td>{author.authorInfo.substring(0, 90)}</td>
                            )}

                            <td>
                                <Link to={`/admin/authors/${author.id}/edit`}>
                                    <Button variant='light' className='btn-sm'>
                                        <i className='fas fa-edit'></i>
                                    </Button>
                                </Link>

                                <Button variant='danger' className='btn-sm mt-1 mt-lg-0 ms-lg-2'
                                    onClick={() => deleteHandler(author.id)}>
                                    <i className='fas fa-trash'></i>
                                </Button>

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Container>
    )
}

export default AuthorListScreen
