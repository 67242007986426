import { HashRouter as Router, Route, Routes } from 'react-router-dom'
import { useEffect } from 'react'
import { I18nextProvider } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import i18n from './i18n'
import HomeScreen from './screens/HomeScreen'
import Header from './components/Header'
import Footer from './components/Footer'
import AboutScreen from './screens/AboutScreen';
import BooksScreen from './screens/BooksScreen';
import BookScreen from './screens/BookScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';
import CartScreen from './screens/CartScreen';
import ProfileScreen from './screens/ProfileScreen';
import ShippingScreen from './screens/ShippingScreen';
import PlaceOrderScreen from './screens/PlaceOrderScreen';
import OrderScreen from './screens/OrderScreen';
import BookListScreen from './screens/BookListScreen'
import BookEditScreen from './screens/BookEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import AuthorListScreen from './screens/AuthorsListScreen'
import AuthorEditScreen from './screens/AuthorsEditScreen'
import BlogsListScreen from './screens/BlogsListScreen'
import BlogEditScreen from './screens/BlogEditScreen'
import BlogsScreen from './screens/BlogsScreen'
import Stuttgart from './screens/Stuttgart'
import Koreea from './screens/Koreea'
import PasswordReset from './screens/PasswordReset'
import ResetScreen from './screens/ResetScreen'
import PeopleScreen from './screens/PeopleScreen'
import EventsScreen from './screens/EventsScreen'
import EventsListScreen from './screens/EventsListScreen'
import EventEditScreen from './screens/EventEditScreen'
import NewsletterScreen from './screens/NewsletterScreen'
import AddPdfScreen from './screens/AddPdfScreen'
import PdfsScreen from './screens/PdfsScreen'
import NotFound from './components/NotFound'
import Unsubscribe from './components/Unsubscribe'
import icon16 from './assets/favicon-16x16.png'
import icon32 from './assets/favicon-32x32.png'
import appleIcon from './assets/apple-touch-icon.png'


function App() {

  useEffect(() => {
    document.getElementById('favicon16').setAttribute('href', icon16)
    document.getElementById('favicon32').setAttribute('href', icon32)
    document.getElementById('appleIcon').setAttribute('href', appleIcon)
    
  }, [])

  return (
    <Router>
      <I18nextProvider i18n={i18n}>
        <ToastContainer />
        <Header/>

        <Routes>
          <Route path='/' element={<HomeScreen />} />

          <Route path='/about' element={<AboutScreen />} />

          <Route path='/books' element={<BooksScreen />} />
          <Route path='/books/:id' element={<BookScreen />} />

          <Route path='/blogs' element={<BlogsScreen/>}/>
          <Route path='/explore-places/stuttgart' element={<Stuttgart/>}/>
          <Route path='/explore-places/koreea' element={<Koreea/>}/>

          <Route path='/people' element={<PeopleScreen/>}/>

          <Route path='/events' element={<EventsScreen/>}/>

          <Route path='/login' element={<LoginScreen />} />
          <Route path='/register' element={<RegisterScreen />} />
          <Route path='/profile' element={<ProfileScreen />} />

          <Route path='/cart/:id?' element={<CartScreen />} />
          <Route path='/shipping' element={<ShippingScreen />} />
          <Route path='/placeorder' element={<PlaceOrderScreen />} />
          <Route path='/order/:id' element={<OrderScreen />} />

          <Route path='/reset-password' element={<PasswordReset/>}/>
          <Route path='/reset-password/:id/:token' element={<ResetScreen/>}/>
          <Route path='/unsubscribe' element={<Unsubscribe/>} />
          
          <Route path='/admin/authors' element={<AuthorListScreen />} />
          <Route path='/admin/authors/:id/edit' element={<AuthorEditScreen />} />

          <Route path='/admin/blogs' element={<BlogsListScreen />} />
          <Route path='/admin/blogs/:id/edit' element={<BlogEditScreen />} />

          <Route path='/admin/orders' element={<OrderListScreen />} />

          <Route path='/admin/booklist' element={<BookListScreen />} />
          <Route path='/admin/ebooks' element={<PdfsScreen/>} />
          <Route path='/admin/add-pdf' element={<AddPdfScreen/>} />
          <Route path='/admin/books/:id/edit' element={<BookEditScreen />} />

          <Route path='/admin/events' element={<EventsListScreen />} />
          <Route path='/admin/events/:id/edit' element={<EventEditScreen />} />

          <Route path='/admin/newsletter' element={<NewsletterScreen />} />

          <Route path='*' element={<NotFound/>}/>
        </Routes>

        <Footer/>

      </I18nextProvider>
    </Router>
  );
}

export default App;
