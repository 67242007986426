import { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { login } from '../actions/userActions'
import { useTranslation } from 'react-i18next'
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer'

const LoginScreen = () => {

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const redirect = new URLSearchParams(useLocation().search).get('redirect')

    const userLogin = useSelector(state => state.userLogin)
    const { error, loading, userInfo } = userLogin

    useEffect(() => {

        if (userInfo) {

            navigate(redirect ? redirect : '/')
        }

    }, [navigate, redirect, userInfo])

    const submitHandler = (e) => {
        e.preventDefault()

        dispatch(login(email, password))
    }


    return (
        <FormContainer>
            <h1>{t('sign in')}</h1>   
            {loading && <Loader/>}
            
            <div>
                <Form onSubmit={submitHandler}>
                    <Form.Group controlId='email'>
                        <Form.Label>{t('email address')}</Form.Label>
                        <Form.Control className={error ? 'input-err' : ''} type='email' placeholder={t('enter email')} value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                            {error && <small style={{'color':'red'}}>{error}</small>}
                    </Form.Group>

                    <Form.Group controlId='password' className='mt-3'>
                        <Form.Label>{t('password')}</Form.Label>
                        <Form.Control className={error ? 'input-err' : ''} type='password' placeholder={t('enter password')} value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                            {error && <small style={{'color':'red'}}>{error}</small>}
                    </Form.Group>

                    <div className='d-flex justify-content-between'>
                        <Button type='submit' variant='primary mt-4'>{t('sign in')}</Button>
                        <Link to='/reset-password' className='mt-4 fw-bold'>{t('forgot password')}?</Link>
                    </div>
                </Form>

                <Row className='py-3'>
                    <Col>{t('new customer')}? <Link 
                        to={redirect ? `/register?redirect=${redirect}` : '/register'} className='fs-5 ms-1'>{t('sign up')}</Link>
                    </Col>
                </Row>
            </div>
           
        </FormContainer>
    )
}

export default LoginScreen
