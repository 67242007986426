import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { NavDropdown } from "react-bootstrap";
import { logout } from "../actions/userActions";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SearchBox from "../components/SearchBox";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = window.location.hash.slice(2);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const { t, i18n } = useTranslation();

  const handleChange = () => {
      const langEl = document.getElementById('website-lang');
      if (langEl.innerHTML === 'EN') {

          langEl.innerHTML = 'DE'
          i18n.changeLanguage('de')
      } else {
          langEl.innerHTML = 'EN'
          i18n.changeLanguage('en')
      }
  }

  const logoutHandler = () => {
    dispatch(logout());
    navigate("/");
  };

  return (
    <>
      <Navbar expand="lg">
        <Container id="nav-container">
          <Link to="/">
            <img
              className="logo-img me-2"
              src={require("../assets/logo.jpg")}
              alt="logo"
            />
          </Link>
          <img
            className="splash"
            src={require("../assets/splash.png")}
            alt="splash"
          />
          <img
            className="splash splash-1"
            src={require("../assets/splash.png")}
            alt="splash"
          />
          <img
            className="splash splash-2"
            src={require("../assets/splash.png")}
            alt="splash"
          />
          <img
            className=" splash-3"
            src={require("../assets/splash-half.png")}
            alt="splash"
          />
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto py-2 my-2 my-lg-0">
              <Link
                to="/about"
                className={
                  location === "about"
                    ? "mx-2 nav-link nav-link-active"
                    : "mx-2 nav-link"
                }
              >
                {t("about")}
              </Link>
              <Link
                to="/books"
                className={
                  location === "books"
                    ? "mx-2 nav-link nav-link-active"
                    : "mx-2 nav-link"
                }
              >
                {t("books")}
              </Link>
              <Link
                to="/blogs"
                className={
                  location === "blogs"
                    ? "mx-2 nav-link nav-link-active"
                    : "mx-2 nav-link"
                }
              >
                {t('blogs')}
              </Link>
              <Link
                to="/people"
                className={
                  location === "people"
                    ? "mx-2 nav-link nav-link-active"
                    : "mx-2 nav-link"
                }
              >
                {t("people")}
              </Link>
              <Link
                to="/events"
                className={
                  location === "events"
                    ? "mx-2 nav-link nav-link-active"
                    : "mx-2 nav-link"
                }
              >
                {t("events")}
              </Link>
            </Nav>

            <SearchBox />

            <div className="d-flex my-2 justify-content-center">
              <Link to="/cart" className="mt-1 cart ms-1 position-relative">
                <img
                  width={35}
                  height={35}
                  src={require("../assets/cart.png")}
                  alt="shopping-cart"
                />

                <span
                  id="cart-notification"
                  className=" position-absolute top-0 start-100 translate-middle badge rounded-pill bg-warning d-none"
                >
                  1
                </span>
              </Link>

              <div onClick={handleChange} className='position-relative mx-1 language-switch'>
                                        <div></div>
                                        <small id='website-lang'>EN</small>
                                    </div>

              {userInfo ? (
                <NavDropdown
                  title={
                    <img
                      width={30}
                      height={30}
                      src={require("../assets/profile.png")}
                      alt="profile"
                    />
                  }
                  id="username"
                  className="profile-btn mx-2"
                >
                  <Link to="/profile" className="dropdown-item">
                    Profile
                  </Link>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <div>
                  <Link
                    to="/login"
                    className="btn login-btn fs-6 px-3 pb-1 mt-3 ms-2"
                  >
                    {t("log in")}
                  </Link>
                </div>
              )}

              {userInfo && userInfo.isAdmin && (
                <NavDropdown title="Admin" id="adminmenu" className="ms-3 mt-3">
                  <Link
                    to="/admin/booklist"
                    className="d-block green-link ms-3 my-1"
                  >
                    {t('Books')}
                  </Link>
                  <Link
                    to="/admin/ebooks"
                    className="d-block green-link ms-3 my-1"
                  >
                    {t('E-Books')}
                  </Link>

                  <Link
                    to="/admin/orders"
                    className="d-block green-link ms-3 my-1"
                  >
                    {t('Orders')}
                  </Link>

                  <Link
                    to="/admin/blogs"
                    className="d-block green-link ms-3 my-1"
                  >
                    {t('blogs')}
                  </Link>

                  <Link
                    to="/admin/authors"
                    className="d-block green-link ms-3 my-1"
                  >
                    {t('Authors')}
                  </Link>

                  <Link
                    to="/admin/events"
                    className="d-block green-link ms-3 my-1"
                  >
                    {('events')}
                  </Link>

                  <Link
                    to="/admin/newsletter"
                    className="d-block green-link ms-3 my-1"
                  >
                    {t('Newsletter')}
                  </Link>
                </NavDropdown>
              )}
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
