import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'

const Koreea = () => {
    const { t } = useTranslation()

    useEffect(() => {

        window.scrollTo(0,0)

    }, [])

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
          }
        }
      };
      
      const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1
        }
      };

    return (
        <Container>
            <h2 className='mt-4' style={{color: '#6B778C'}}>{t('Korean Culture')}</h2>
            <div className='mb-3'>
                <Link to='/' className='fs-6 fs-md-5 light-gray-text'>
                    {t('Home')} &gt;
                </Link>
                <Link to='/?places' className='fs-6 fs-md-5 light-gray-text'> {t('Explore Places')} &gt; </Link>
                <Link to='/explore-places/koreea' className='fs-6 fs-md-5 light-gray-text opacity-100'> {t('Korean Culture')}</Link>
            </div>

            <div className='place-box'>
                <div className=' w-100 d-flex justify-content-center text-center position-relative'>
                    <h2 className='text-center places-title '>{t('Cherry Blossom Festival')}</h2>
                    <div className='underline'></div>
                </div>

                <div className='px-2 px-xl-5'>
                    <div className={`places-blog-card mt-2`}>
                        <motion.div variants={container} initial="hidden" whileInView="visible"
                         className='light-green-text pe-lg-5 me-lg-5'>
                            <motion.p variants={item} className='m-0'>{t('in korea')}</motion.p>
                            <motion.p variants={item}>{t('if you')}</motion.p>
                            <motion.span variants={item}>{t('unfortunately cherry blossomms')}</motion.span>
                        </motion.div>

                        <motion.img initial={{opacity:0, x: 200}} whileInView={{opacity: 1, x: 0}}
                         transition={{duration: 0.6}}
                         src={require('../assets/koreea-img.jpg')} alt="cherry-blossom" />
                    </div>
                </div>
            </div>

            <div className='place-box-2'>
                <div className='w-100 d-flex justify-content-center text-center position-relative mb-2'>
                    <p className='text-center fs-3 mb-1 light-green-text'><span className='fw-bold'>Tchekbom:</span> {t('must visit')}</p>
                    <div className='underline bookshop-underline'></div>
                </div>
                <div className='px-2 px-xl-5'>
                    <div className='mb-5 px-3 px-xxl-5 py-4 bookshop'> 

                        <div className='d-flex justify-content-between align-items-center'>
                            <motion.img initial={{opacity: 0, scale: 0.5}} whileInView={{opacity: 1, scale: 1}}
                             tranisition={{duration: 1}}
                             className='bookshop-img' src={require('../assets/koreea-4.jpg')} alt="peoples" />

                            <motion.div initial={{opacity: 0, x: 200}} whileInView={{opacity: 1, x: 0}}
                             transition={{duration: 0.8}}
                             className='bookshop-right mb-2'>
                                <p className='mt-3 mt-lg-5 ps-0 ps-xl-4'>
                                    {t('small bookshop')}
                                </p>

                                <div className='d-flex flex-row flex-wrap flex-md-row align-items-center justify-content-around'>
                                    <motion.img initial={{opacity: 0, scale: 0.5}} whileInView={{opacity: 1, scale: 1}}
                                     tranisition={{duration: 1}}
                                     className='bookshop-img-sm' src={require('../assets/koreea-1.jpg')} alt="bookshelter" />
                                    <motion.img initial={{opacity: 0, scale: 0.5}} whileInView={{opacity: 1, scale: 1}}
                                     tranisition={{duration: 1}}
                                     className='bookshop-img-sm my-2' src={require('../assets/koreea-2.png')} alt="bookcover" />
                                    <motion.img initial={{opacity: 0, scale: 0.5}} whileInView={{opacity: 1, scale: 1}}
                                     tranisition={{duration: 1}}
                                     className='bookshop-img-sm' src={require('../assets/koreea-3.jpg')} alt="books" />
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Koreea
