import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { Carousel } from 'react-bootstrap'
import HeroSubscribe from './HeroSubscribe'
import HeroUpcomingBook from './HeroUpcomingBook'
import HeroBooks from './HeroBooks'

const Hero = () => {

    const heros = [ <HeroBooks/>, <HeroSubscribe/> ]
    const [isUpcomingBook, setIsUpcomingBook] = useState()
    const booksListAll = useSelector(state => state.booksListAll)
    const { books } = booksListAll

    useEffect(() => {

        if (books) {
            setIsUpcomingBook(books.filter(book => book.upcomingBook))
        }
    
    }, [books])

    if (isUpcomingBook) {
        heros.splice(1, 0, <HeroUpcomingBook isUpcomingBook={isUpcomingBook[0]} />)      
    }
    return (   
        <div className='carousel-div'>
            <Carousel>
                {heros.map((hero, index) =>    
                    (
                        <Carousel.Item key={index} interval={4000}>
                            {hero}
                        </Carousel.Item>
                    )
                )}
            </Carousel>
        </div>
           
    )
}

export default Hero