
export const english = {
  // Navbar
    "about": "About Us",
    "books": "Books",
    "blogs": "Blogs",
    "people": "People",
    "events": 'Events',
    "search": 'Search',
    "log in": 'Log in',
    "profile": 'Profile',
    "logout": 'Logout',
    "user profile": 'User Profile',
    'change password': 'Change Password',
    'update': 'Update',
    'my orders': 'My orders',
    'id': 'ID',
    'date': 'Date',
    'total': 'Total',
    'paid': 'Paid',
    'delivered': 'Delivered',
    'details': 'Details',
    'view-order': 'View Order',
    // Sign in page
    "sign in": 'Sign in',
    "email address": "Email Address",
    "enter email": "Enter Email",
    "password": "Password",
    "enter password": 'Enter Password',
    "new customer": 'New customer',
    "sign up": 'Sign up',
    "forgot password": 'Forgot Password',
    // Footer
    "contact us": 'Contact us',
    "for business please email": 'For business please email',
    "office address": 'Office address',
    "follow us on social media channels": 'Follow us on social media channels',
    "our payment channels": 'Our payment channels',
    // Password reset
    "reset password": 'Reset password',
    "forgotten-pw": 'Forgotten your password? Enter your email address below, and we\'ll email instructions for setting a new one.',
    "submit": 'Submit',
    // Register
    "name": 'Name',
    "enter name": 'Enter name',
    "must contain at least 8 characters": 'Must contain at least 8 characters',
    "confirm password": 'Confirm password',
    "register": 'Register',
    "have an account": 'Have an account',
    'passwords do not match': 'Passwords do not match ',
    'password to short': 'the password is to short. It must contain at least 8 characters',
    // Heros
    "books that warm your heart": "Books that warm your heart!",
    "upcoming book": "Upcoming Book!",
    "we are excited": "We are excited to add a new book in our collection. Get ready for a brand new book from your favourite author. Don't miss out on this exciting new book!",
    "taking preorders": "We're now taking preorders, so grab your copy now before it's released!",
    "preorder now": "Preorder Now",
    "new sign up": "New? signUp Now",
    "subscribe now": "Subscribe Now",
    "life is to short": "Life is too short for boring emails",
    "subscribe to newsletter": "Subscribe to our newsletter to receive updates about latest launch and exclusive offers.",
    "no spam": "No Spam, we promise :)",
    "protecting privacy": "We are committed to protecting your privacy and complying with the General Data Protection Regulation (GDPR) when collecting your email ID for our newsletter. By subscribing to our newsletter, you are giving us permission to use your email ID for the sole purpose of sending you our newsletter. We will store your email ID securely and will not share it with any third parties without your explicit consent. You can unsubscribe from our newsletter at any time by clicking the unsubscribe link in any of the emails you receive from us.",
    "questions use of data":'If you have any questions or concerns about how we use your data, please do not hesitate to contact us at info@hanlbook.com',
    "subscribe": "Subscribe",
    // Home page
    'best seller': 'Best Seller',
    'book club': 'Book Club',
    "Counting Stars Out Of Heaven in Us": "Counting Stars Out Of Heaven in Us",
    'In the heart of Stuttgart in 2023': 'In the heart of Stuttgart in 2023',
    'Hanl Publication is born': 'Hanl Publication is born',
    'We provide a specialised platform': 'We provide a specialised platform',
    'that': 'that',
    'informs, inspires and transforms you': 'informs, inspires and transforms you',
    'Take a look around and see what catches your heart': 'Take a look around and see what catches your heart',
    'Wishing you a delightful experience': 'Wishing you a delightful experience',
    'Social initiatives our literature focuses towards': 'Social initiatives our literature focuses towards',
    'Explore Places': 'Explore Places',
    'Korean Culture': 'Korean Culture',
    'Stuttgart: A place called Home': 'Stuttgart: A place called Home',
    // Korean 
    'Home': 'Home',
    'Cherry Blossom Festival': 'Cherry Blossom Festival',
    'in korea': 'In Korea, there is a cultural tradition known as "flower viewing," which is a term that does not exist in Germany. This tradition holds a special place in the hearts of Koreans, who often plan weekend getaways with friends, family, and loved ones to coincide with the flowering season. The blooming of flowers begins in the warmer southern regions and gradually moves northward, starting in early spring with cornelian cherry flowers, followed by azaleas, and culminating with cherry blossoms in April.',
    'if you': 'If you were to observe Korea from the moon, you would see a yellow landscape in February, a pink landscape in March, and a white-pink landscape in April. Each city in Korea is designed to complement the specific time of year when flowers are in bloom, creating a beautiful, large-scale flower garden throughout the country. Therefore, romantic cherry blossom viewing events are widely enjoyed by everyone, regardless of where they live. This beautiful and memorable moment is cherished by people of all ages in Korea.',
    'unfortunately cherry blossomms': 'Unfortunately, cherry blossoms have a short lifespan, which makes their disappearance bittersweet. However, even the moment of their rapid fading is considered a romantic experience by Koreans. During the summer months, they take solace in the beauty of roses and sunflowers while eagerly awaiting the arrival of autumn.As for the autumn viewing tradition in Korea, it remains a closely guarded secret. However, there are some breathtaking viewing experiences that rival the beauty of cherry blossoms, and we will share more about them in the autumn season.',
    'must visit': 'Must Visit Bookshop in South Korea',
    'small bookshop': 'Tchekbom, a small bookshop that cares about the environment and loves animals. It mainly presents independent publications. It not only sells books, but also conveys the joy of reading through various activities.Choi Hyun-joo, the owner of the bookshop, has also published a book about her experience with the bookshop. In this book, she told about adopting three abandoned cats. How through this she developed her life into a simple and cordial way of dealing with her neighbours, animals and all living creatures. This book gradually became known from person to person. There are those readers who travel to Gumi in a small village in South Korea especially to buy books from this bookshop "Tchekbom". Big surprise!! This book in German language will be published by Hanl publishing. The German edition is called "Das Glück auf leisen Pfoten"(Happiness on Quiet Paws).',
    // Stuttgart
    'Charm, Culture and History of Stuttgart': 'Charm, Culture and History of Stuttgart',
    'stuttgart-p1': 'Stuttgart is a city that truly captures the essence of Germany. Located in the southwestern region of the country, it is a hub for business, culture, and entertainment. The city is famous for its green spaces, innovative architecture, and world-renowned museums, as well as its fascinating history.',
    'stuttgart-p2': 'One of Stuttgart’s most iconic landmarks is the Mercedes-Benz Museum, which showcases the evolution of the automotive industry from its inception to modern times. It’s a must- visit for any car enthusiast or engineering aficionado. Another fascinating cultural institution in Stuttgart is the State Library of Württemberg, one of the oldest and most significant libraries in Germany. Its collection includes over three million books, manuscripts, maps, and more.',
    'stuttgart-p3': 'Stuttgart’s culinary scene is also worth exploring, with its diverse mix of traditional German cuisine and international flavors. From the world-famous Maultaschen (Swabian pasta pockets) to local specialties like Käsespätzle (cheesy noodles), there’s something to suit every palate.',
    'stuttgart-p4': 'Aside from its cultural attractions, Stuttgart is a popular destination for outdoor enthusiasts. The Schlossgarten, a sprawling park in the heart of the city, offers scenic walking and jogging trails, beautiful botanical gardens, and charming cafes for a relaxing afternoon. Stuttgart’s bookstores are also worth exploring, as the city is home to many independent shops that offer a unique and diverse selection of books. One of the most popular is the Tübinger Bücherstube, which has been around for over 100 years and is known for its friendly service and impressive collection of German literature.',
    'stuttgart-p5': 'Stuttgart has a rich history dating back to the Roman Empire, with the city being an important center for trade and commerce for centuries. During World War II, it suffered significant damage, but the city has since rebuilt and transformed into a modern and vibrant destination that preserves its heritage while embracing innovation.',
    'stuttgart-p6': 'Overall, Stuttgart is a city that offers something for everyone. Whether you’re interested in history, art, or simply enjoying the outdoors, you’re sure to find something to love in this charming and culturally-rich destination.',
    // About
    'Book to the Future': 'Book to the Future',
    'michelle': 'Really happy to open a new literature platform! So many people are surprised and asking us constantly, will the book even continue to exist?"',
    'alex': 'The world has changed dramatically in a short time and so has our lifestyle. Mankind has become much smarter, more intelligent, more knowledgeable and has at its disposal all kinds of miraculous devices for informing, remembering and calculating. For this everyday life with such high speed and diverse information sources, the book could easily be dispensable.',
    'swati': 'Does this make your life happier and more fulfilling?',
    'burkhard': "A happy and fulfilled life does not depend on information and knowledge. The essential feel-good factors consist of the various relationships. e.g. how one relates to one's job. How you behave with your family, friends and colleagues, and how you feel about yourself.",
    'michelle-1': ' Feeling? Do you mean different emotions?',
    'tina': "Actually the positive feelings. I mean, any positive feeling makes life happy because it makes us resilient in all circumstances. These positive feelings can only be taught through experience and confrontation with the reality of life. Well, you can't have at once thousand biographies. But if you have the chance to experience how diverse life could be, you are already on the right track.",
    'swati-1': 'Experiencing the lives of others and empathising with them gives you the ability to develop positive feelings, I find interesting. If it were like that, good literature could still be essential for the quality of life. What do you think about that?',
    'rose': "Well, sure. Just that I'm hungry for 'New Story!'. No one lives in the traditional notions of time and space any more. All the old concepts of gender, ethnicity, nation, social class, region or religion are dissolving. Each of us is looking for something new to take their place. This new value comes from engaging with the people who live with me here and now. Look around and listen for a moment: how many languages are being spoken at the same time? How many different people are mingling with each other? What a wonderful opportunity to be neighbours or friends with such a diverse group of people? We want to build new social values by respecting and sharing each other's values. We are looking for literature to help us do this. I feel from time to time as if my head had arrived ahead in the 23rd century, but my heart had stopped in the 19th century. It can't be true. I would be really happy if I knew how to develop my heart even further. Preferably even more loving, warmer, braver and more generous, above all freer!",
    'Heartwarming': 'Heartwarming',
    'Your': 'Your',
    'Our Team': 'Our Team',
    // Books
    'Book Collection': 'Book Collection',
    'Know More': 'Know More',
    // Filter
    'Categories': 'Categories',
    "Languages": 'Languages',
    'In-stock': 'In-Stock',
    'Authors': 'Authors',
    'Artists': 'Artists',
    // Book page
    'Description': 'Description',
    'Read More': 'Read More',
    'Read Less': 'Read Less',
    'Paper Back': 'Paper Back',
    'Coming Soon': 'Coming Soon',
    'Select language': 'Select language',
    'Pre Order': 'Pre Order',
    'Available after': 'Available after',
    'Buy Now': 'Buy Now',
    'Add to cart': 'Add to cart',
    'Social Contribution': 'Social Contribution',
    'Product Description': 'Product Description',
    'About author': 'About author',
    'Write a review': 'Write a review',
    'Rating': 'Rating',
    'Review': 'Review',
    "Reviews": 'Reviews',
    'Submit': 'Submit',
    'Please': 'Please',
    'to write a review': 'to write a review',
    'poor': 'Poor',
    'fair': 'Fair',
    'good': 'Good',
    'very good': 'Very Good',
    'excellent': 'Excellent',
    // Blogs
    'Hanl Book\'s Blog' : 'Hanl Book\'s Blog',
    // People
    'Hanl Book\'s People' : 'Hanl Book\'s People',
    'Hanl\'s Team': 'Hanl\'s Team' ,
    // Team
    'Publishing director': 'Publishing director',
    'born in': 'Born in South Korea and living in Stuttgart, Germany since 2006.',
    'Lector': 'Lector',
    'user exp': 'User Experience Designer and Social Media Manager',
    'Graphic Designer': 'Grafikdesigner',
    'finken-desc': 'Finken & Bumiller Book Designer and Graphic Designer Finken & Bumiller is a design office with many years of extensive experience in designing books and publishing appearances. the range of services includes everything — from the individual book covers, series, previews to the design of an appearance for the entire publishing house. In addition to the actual focus of work for book publishers, Finken & Bumiller primarily looks after cultural institutions.',
    // Events
    'Book Tour': 'Book Tour',
    "Hanl Book's Events": "Hanl Book's Events",
    // Shopping Cart
    'Shopping Cart': 'Shopping Cart',
    'Language': 'Language',
    'Subtotal': 'Subtotal',
    'items': 'Items',
    'Proceed to Checkout': 'Proceed to Checkout',
    'Your Wishlist': 'Your Wishlist',
    'Your wishlist is empty': 'Your wishlist is empty',
    'Your cart is empty': 'Your cart is empty',
    'Buy Books': 'Buy Books',
    // Shipping
    'Shipping': 'Shipping',
    'Address': 'Address',
    'Enter Address': 'Enter Address',
    'City': 'City',
    'Enter City': 'Enter City',
    'Postal Code': 'Postal Code',
    'Enter Postal Code': 'Enter Postal Code',
    'Country': 'Country',
    'Enter Country': 'Enter Country',
    'Continue': 'Continue',
    // Place order
    'Order Items':'Order Items',
    'Order Summary': 'Order Summary',
    'Total': 'Total',
    'Place Order': 'Place Order',
    // Order Screen
    'Order': 'Order',
    'Not Paid': 'Not Paid',
    'Not Delivered': 'Not Delivered',
    // Cookies
    'We care': 'We care about your personal data to remain private',
    'cookie msg': 'uses only essential cookies which are set in response to your actions on the site, such as singing in, or filling in forms, this are necessary for the functionality of our website and cannot be dezactivated.',
    'View Cookies': 'View Cookies',
    'Accept': 'Accept',
    'Privacy Policy': 'Privacy Policy',
    'Cookie List': 'Cookie List',
    'PayPal cookie': 'These cookies are used to enable online payments through our website. They allow us to securely process payments and to keep track of your order details.',
    'User Info cookie': 'This is a local storage item that stores your user information, such as your token, ID, username, name, and email. This information is used to personalize your experience on our website and to enable certain features, such as online payments, order history, tracking your orders.',
    'Shipping Address cookie': 'This is a local storage item that stores your user information, such as your token, ID, username, name, and email. This information is used to personalize your experience on our website and to enable certain features, such as online payments, order history, tracking your orders.',
    'paypal storage cookie': 'This is a local storage item used by PayPal to remember your payment preferences and to enable faster checkout.',
    'all essential': 'This are all essential cookies that are necessary for the functioning of the website and cannot be disabled.',
    'by using': 'By using our website, you consent to the use of these cookies',
    // Privacy Policy
    'Privacy Policy for website': 'Privacy Policy for Hanl Book Website',
    'Collection of Information': 'Collection of Information',
    'we collect': 'We collect information about our users in the following ways:',
    'collect-1': 'Information you provide to us: When you register for an account or place an order we may collect personal information such as your name, email address, and shipping address.',
    'Use of Information': 'Use of Information',
    'we use information': 'We use the information we collect for the following purposes:',
    'we use information-1':'We use the information to process order and to personalize your experience on our website.',
    'we use information-2': 'For marketing purposes: We may use the information to send you promotional emails.',
    'Disclosure of Information': 'Disclosure of Information',
    'we disclose': 'We may disclose information about our users in the following circumstances:',
    'we disclose for': 'For legal purposes: We may disclose your information in response to a subpoena or other legal request.',
    'Security of Information': 'Security of Information',
    'security msg': 'We take appropriate measures to protect the security of your information, including implementing technical and organizational measures to prevent unauthorized access, disclosure, or destruction.',
    'Your Rights': 'Your Rights',
    'your rights msg': 'You have the right to access, correct, and delete your personal information. You also have the right to object to the processing of your personal information and to receive a copy of your personal information in a structured, machine-readable format.',
    'contact us about pp': 'If you have any questions or concerns about this Privacy Policy, please contact us at info@hanlbook.com.',
     // Reset pw
     'Enter a new Password': 'Enter a new Password',
     'Enter a new Password p': 'Please enter your new password twice so we can verify you typed it in correctly',
     'Passwords do not match': 'Passwords do not match!',

     'Out of Stock': 'Out of Stock',
     'Added to wishlist': 'Added to wishlist',
     'Added to cart': 'Added to cart',
     'Preview': 'Preview',
     'location link': 'location link',
    'onwards at':'onwards at',
    'Blogs': 'Blogs',
    "E-Books": "E-Books",
    'Reject All': 'Reject All',
    'Orders': 'Orders',
    'Newsletter': 'Newsletter',
    'Loading': 'Loading',
    'unsubscribe-here': 'If you want to unsubscribe from the newsletter, click here',
    'Unsubscribe': 'Unsubscribe',
    'Go Back': 'Go Back',
    'Add Ebook Pdf': 'Add Ebook Pdf',
    'Select the E-book': 'Select the E-book',
    'Select the language of the E-Book': 'Select the language of the E-Book',
    'Add': 'Add',
    'Edit': 'Edit',
    'Select job': 'Select job',
    'Team': 'Team',
    'Image': 'Image',
    'Enter Image': 'Enter Image',
    'Role': 'Role',
    'Link': 'Link',
    'Enter a': 'Enter a',
    'Short': 'Short',
    'Author': 'Author',
    'Artist': 'Artist',
    'About': 'About',
    'Actions': 'Actions',
    'Enter descritpion': 'Enter descripton',
    'Edit Blog': 'Edit Blog',
    'Blog Name': 'Blog Name',
    'Author Name': 'Author Name',
    'Text': 'Text',
    'Enter Section One Text': 'Enter Section One Text',
    'Choose File': 'Choose File',
    'Are you sure you want to delete this product?': 'Are you sure you want to delete this product?',
    'Create Blog': 'Create Blog',
    'Create Book': 'Create Book',
    'Created': 'Created',
    'Edit Product': 'Edit Product',
    'Is Upcoming Book?': 'Is Upcoming Book?',
    'Yes': 'Yes',
    'No': 'No',
    'Enter Price': 'Enter Price',
    'Hard Cover Price': 'Hard Cover Price',
    'E-book Price': 'E-book Price',
    'Price': 'Price',
    'Select all available languages for the book': 'Select all available languages for the book',
    'Is Best Seller': 'Is Best Seller',
    'Book Preview PDF': 'Book Preview PDF',
    'Add book Preview': 'Add book Preview',
    'Category': 'Category',
    'Select a category': 'Select a category',
    'Hold down "Control", or "Command" on a Mac, to select more than one.': 'Hold down "Control", or "Command" on a Mac, to select more than one.',
   'Stock': 'Stock',
   'Enter stock': 'Enter stock',
   'Enter Social Contribution': 'Enter Social Contribution',
   'Enter message': 'Enter message',
   'Product details': 'Product details',
   'type all product details separated with comma': 'type all product details separated with comma',
   'No reviews yet': 'No reviews yet',
  'Select': 'Select',
  'Currently, there are no books with the given filter': 'Currently, there are no books with the given filter',
  'Send a Newsletter': 'Send a Newsletter',
  'Number': 'Number',
  'Paypal-payment': "You don't need to create an account; simply select PayPal during checkout and follow the prompts.",
  'Credit-card-payment': "Use your preferred debit or credit card for payment.Enter your card details securely during checkout,and your book will be dispatched once the payment is processed.",
  'Payment Guidelines': 'Payment Guidelines',
  'sepa-payment': 'If SEPA payment fails, use either a credit card, debit card, or PayPal. For card payment, enter your card details during checkout. With PayPal, you can make a payment without creating an account. Simplyselect PayPal as the payment option.',
  'sepa-failure': 'SEPA Payment Failure',
  'prepayment': 'Transfer the total amount to the provided IBAN on email with order reference number. Once payment is received, your book will be dispatched.',
   'order-confirm': 'Thank you for your order! A confirmation email with the E-book attached was sent to your Email Address. Make sure to also check in the spam. Best, Hanl Book!',
   'order-confirm-note': 'for pre-orders the E-book will be sent whenever is ready!',
   'order-confirm-2': ' Thank your for your order! A confirmation email was sent to your Email Address. Make sure to also check in the spam. Best, Hanl Book!',
   'no-artists': 'Currently there are no authors or artists',
  }