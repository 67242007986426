import axios from 'axios'

export const addToCart = (id, qty, type, language) => async(dispatch, getState) => {

    dispatch({type: 'CART_ADD_ITEM_REQUEST'})
    
    const { data } = await axios.get(`/api/books/${id}/`)

    dispatch({
        type: 'CART_ADD_ITEM',
        payload: {
            id: data.id,
            name: data.name,
            image: data.image,
            price: type === 'eBook' ? data.eBookPrice : data.hardCoverPrice,
            countInStock: data.countInStock,
            qty,
            type,
            language
        }
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


export const removeFromCart = (id) => async (dispatch, getState) => {

    dispatch({
        type: 'CART_REMOVE_ITEM',
        payload: id
    })

    localStorage.setItem('cartItems', JSON.stringify(getState().cart.cartItems))
}


export const addToWishlist = (id) => async(dispatch, getState) => {

    const { data } = await axios.get(`/api/books/${id}/`)

    dispatch({
        type: 'WISHLIST_ADD_ITEM',
        payload: {
            id: data.id,
            name: data.name,
            author: data.author,
            description: data.description,
            image: data.image,
            price: data.hardCoverPrice,
            countInStock: data.countInStock,
            inWishlist: true,
        }
    })

    localStorage.setItem('wishlist', JSON.stringify(getState().wishlist.wishlistItems))
}

export const removeFromWishlist = (id) => async (dispatch, getState) => {

    dispatch({
        type: 'WISHLIST_REMOVE_ITEM',
        payload: id
    })

    localStorage.setItem('wishlist', JSON.stringify(getState().wishlist.wishlistItems))
}


export const saveShippingAddress = (address) => async(dispatch) => {
    dispatch({
        type: 'SAVE_SHIPPING_ADDRESS',
        payload: address
    })

    localStorage.setItem('shippingAddress', JSON.stringify(address))

}