import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { listBlogs } from '../actions/blogActions'
import { Container } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import BlogCard from '../components/BlogCard'
import Message from '../components/Message'
import Loader from '../components/Loader'


const BlogsScreen = () => {

    const dispatch = useDispatch()
    const { t  } = useTranslation()
    const blogsList = useSelector(state => state.blogsList)
    const { loading, error, blogs } = blogsList

    useEffect(() => {

        if (!blogs) {

            dispatch(listBlogs())
        }

    }, [dispatch, blogs])

    return (
        <Container>
            <h1 className='page-title mt-3 mb-5 fs-2'>{t('Hanl Book\'s Blog')}</h1>
            {loading ? <Loader /> : error && <Message variant={'danger'}> {error}</Message>}

            <div className='blogs-div'>
            {blogs ? blogs.map((blog, idx) => (
                <BlogCard key={blog.id} blog={blog} flex={idx}/>
            )) : (<p className='mt-5'>Currently there are no blogs</p>)}
            </div>
        </Container>
    )
}

export default BlogsScreen
