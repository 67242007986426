import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const AboutScreen = () => {
    const { t } = useTranslation()
    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
          }
        }
      };
      
      const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1
        }
      };

    return (
        <div className='container mt-5 position-relative'>
            
            <motion.div initial={{opacity: 0, x: -300}} animate={{opacity: 1, x: 0}}
                transition={{duration: 1}}>
                <h2 className='fw-bold mb-5 text-center green-text about-title position-relative'>{t('Book to the Future')}</h2>
            </motion.div>

            
            <div className='conv-box'>
                <motion.div variants={container} initial="hidden" animate="visible">
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/michelle.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Michelle: </span> 
                        {t('michelle')}</p>
                    </motion.div>
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/alex.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Alex: </span>
                        {t('alex')}</p>
                    </motion.div>
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/swati.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Swati: </span>
                        {t('swati')}</p>
                    </motion.div>
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/burkard.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Burkhard: </span>
                        {t('burkhard')}</p>
                    </motion.div>  
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/michelle.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Michelle: </span>
                        {t('michelle-1')}</p>
                    </motion.div>
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/tina.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Tina: </span>
                        {t('tina')}</p>
                    </motion.div>
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/swati.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Swati : </span>
                        {t('swati-1')}</p>
                    </motion.div>
                    <motion.div className='d-flex mb-0 mb-md-4' variants={item}>
                        <img width={40} height={40} src={require('../assets/rose.png')} alt="" />
                        <p className='ms-3'><span className='fw-bold fst-italic'>Rose : </span>
                        {t('rose')}</p>
                    </motion.div>
                </motion.div>
                <p className='conv-closing '>{t('Heartwarming')}, <span className=' fst-italic d-block'>{t('Your')} HanlBook!</span></p>
                <img src={require('../assets/circle-chair.png')} className='about-img opacity-0' alt="circle-chair" />    
                
            </div>

            <div className='about-bottom'>
            <img src={require('../assets/circle-chair.png')} className='about-img about-img-visible' alt="circle-chair" />
                <Link className='fw-bold team-link' to={'/people?team'}>{t('Our Team')}</Link>
            </div>
            <img src={require('../assets/circle-chair.png')} className='about-img opacity-0' alt="circle-chair" />
        </div>
    )
}


export default AboutScreen
