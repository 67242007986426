import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Card,
  Button,
  Container,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getOrderDetails,
  payOrder,
  prepaymentOrder,
  deliverOrder,
} from "../actions/orderActions";
import { PayPalButton } from "react-paypal-button-v2";
import { useTranslation } from "react-i18next";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { toast } from "react-toastify";

const OrderScreen = () => {
  const [sdkReady, setSdkReady] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { success: successPay, loading: loadingPay } = orderPay;

  const orderPrepay = useSelector((state) => state.orderPrepay);
  const {
    success: successPrepay,
    loading: loadingPrepay,
    message,
  } = orderPrepay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { success: successDeliver, loading: loadingDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const params = useParams();
  const { id } = params;

  if (!loading && !error) {
    order.itemsPrice = order.orderItems
      .reduce((acc, item) => acc + item.price * item.quantity, 0)
      .toFixed(2);
  }

  const addPayPalScript = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    // Live
    // AXS3H6Fv8kZjMp6-M4oWjflx0ausgt7VecrUrGQeMLk15YfmEdTBog2FtW6cCKOsuMOhIOQxAiA2y-np
    // Sandbox
    // AUtZp_UjRfmjmPFn9xViimjx7op3xIsMNrgGdGwmTZCxD6PXlmf3_YSFSSE98ESSrU_iRj8bfTJHxKw8
    script.src =
      "https://www.paypal.com/sdk/js?client-id=AXS3H6Fv8kZjMp6-M4oWjflx0ausgt7VecrUrGQeMLk15YfmEdTBog2FtW6cCKOsuMOhIOQxAiA2y-np&currency=EUR&disable-funding=sepa";
    script.async = true;
    script.onload = () => {
      setSdkReady(true);
    };
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!userInfo) {
      navigate("/login");
    }

    if (!order || successPay || successDeliver || order.id !== id) {
      dispatch({ type: "ORDER_PAY_RESET" });
      dispatch({ type: "DELIVER_ORDER_RESET" });

      dispatch(getOrderDetails(id));
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }

    dispatch({ type: "ORDER_PREPAY_RESET" });
  }, [order, id, userInfo, successPay, successDeliver, navigate, dispatch]);

  const successPaymentHandler = (paymentResult) => {
    dispatch(payOrder(id, "paypal"));
  };

  const handlePrepayment = () => {
    dispatch(prepaymentOrder(id, "prepayment"));
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(id));

    if (order.paymentMethod === "prepayment") {
      dispatch(payOrder(id, "prepayment"));
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(order.orderNumber);
    toast.success("Copied to clipboard");
  };
  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <Message variant="danger">{error}</Message>;
  }

  return (
    <Container className="my-5">
      <div className="d-flex">
        <h1>
          {t("Order")} {t('Number')}: {order.orderNumber}
        </h1>
        <button className="btn ms-2" onClick={copyToClipboard}>
          <i className="fa-solid fa-copy"></i>
        </button>
      </div>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>{t("Shipping")}</h2>
              <p>
                <strong>{t("Name")}: </strong>
                {order.user.name}
              </p>
              <p>
                <strong>{t("Email")}: </strong>
                <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
              </p>
              {order.shippingAddress &&
                !order.orderItems.find((item) => item.type === "eBook") && (
                  <p>
                    <strong>{t("Shipping")}: </strong>
                    {order.shippingAddress.address},{order.shippingAddress.city}
                    {"  "}
                    {order.shippingAddress.postalCode},{"  "}
                    {order.shippingAddress.country}
                  </p>
                )}

              {order.isPaid ? (
                <Message variant="success">
                  {t("Paid at")} {order.paidAt.substring(0, 10)}
                </Message>
              ) : (
                <Message variant="warning">{t("Not Paid")}</Message>
              )}

              {order.isDelivered ? (
                <Message variant="success">
                  {" "}
                  {t("Delivered At")} {order.deliveredAt.substring(0, 10)}
                </Message>
              ) : (
                <Message variant="warning">{t("Not Delivered")}</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item>
              <h2>{t("Order Items")}</h2>

              {order.orderItems.length === 0 ? (
                <Message variant="info">Order is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item className="px-0" key={index}>
                      <Row>
                        <Col md={2}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>

                        <Col>
                          <Link
                            className="green-link fs-5"
                            to={`/books/${item.book}`}
                          >
                            {item.name} {item.type === "eBook" && "(E-Book)"}
                            <p className="mt-3 text-warning">
                              {t("Language")}: {item.language}
                            </p>
                          </Link>
                        </Col>

                        <Col md={4}>
                          {item.quantity} X &euro;{item.price} = &euro;
                          {(item.quantity * item.price).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>{t("Order Summary")}</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>{t("items")}: </Col>
                  <Col>&euro;{order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>{t("Shipping")}: </Col>
                  <Col>&euro;{order.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>{t("Total")}: </Col>
                  <Col>&euro;{order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>

              {!order.isPaid && order.paymentMethod !== "prepayment" && (
                <ListGroup.Item className="d-flex flex-column">
                  {loadingPay && <Loader />}

                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <>
                      <h5 className="text-center">{t('Payment Guidelines')}</h5>
                      <ul>
                        <li>
                          <small>
                            <span className="fw-semibold me-2">PayPal:</span>
                            {t('Paypal-payment')}
                          </small>
                        </li>
                        <li>
                          <small>
                            <span className="fw-semibold me-2">
                              Debit/Credit Card:
                            </span>
                            {t('Credit-card-payment')}
                          </small>
                        </li>
                        <li>
                          <small>
                            <span className="fw-semibold me-2">
                              {t('sepa-failure')}:
                            </span>
                            {t('sepa-payment')}
                          </small>
                        </li>
                      </ul>
                      <PayPalButton
                        amount={order.totalPrice}
                        currency={"EUR"}
                        onSuccess={successPaymentHandler}
                      />
                    </>
                  )}
                  {loadingPrepay ? (
                    <Loader />
                  ) : (
                    <>
                      <small>
                        <li>
                          <span className="fw-semibold me-2">
                            Vorauskasse (Prepayment):
                          </span>
                          {t('prepayment')}
                        </li>
                      </small>
                      <Button
                        className="mt-2 mb-2 py-2"
                        onClick={handlePrepayment}
                        disabled={successPrepay}
                      >
                        Vorauskasse
                      </Button>

                      {successPrepay && (
                        <small className="bg-success p-2 text-white rounded">
                          {message}
                        </small>
                      )}
                    </>
                  )}
                </ListGroup.Item>
              )}
            </ListGroup>

            {loadingDeliver && <Loader />}

            {userInfo &&
              userInfo.isAdmin &&
              (order.isPaid || order.paymentMethod === "prepayment") &&
              !order.isDelivered && (
                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn-block w-100"
                    onClick={deliverHandler}
                  >
                    Mark As Delivered
                  </Button>
                </ListGroup.Item>
              )}
          </Card>
          {userInfo &&
          !userInfo.isAdmin &&
          order.isPaid &&
          order.orderItems.find((item) => item.type === "eBook") ? (
            <Message variant="success">
              {t('order-confirm')}{" "}
              <small className="d-block">
                * {t('order-confirm-note')}
              </small>
            </Message>
          ) : (
            userInfo &&
            !userInfo.isAdmin &&
            order.isPaid && (
              <Message variant="success">
               {t('order-confirm-2')}
              </Message>
            )
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default OrderScreen;
