import { useState } from "react";
import { Form } from "react-bootstrap";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import axios from "axios";

const HeroSubscribe = () => {
  const [email, setEmail] = useState("");

  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data } = await axios.post("/api/users/subscribe/", {
      email: email,
    });

    data.success
      ? toast.success(data.success)
      : data.error && toast.error(data.error);
    setEmail("");
  };

  return (
    <header id="hero" className="d-flex justify-content-around ">
      <div className="position-relative">
        <motion.img
          initial={{ opacity: 0, scale: 0.4 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.8 }}
          className="hero-ilustration literature"
          src={require("../assets/literature.png")}
          alt="Book Club"
        />

        <div className="ellipse"></div>
      </div>
      <motion.div
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
        className="context"
      >
        <h1 className="mb-4 mb-md-0">{t("subscribe now")} !</h1>
        <span>{t("life is to short")}</span>
        <p className="mb-0">{t("subscribe to newsletter")}</p>
        {/* <span style={{fontWeight:'300', fontFamily:'Roboto'}} className='fst-italic'>{t('no spam')}</span> */}
        <Form className="d-flex subscribe-form mt-2" onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Control
              type="email"
              required
              placeholder={t("enter email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
          <input type="submit" value={t("subscribe")} />
        </Form>
        <p className="mt-1 newsletter-warning">
          {t("protecting privacy")}
          <br />
          <br />
          {t("questions use of data")}
        </p>
      </motion.div>
    </header>
  );
};

export default HeroSubscribe;
