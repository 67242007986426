import { useEffect } from 'react'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { Table, Button, Row, Col, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { listBooks, createBook, deleteBook } from '../actions/bookActions'
import Loader from '../components/Loader';
import Message from '../components/Message';
import Paginate from '../components/Paginate'
import { useTranslation } from 'react-i18next'

const BookListScreen = () => {

    const navigate = useNavigate()
    const location = useLocation()

    const dispatch = useDispatch()
    const { t } = useTranslation()

    const booksList = useSelector(state => state.booksList)
    const { loading, error, books } = booksList

    const bookDelete = useSelector(state => state.bookDelete)
    const { success: deleteSuccess, loading:loadingDelete, error:errorDelete } = bookDelete
    
    const bookCreate = useSelector(state => state.bookCreate)
    const {success: successCreate, loading: loadingCreate,
        error: errorCreate, book: createdBook} = bookCreate
        
    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    let keyword = location.search

    useEffect(() => {
    
        if (!userInfo || !userInfo.isAdmin) {
            navigate('/login')
        } 

        dispatch({type: 'CREATE_BOOK_RESET'})

        if (successCreate) {
            navigate(`/admin/books/${createdBook.id}/edit`)

        } else {
            dispatch(listBooks(keyword))
        }

    }, [dispatch, navigate, keyword, userInfo, createdBook, deleteSuccess, successCreate])

    const deleteHandler = (id) => {
        
        if (window.confirm('Are you sure you want to delete this product?')) {
            dispatch(deleteBook(id))
        }

    }

    const createBookHandler = () => {
        dispatch(createBook())
    }

    if (loading) {
        return <Loader/>
    }

    if (error) {
        return <Message variant='danger'>{error}</Message>
    }

    return (
        <Container className='my-5'>
            <Row className='align-items-center'>
                <Col><h1>{t('books')}</h1></Col>

                <Col className='d-flex justify-content-end'>
                    <Button className='my-3' onClick={createBookHandler}>
                        <i className='fas fa-plus'></i> {t('Create Book')}
                    </Button>
                </Col>
            </Row>

            {loadingDelete && <Loader />}
            {errorDelete && <Message variant='danger'>{errorDelete}</Message>}

            {loadingCreate && <Loader />}
            {errorCreate && <Message variant='danger'>{errorCreate}</Message>}

            <div>
                <Table striped bordered hover responsive className='table-sm'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>{t('name').toUpperCase()}</th>
                            <th>{('Price').toUpperCase()}</th>
                            <th>{('Actions').toUpperCase()}</th>
                        </tr>
                    </thead>

                    <tbody>
                        {books && books.books && books.books.map(book => (
                            <tr key={book.id}>
                                <td>{book.id}</td>
                                <td>{book.name.substring(0,55)}</td>
                                <td>${book.hardCoverPrice}</td>

                                <td>
                                    <Link to={`/admin/books/${book.id}/edit`}>
                                        <Button variant='light' className='btn-sm'>
                                            <i className='fas fa-edit'></i>
                                        </Button>
                                    </Link>

                                    <Button variant='danger' className='btn-sm mt-1 mt-lg-0 ms-lg-2'
                                        onClick={() => deleteHandler(book.id)}>
                                        <i className='fas fa-trash'></i>
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                
                {books && (
                    <Paginate page={books.page} pages={books.pages} isAdmin={true}/>
                )}
            </div>
        </Container>
    )
}

export default BookListScreen
