import { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'


const Stuttgart = () => {
    const { t } = useTranslation()
    useEffect(() => {

        window.scrollTo(0,0)

    }, [])

    const container = {
        hidden: { opacity: 1, scale: 0 },
        visible: {
          opacity: 1,
          scale: 1,
          transition: {
            delayChildren: 0.1,
            staggerChildren: 0.1
          }
        }
      };
      
      const item = {
        hidden: { y: 20, opacity: 0 },
        visible: {
          y: 0,
          opacity: 1
        }
      };

    return (
        <Container>
            <div className='place-box'>
                <h2 className='mt-4' style={{color: '#6B778C'}}>{t('Stuttgart : A place called Home')}</h2>
                <div className='mb-5'>
                    <Link to='/' className='light-gray-text fs-6 fs-md-5'>
                        Home &gt;
                    </Link>
                    <Link to='/?places' className='light-gray-text fs-6 fs-md-5'> {t('Explore Places')} &gt; </Link>
                    <Link to='/explore-places/stuttgart' className='light-gray-text fs-6 fs-md-5 opacity-100'> Stuttgart</Link>
                </div>

                
                <div className=' w-100 d-flex justify-content-center text-center position-relative mb-2'>
                    <h2 className='text-center fw-semibold green-text places-title position-relative'>{t('Charm, Culture and History of Stuttgart')}</h2>
                    <div className="underline bookshop-underline"></div>
                </div>

                <div className='px-2 px-xl-5'>
                    <div className='pb-0 places-blog-card stuttgart-card d-flex flex-column'>
                        
                        <motion.img initial={{opacity:0, x: -200}} whileInView={{opacity: 1, x: 0}}
                         transition={{duration: 0.6}}
                         src={require(`../assets/stuttgartone.jpg`)} alt="" />
                        

                        <div
                         className={`light-green-text w-100`}>
                            <motion.p variants={container} initial="hidden" whileInView="visible"
                             className='my-0'>
                                <motion.span variants={item}
                                 className='mb-2 d-block'>{t('stuttgart-p1')}</motion.span>
                                <motion.span variants={item}
                                 className='mb-2 d-block'>{t('stuttgart-p2')}</motion.span>
                                <motion.span variants={item}
                                 className='mb-2 d-block'>{t('stuttgart-p3')}</motion.span>
                                <motion.span variants={item}
                                 className='mb-2 d-block'>{t('stuttgart-p4')}</motion.span>
                                <motion.span variants={item}
                                 className='mb-2 d-block'>{t('stuttgart-p4')}</motion.span>
                                <motion.span variants={item}
                                 className='d-block'>{t('stuttgart-p5')}</motion.span>
                            </motion.p>
                        </div>
                    </div>
                </div>
            </div>

        </Container>
    )
}

export default Stuttgart
